import { FC, useState } from 'react'

import './Tooltip.scss'

type Props = {
  placement: string
  text: string
}

export const AdviseTooltip: FC<Props> = ({ placement, text, children }) => {
  const [active, setActive] = useState(false)

  const showTip = () => {
    setActive(true)
  }

  const hideTip = () => {
    setActive(false)
  }
  return (
    <div className='tooltip-wrapper' onMouseEnter={showTip} onMouseLeave={hideTip}>
      <>
        {children}
        {active ? <div className={`tooltip ${placement}`}>{text}</div> : null}
      </>
    </div>
  )
}
