import React, { ReactNode } from 'react'
import {
  AddCartItem,
  AddCartItems,
  RemoveCartItem,
  RemoveCartItems,
  UpdateCartItemData,
  UpdateCartItemLanguage,
} from 'services/utility/cart.util'
import { Cart, CartItem } from 'types/Cart'
import { Localization } from 'types/Localization'
import { createCtx } from './ContextHelper'

export const EMPTY_CART: Cart = {
  visible: false,
  items: [],
  notes: '',
  notesVisible: false,
  itemExpanded: false,
  userOverrodeVisibility: false,
  selectAll: false,
}

export enum CartActionEnum {
  ADD_ITEM,
  ADD_ITEMS,
  REMOVE_ITEM,
  REMOVE_ITEMS,
  EMPTY_CART,
  LANGUAGE_CHANGE,
  CLEAR_RECENTLY_ADDED,
  CLEAR_RECENTLY_DELETED,
  UPDATE_RECENT_BATCH_TIMER,
  TOGGLE_CART_VISIBILITY,
  UPDATE_NOTES,
  TOGGLE_NOTES_VISIBILITY,
  ITEM_EXPANDED,
  TOGGLE_VISIBILITY_OVERRIDE,
  SET_SELECT_ALL,
  UPDATE_ITEM,
}

type CartAction =
  | { type: CartActionEnum.ADD_ITEM; data: CartItem }
  | { type: CartActionEnum.ADD_ITEMS; data: CartItem[] }
  | { type: CartActionEnum.REMOVE_ITEM; data: CartItem }
  | { type: CartActionEnum.REMOVE_ITEMS; data: CartItem[] }
  | {
      type: CartActionEnum.LANGUAGE_CHANGE
      data: { cartItemId: string; localization: Localization }
    }
  | { type: CartActionEnum.EMPTY_CART }
  | { type: CartActionEnum.TOGGLE_CART_VISIBILITY; data: boolean }
  | { type: CartActionEnum.UPDATE_NOTES; data: string }
  | { type: CartActionEnum.TOGGLE_NOTES_VISIBILITY; data: boolean }
  | { type: CartActionEnum.ITEM_EXPANDED; data: boolean }
  | { type: CartActionEnum.TOGGLE_VISIBILITY_OVERRIDE; data: boolean }
  | { type: CartActionEnum.SET_SELECT_ALL; data: boolean }
  | { type: CartActionEnum.UPDATE_ITEM; data: CartItem }

type CartContextType = {
  state: Cart
  dispatch: React.Dispatch<CartAction>
}

const [useCart, CtxProvider] = createCtx<CartContextType>('Cart Context')

type Props = {
  initialState?: Cart
  children: ReactNode
}

// KEEP any side effects OUT of any reducer
const CartContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: Cart, action: CartAction): Cart {
    let items: CartItem[] = []
    switch (action.type) {
      case CartActionEnum.UPDATE_ITEM:
        items = UpdateCartItemData(ctxState.items, action.data)
        return { ...ctxState, items }
      case CartActionEnum.ADD_ITEM:
        items = AddCartItem(ctxState.items, action.data)
        return { ...ctxState, items }

      case CartActionEnum.ADD_ITEMS:
        items = AddCartItems(ctxState.items, action.data)
        return { ...ctxState, items, selectAll: false }

      case CartActionEnum.REMOVE_ITEM:
        items = RemoveCartItem(ctxState.items, action.data)
        return { ...ctxState, items }

      case CartActionEnum.REMOVE_ITEMS:
        items = RemoveCartItems(ctxState.items, action.data)
        return { ...ctxState, items }

      case CartActionEnum.LANGUAGE_CHANGE:
        items = UpdateCartItemLanguage(
          ctxState.items,
          action.data.cartItemId,
          action.data.localization
        )
        return { ...ctxState, items }
      case CartActionEnum.UPDATE_NOTES:
        return { ...ctxState, notes: action.data }
      case CartActionEnum.TOGGLE_CART_VISIBILITY:
        return { ...ctxState, visible: action.data }
      case CartActionEnum.TOGGLE_NOTES_VISIBILITY:
        return { ...ctxState, notesVisible: action.data }
      case CartActionEnum.ITEM_EXPANDED:
        return { ...ctxState, itemExpanded: action.data }
      case CartActionEnum.TOGGLE_VISIBILITY_OVERRIDE:
        return { ...ctxState, userOverrodeVisibility: action.data }
      case CartActionEnum.SET_SELECT_ALL:
        return { ...ctxState, selectAll: action.data }
      case CartActionEnum.EMPTY_CART:
        return EMPTY_CART
      default:
        console.warn('Action type not handled in Cart reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_CART)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { CartContextProvider, useCart }
