import env from '@beam-australia/react-env'
import { buildHeaders } from 'services/utility/api/api.util'
const FAVORITES_URL = `${env('HW_API_ASSIGNMENT_URL')}/favorite`
const FAVORITES_FOLDER_URL = `${env('HW_API_ASSIGNMENT_URL')}/folder`

export async function getFavorites(practitionerId: string, hwAuthToken: string): Promise<Response> {
  const getFolderUrl = `${FAVORITES_URL}/${practitionerId}`

  return fetch(getFolderUrl, {
    method: 'GET',
    headers: buildHeaders(hwAuthToken, 'application/json'),
  })
}

export async function updateFavorites(
  practitionerId: string,
  hwid: string,
  folderIds: string[],
  hwAuthToken: string,
  newFolderName?: string
): Promise<Response> {
  return fetch(FAVORITES_URL, {
    method: 'POST',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: JSON.stringify({
      practitionerId: practitionerId,
      hwid: hwid,
      folderIds: folderIds,
      newFolderName: newFolderName,
    }),
  })
}

export async function createFolder(
  practitionerId: string,
  folderName: string,
  hwAuthToken: string
): Promise<Response> {
  const createFolderUrl = `${FAVORITES_FOLDER_URL}`
  return fetch(createFolderUrl, {
    method: 'POST',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: JSON.stringify({
      practitionerId: practitionerId,
      folderName: folderName,
    }),
  })
}

export async function updateFolder(
  practitionerId: string,
  folderId: string,
  newName: string,
  hwAuthToken: string
): Promise<Response> {
  return fetch(FAVORITES_FOLDER_URL, {
    method: 'PUT',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: JSON.stringify({
      practitionerId: practitionerId,
      folderName: newName,
      folderId,
    }),
  })
}

export async function deleteFavoriteFolder(
  practitionerId: string,
  favoriteId: string,
  hwAuthToken: string
): Promise<Response> {
  const deleteFolderUrl = `${FAVORITES_FOLDER_URL}/${practitionerId}/${favoriteId}`
  return fetch(deleteFolderUrl, {
    method: 'DELETE',
    headers: buildHeaders(hwAuthToken, 'application/json'),
  })
}

export async function updateFolderOrder(
  practitionerId: string,
  folderOrder: string[],
  hwAuthToken: string
): Promise<Response> {
  const updateFolderOrderUrl = FAVORITES_FOLDER_URL + '/sort'
  return fetch(updateFolderOrderUrl, {
    method: 'PUT',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: JSON.stringify({
      practitionerId: practitionerId,
      folderIds: folderOrder,
    }),
  })
}
