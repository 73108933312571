import { LanguageSelect } from 'components/LanguageSelect/LanguageSelect'
import { FC } from 'react'
import { Localization } from 'types/Localization'

type Props = {
  availableLanguages: Localization[]
  selectedLanguage: Localization
  handleChange: (value: string) => void
}

export const SearchLanguageSelector: FC<Props> = ({
  availableLanguages,
  selectedLanguage,
  handleChange,
}) => {
  return (
    <LanguageSelect
      title={'Language'}
      selectedLanguage={selectedLanguage}
      availableLanguages={availableLanguages}
      className={'nav-search-language'}
      languageSelectElementId={'SearchLanguageSelector'}
      handleLanguageChange={handleChange}
    />
  )
}
