import env from '@beam-australia/react-env'
import { template } from 'lodash'
import { appInsights } from 'services/AppInsights'
import { delay } from 'services/utility/delay'
import { buildHeaders } from 'services/utility/api/api.util'
import { EditableContent } from 'types/Preview'

const CONTENT_URL = `${env('HW_API_ASSIGNMENT_URL')}/v2/content/preview`
const EDIT_CONTENT_URL = `${env('HW_API_ASSIGNMENT_URL')}/content/preview/edit`
const RXCHIVE_URL = env('HW_RXCHIVE_URL')
const FETCH_CONTENT_APP_INSIGHT_TEXT = 'Content::Fetch Content'
const FETCH_IHC_APP_INSIGHT_TEXT = 'Content::Fetch In-House Content'
const FETCH_ARCHIVE_CONTENT_APP_INSIGHT_TEXT = 'Content::Fetch Archive'
const FETCH_EDIT_CONTENT_APP_INSIGHT_TEXT = 'Content::Fetch Edit'

export type ContentRequest = {
  hwid: string
  language: string
  rawHtml?: string
}

export function createEditedContent(content: EditableContent): string {
  const tpl = template(content.contentWrapper)
  let result = tpl({ article: content.article })
  return result
}

export type ContentResponse = {
  key: string
  content: string
}

export const fetchContent = async (
  hwid: string,
  inHouseContent: boolean,
  hwAuthToken: string,
  lang?: string,
  logoUrl?: string | null
): Promise<string> => {
  const appInsightsText = inHouseContent
    ? FETCH_IHC_APP_INSIGHT_TEXT
    : FETCH_CONTENT_APP_INSIGHT_TEXT
  appInsights?.startTrackEvent(appInsightsText)
  let res = await fetchFormattedContentImpl(hwid, inHouseContent, hwAuthToken, lang, logoUrl)

  if (res.ok) {
    appInsights?.stopTrackEvent(appInsightsText)
    return await res.text()
  }

  return 'Cannot Load Preview'
}

const fetchFormattedContentImpl = async (
  hwid: string,
  inHouseContent: boolean,
  hwAuthToken: string,
  lang?: string,
  logoUrl?: string | null
): Promise<Response> => {
  const headers = buildHeaders(hwAuthToken, 'application/json')

  let q = CONTENT_URL
  q += `?hwid=${hwid}`
  q += lang ? `&lang=${lang}` : ''
  q += logoUrl ? `&logoUrl=${encodeURIComponent(logoUrl)}` : ''
  q += inHouseContent ? `&inHouseContent=${inHouseContent}` : ''

  return await fetch(q, {
    method: 'GET',
    headers,
  })
}

export async function fetchArchivedContent(archivedId: string): Promise<string> {
  if (!archivedId) {
    return new Promise(res => res('Invalid ArchiveId'))
  }
  appInsights?.startTrackEvent(FETCH_ARCHIVE_CONTENT_APP_INSIGHT_TEXT)

  const res = await fetch(`${RXCHIVE_URL}/${archivedId}?code=${env('HW_RXCHIVE_KEY')}`, {
    method: 'GET',
  })
  if (res.ok) {
    appInsights?.stopTrackEvent(FETCH_ARCHIVE_CONTENT_APP_INSIGHT_TEXT)
    return await res.text()
  }
  return 'Cannot Load Archived Content'
}

export interface InHouseContentResponse {
  contentUrl: string
  error?: string
}

export const fetchArchivedInHouseContentLink = (archivedId: string): InHouseContentResponse => {
  if (!archivedId) {
    return { contentUrl: '', error: 'ArchiveId Invalid' }
  }
  const url = `${RXCHIVE_URL}/${archivedId}?code=${env('HW_RXCHIVE_KEY')}`

  return { contentUrl: url }
}

export const fetchFormattedContentForEdit = async (
  hwid: string,
  hwAuthToken: string,
  lang?: string,
  logoUrl?: string | null
): Promise<EditableContent> => {
  let attempts = 0
  let maxAttempts = 5

  const headers = buildHeaders(hwAuthToken, 'application/json')

  while (attempts < maxAttempts) {
    if (attempts > 0) {
      await delay(2000)
    }
    appInsights?.startTrackEvent(FETCH_EDIT_CONTENT_APP_INSIGHT_TEXT)
    const res = await fetch(EDIT_CONTENT_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        hwid,
        lang,
        logoUrl,
      }),
    })

    if (res.ok) {
      appInsights?.stopTrackEvent(FETCH_EDIT_CONTENT_APP_INSIGHT_TEXT, {
        attempts: (attempts + 1).toString(),
      })
      return res.json()
    }
    attempts++
  }

  return { article: 'Cannot Load Editable Content', contentWrapper: '' }
}
