import { LaunchCode } from 'types/LaunchContextTypes'
import { AssignmentType } from './Assignment'
import { ContentFormat } from './ContentFormat'
import { SearchResultType } from './Search'

export enum BackEndEventType {
  ClinicalLaunch = 'clinicaladvise.clinicallaunch:v5',
  AssignmentCreated = 'clinicaladvise.assignmentcreated:v2',
  HistoryViewed = 'clinicaladvise.historyviewed:v2',
  ManualSearch = 'clinicaladvise.manualsearch:v2',
  ContentAssigned = 'clinicaladvise.contentassigned:v2',
}

export interface BackEndEventCore {
  EventType: BackEndEventType
  SessionId?: string
  OrganizationId: string
  SubscriptionId: string
  OrganizationName?: string
  PractitionerFhirId?: string
  PractitionerName?: string
  PatientPreferredLanguage?: string
  PractitionerLocation?: string
  EncounterId?: string
  PatientId?: string
  LaunchCodes?: LaunchCode[]
  AuthToken: string
  PractitionerRole: string[]
}

export interface ClinicalLaunchEvent extends BackEndEventCore {
  PatientAge?: string
  PatientSex?: string
  Platform: string
  IntegrationType: string
  TimeToFinishLaunch: string
  SearchLanguage?: string
  DateOfLaunch: string // UTC string
  PatientRace?: string
  PatientEthnicity?: string
  PatientPostalCode?: string
  PractitionerSpecialty: string[]
  PractitionerDepartment?: string
  FacilitySpecialty: string[]
  DepartmentSpecialty: string[]
  EncounterClass?: string
  EncounterType?: string[]
  Readmission: boolean | null
}

export interface AssignmentCreatedEvent extends BackEndEventCore {
  PatientAge?: string
  PatientSex?: string
  PatientRace?: string
  PatientEthnicity?: string
  PatientPostalCode?: string
  AssignmentId: string
  NumberOfTitlesInAssignment: number
  AssignmentMethod: { Value: AssignmentType }
  NoteIncluded: boolean
  VideosIncluded: boolean
  NonEnglishContentIncluded: boolean
  PractitionerSpecialty: string[]
  EncounterId?: string
  AssignmentCreateDate: string
  PdfLinks: ContentPersist[]
  HtmlLinks: ContentPersist[]
}

export interface HistoryViewedEvent extends BackEndEventCore {
  PractitionerSpecialty: string[]
  DateHistoryViewed: string
}

export interface ManualSearchEvent extends BackEndEventCore {
  PractitionerSpecialty: string[]
  SearchString: string
  SearchLanguage: string
  DateOfSearch: string
}

export interface ContentAssignedEvent extends BackEndEventCore {
  AssignmentId: string
  ContentIdentifier: string
  SearchResultType: SearchResultType
  SearchString: string
  SearchResultRank: number
  Format: { Value: ContentFormat }
  Edited: boolean
  Language: string
  Title: string
  Version: string
  Source: string
  ContentAssignedDate: string // UTC string
  PractitionerSpecialty?: string[]
}

export interface ContentPersist {
  Id: string
  Localization: string
  Hwid: string
  Link: string
  Type: 'Single' | 'Combined'
}
