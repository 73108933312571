export interface DiscoServiceResult {
  results: {
    initialFilterString: string
    facets: DiscoFacet[]
    displayName: string
    searchRating: number
  }[]
  error: boolean
  queryKey?: string
  appId?: string
  indexes?: {
    [key: string]: DiscoIndexResult
  }
}

export interface DiscoEntitlement {
  queryKey: string
  appId: string
  indexes: {
    [key: string]: DiscoIndexResult
  }
  availableLanguages: string[]
  languagePackageAttribute: string
}

export interface DiscoIndexResult {
  suggestionsIndex: string
  wordsIndex: string
}

export interface DiscoFacet {
  attributes: string[]
  values: string[]
  joiner: DiscoJoiner
  comparator?: DiscoComparator
}

export enum DiscoComparator {
  LessThan = '<=',
  GreaterThan = '>=',
}

export enum DiscoJoiner {
  OR = ' OR ',
  AND = ' AND ',
}

export enum Modifier {
  Add = 'add',
  Remove = 'remove',
}

export enum DiscoResourceTypes {
  Condition = 'Condition',
  Medication = 'Medication',
  Immunization = 'Immunization',
  Reason = 'Reason',
}

export type AgeFilterSettingsType = {
  debug: boolean | undefined
  age?: number | string | undefined
  leftAge?: number | string | undefined
  rightAge?: number | string | undefined
}

export type LanguagePackageFilterSettingsType = {
  debug: boolean | undefined
  languagePackageIdentifier?: string | undefined
  selectedLanguage?: string | undefined
}

export type BaseFilterType = {
  item?: string | number | undefined
  items?: (string | number | undefined)[]
  debug?: boolean | undefined
}

export type FilterStringBuilderSettings = {
  onChange?: OnChangeFunctionType
  age?: number
  leftAge?: number
  rightAge?: number
  audience?: string
  audiences?: string[]
  concept?: string
  concepts?: string[]
  sex?: string
  sexes?: string[]
  type?: string
  types?: string[]
  delivery?: string
  deliveries?: string[]
  specialty?: string
  specialties?: string[]
  localization?: string
  localizations?: string[]
  product?: string
  products?: string[]
  hwid?: string
  hwids?: string[]
  debug?: boolean
  displayLanguage?: string
  languagePackageIdentifier?: string
  selectedLanguage?: string
}

export type OnChangeFunctionSettings = {
  aisFilterString: string
  aisFilterList: string[]
  optionalFilterList: string[]
  isInDefaultState: boolean
  activeFilterCount: number
}

export type OnChangeFunctionType = (settings: OnChangeFunctionSettings) => any
