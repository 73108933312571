import { LaunchContext } from '../types/LaunchContextTypes'
import { AlgoliaHit } from '../types/AlgoliaHit'
// import env from '@beam-australia/react-env'

class FulfillmentSASTokenServiceInstance {
  private isInit: boolean = false
  private token?: string

  public init() {
    //TODO: Will replace with updated fulfillment method
    //Leaving structure in place
    /*    if (this.isInit) return

    env(FULFILLMENT_TOKEN_URL)
    fetch(process.env.FULFILLMENT_TOKEN_URL as string, { method: 'POST' })
      .then((r) => r.text())
      .then((t) => {
        this.token = t
        // don't return the token
        // return t
      })
      .catch((e) => console.error('Failed To Obtain Token For Fulfillment Service: ', e))

    this.isInit = true;
	   */
  }

  public logFulfillment(launch: LaunchContext, hits: AlgoliaHit[]) {
    /*
    if (!this.token) {
      // throw new Error('Missing Fulfillment Token')
      console.warn('Missing Fulfillment Token')
      return
    }

    var req = {
      search: hits,
      launchContext: launch,
    }

    env(FULFILLMENT_EVENT_HUB)
    return fetch(process.env.FULFILLMENT_EVENT_HUB as string, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: {
        Authorization: this.token,
        'Content-Type': 'text/plain',
      },
    })
		 */
  }
}

export const FulfillmentSASTokenService = new FulfillmentSASTokenServiceInstance()
