import { FC, useEffect, useState } from 'react'
import { ListBox, ListBoxItemType } from 'components/ListBox/ListBox'
import { Localization } from 'types/Localization'

const LANGUAGE_SELECT = 'languageSelector'
interface Props {
  selectedLanguage: Localization | undefined
  availableLanguages: Localization[]
  languageSelectElementId: string
  handleLanguageChange: (key: string, id?: string) => void
  id?: string
  disabledLanguages?: Localization[]
  inverseColor?: boolean
  className?: string
  title?: string
  disabled?: boolean
}
export const LanguageSelect: FC<Props> = ({
  id,
  selectedLanguage,
  availableLanguages,
  handleLanguageChange,
  className,
  disabledLanguages,
  title,
  disabled,
}) => {
  const [languageData, setLanguageData] = useState<ListBoxItemType[]>([])
  function getUniqueId(selectId: string, id: string | undefined) {
    return `${selectId}-${typeof id !== 'undefined' ? id : 'SearchLanguage'}`
  }
  useEffect(() => {
    const data: ListBoxItemType[] = availableLanguages.map(loc => {
      const disabledIndex = (disabledLanguages || []).findIndex(
        disabledLanguage => disabledLanguage.indexKey === loc.indexKey
      )
      return {
        value: loc.key,
        label: loc.name,
        shortLabel: loc.abbreviation,
        indexValue: loc.indexKey,
        disabled: disabledIndex !== -1,
      }
    })
    setLanguageData(data)
  }, [availableLanguages, disabledLanguages, setLanguageData])
  const selectedElementIndex = languageData.findIndex(
    element => selectedLanguage?.key === element.value
  )
  const uniqueId = getUniqueId(LANGUAGE_SELECT, id)
  return (
    <div id={uniqueId} data-testid={uniqueId} className={className} key={uniqueId}>
      {languageData.length > 0 ? (
        <ListBox
          data={languageData}
          title={title}
          data-testid={getUniqueId(LANGUAGE_SELECT, id)}
          onChange={(dataItem: ListBoxItemType) => handleLanguageChange(dataItem.value, id)}
          selectedElementIndex={selectedElementIndex}
          disabled={disabled}
        />
      ) : null}
    </div>
  )
}
