import { isEmpty } from 'lodash'
import { appInsights } from 'services/AppInsights'
import { DiscoServiceResult } from 'types/DiscoTypes'
import { HwEntity } from 'types/HwEntity'
import { LaunchContext } from 'types/LaunchContextTypes'
import { Patient } from 'types/Patient'
import { ResultSectionType } from 'types/ResultSections'
import { DEFAULT_LOCALIZATION, GetLocalizationFromPreferredLanguage } from './languages.util'
import { uuid } from './uuid.util'

export const addSessionId = (sessionId: string) => {
  window.sessionId = sessionId
}

export const startLaunchEventTracking = () => {
  appInsights?.startTrackEvent('Launch::Launch')
}
export const stopLaunchEventTracking = (sessionId: string, locationId: string) => {
  appInsights?.stopTrackEvent(`Launch::Launch`, {
    sessionId: sessionId,
    locationId: locationId,
  })
}
export const startFetchContextEventTracking = () => {
  appInsights?.startTrackEvent(`Launch::Fetch Context`)
}
export const stopFetchContextEventTracking = (sessionId: string, locationId: string) => {
  appInsights?.stopTrackEvent(`Launch::Fetch Context`, {
    sessionId: sessionId,
    locationId: locationId,
  })
}

export const trackUsernameMismatchEvent = (
  ehrUserName: string,
  fhirPractitionerName: string,
  sessionId: string,
  locationId?: string
) => {
  appInsights.trackEvent(
    { name: 'Launch::Username Mismatch' },
    {
      ssoUserNameEmpty: ehrUserName === '',
      fhirPractitionerNameEmpty: fhirPractitionerName === '',
      sessionId,
      locationId,
    }
  )
}

export const startInitializePractitionerEventTracking = () => {
  appInsights?.startTrackEvent('Launch::Practitioner Query')
}

export const stopInitializePractitionerEventTracking = (sessionId: string, locationId: string) => {
  appInsights?.stopTrackEvent('Launch::Practitioner Query', {
    sessionId: sessionId,
    locationId: locationId,
  })
}

export const getQueryParams = (searchParams: string) => {
  const urlParams = new URLSearchParams(searchParams)
  const statusUrl = urlParams.get('statusUrl') ?? ''
  const cacheId = urlParams.get('cacheId') ?? ''
  const use = urlParams.get('use') ?? ''

  const testContext = urlParams.get('testContext') ?? ''
  return {
    statusUrl,
    cacheId,
    testContext,
    use,
  }
}

export const getResultSectionsFromDiscoResult = (
  disco: DiscoServiceResult,
  collapsedAccordions: boolean
) => {
  return disco.results.map(result => ({
    id: uuid(),
    label: result.displayName,
    type: ResultSectionType.LAUNCH,
    collapsed: collapsedAccordions,
    data: {
      baseFilterString: result.initialFilterString,
      discoFacets: result.facets.map(facet => ({
        attributes: facet.attributes,
        comparator: facet.comparator,
        joiner: facet.joiner,
        values: facet.values,
      })),
    },
  }))
}

export const setPatient = (patient: Patient) => {
  patient.preferredLocalization = patient.preferredLanguage
    ? GetLocalizationFromPreferredLanguage(patient.preferredLanguage)
    : DEFAULT_LOCALIZATION
  return patient
}

export const validateLaunchData = (launch: LaunchContext) => {
  if (launch.error) {
    return 'Unable to return FHIR resources'
  }

  let err = ''

  if (!validPatient(launch.patient)) {
    err = 'patient'
  }

  if (!validOrganization(launch.hwOrganization)) {
    err += err ? ', organization' : 'organization'
  }

  if (isEmpty(launch.practitioner)) {
    err += err ? ', practitioner' : 'practitioner'
  }

  if (isEmpty(launch.entitlement)) {
    err += err ? ', entitlement' : 'entitlement'
  }

  return err ? `Missing data: ${err}` : ''
}

export const validPatient = (patient: Patient) => {
  if (!isEmpty(patient) && patient.id) {
    return true
  }
  return false
}

export const validOrganization = (org?: HwEntity) => {
  if (!isEmpty(org) && org.id && org.name) {
    return true
  }

  return false
}
