import React, { Dispatch, SetStateAction, useState } from 'react'
import { createCtx } from './ContextHelper'

const [useSearchTerm, CtxProvider] =
  createCtx<{ state: string; setSearchTerm: Dispatch<SetStateAction<string>> }>(
    'Search Term Context'
  )

const SearchTermCtxProvider: React.FC = ({ children }) => {
  const [state, setSearchTerm] = useState<string>('')

  return <CtxProvider value={{ state, setSearchTerm }}>{children}</CtxProvider>
}

export { SearchTermCtxProvider, useSearchTerm }
