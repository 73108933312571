import { ReactNode, FC } from 'react'
import { Modal, Box } from '@material-ui/core'

type Props = {
  show: boolean
  children: ReactNode
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #424242',
  boxShadow: 24,
  p: 4,
  padding: '16px',
  paddingRight: '0px',
  overflowY: 'hidden',
}
export const Popover: FC<Props> = ({ show, children }) => {
  return (
    <Modal
      disableEnforceFocus
      open={show}
      className='preview-modal'
      aria-labelledby='modal-title'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  )
}
