import React, { ReactNode } from 'react'
import { createCtx } from './ContextHelper'
import { ClientConfiguration } from 'types/Config'

export enum ConfigurationActionEnum {
  SET_CONFIGURATION,
  UPDATE_CONFIGURATION,
}

type ConfigurationAction =
  | { type: ConfigurationActionEnum.SET_CONFIGURATION; data: ClientConfiguration }
  | { type: ConfigurationActionEnum.UPDATE_CONFIGURATION; data: { name: string; enabled: boolean } }

type ConfigurationContextType = {
  state: ClientConfiguration
  dispatch: React.Dispatch<ConfigurationAction>
}

const [useClientConfigurations, CtxProvider] = createCtx<ConfigurationContextType>(
  'Client Configuration Context'
)
type Props = {
  initialState?: ClientConfiguration
  children: ReactNode
}
const ConfigurationsContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(
    ctxState: ClientConfiguration,
    action: ConfigurationAction
  ): ClientConfiguration {
    switch (action.type) {
      case ConfigurationActionEnum.SET_CONFIGURATION:
        return Object.assign(ctxState, action.data)
      case ConfigurationActionEnum.UPDATE_CONFIGURATION:
        const newState = { ...ctxState }
        newState[action.data.name] = action.data.enabled
        return newState
      default:
        console.warn('Action type not handled in Configuration reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? {})
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { ConfigurationsContextProvider, useClientConfigurations }
