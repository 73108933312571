import env from '@beam-australia/react-env'
import _ from 'lodash'
import { buildHeaders } from 'services/utility/api/api.util'
import {
  AssignmentCreatedEvent,
  ClinicalLaunchEvent,
  ContentAssignedEvent,
  HistoryViewedEvent,
  ManualSearchEvent,
} from 'types/EventTypes'
import { EntityParams } from 'types/HwEntity'
const EVENT_URL = `${env('HW_API_ASSIGNMENT_URL')}/events/sendEvent`

export const sendEvent = async (
  event:
    | ClinicalLaunchEvent
    | AssignmentCreatedEvent
    | HistoryViewedEvent
    | ManualSearchEvent
    | ContentAssignedEvent
): Promise<Response> => {
  const payload = { ..._.omit(event, ['SubscriptionId', 'OrganizationId', 'AuthToken']) } as any
  const params: EntityParams = {
    OrganizationId: event.OrganizationId,
    SubscriptionId: event.SubscriptionId,
  }
  return sendEventImpl(payload, params, event.AuthToken)
}

const sendEventImpl = async (
  payload: any,
  params: EntityParams,
  hwAuthToken: string
): Promise<Response> => {
  const headers = buildHeaders(hwAuthToken, 'application/json')

  return fetch(EVENT_URL, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ ...params, data: payload }),
  })
}
