import { SearchClient } from 'algoliasearch'

export interface Algolia {
  client: SearchClient | null
  state: AlgoliaState
}

export interface AlgoliaKey {
  QueryAppId: string
  QueryKey: string
}

export interface AlgoliaState {
  key: AlgoliaKey
  index: string
  // entitlement: string
  suggestions: string
  appId: string
  userToken: string
  languagePackageIdentifier: string
}

export const INITIAL_ALGOLIA: Algolia = {
  client: null,
  state: {
    key: { QueryAppId: '', QueryKey: '' },
    index: '',
    appId: '',
    // entitlement: '',
    suggestions: '',
    userToken: '',
    languagePackageIdentifier: '',
  },
}
