import React from 'react'
import { createCtx } from './ContextHelper'
import { Popover, PopoverType } from 'types/Popover'

const EMPTY_POPOVER: Popover = {
  type: PopoverType.NONE,
}

export enum PopoverActionEnum {
  SET_POPOVER,
  CLEAR_POPOVER,
}

type PopoverAction =
  | { type: PopoverActionEnum.SET_POPOVER; data: Popover }
  | { type: PopoverActionEnum.CLEAR_POPOVER }

type PopoverContextType = {
  state: Popover
  dispatch: React.Dispatch<PopoverAction>
}

const [usePopover, CtxProvider] = createCtx<PopoverContextType>('Popover Context')

const PopoverContextProvider: React.FC = ({ children }) => {
  function reducer(ctxState: Popover, action: PopoverAction): Popover {
    switch (action.type) {
      case PopoverActionEnum.SET_POPOVER:
        return { ...ctxState, ...action.data }
      case PopoverActionEnum.CLEAR_POPOVER:
        return EMPTY_POPOVER
      default:
        console.warn('Action type not handled in Popover reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, EMPTY_POPOVER)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { PopoverContextProvider, usePopover }
