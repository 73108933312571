import React from 'react'
import styles from './Button.module.scss'
import { getCommonButtonProps, getCommonAnchorProps } from 'services/utility/commonProps'
import classNames from 'classnames'

type Props = {
  children?: any
  className?: string
  href?: string
  rounded?: boolean
  rounder?: boolean
  accent?: boolean
  neutral?: boolean
  warn?: boolean
  outline?: boolean
  borderless?: boolean
  small?: boolean
  unstyled?: boolean
  type?: 'button' | 'submit' | 'reset'
  icon?: any
  onClick?: Function
  onFocus?: Function
  onBlur?: Function
  onKeyDown?: Function
  onKeyPress?: Function
  onKeyUp?: Function
}

class Button extends React.Component<Props> {
  render() {
    const {
      children,
      className,
      href,
      rounded,
      rounder,
      accent,
      neutral,
      warn,
      outline,
      borderless,
      small,
      unstyled,
      type,
    } = this.props

    const classes = classNames(
      styles.button_common,
      className,
      { [styles.button]: !unstyled },
      { [styles.unstyled]: unstyled },
      { [styles.rounded]: rounded },
      { [styles.rounder]: rounder },
      { [styles.accent]: accent },
      { [styles.neutral]: neutral },
      { [styles.warn]: warn },
      { [styles.small]: small },
      { [styles.outline]: outline },
      { [styles.borderless]: borderless }
    )
    const icon = this.props.icon ? (
      <span
        className={classNames(
          styles.icon_wrapper,
          'hw-button-icon',
          { [styles.icon_only]: !children },
          { 'hw-button-icon-only': !children }
        )}
      >
        {typeof this.props.icon === 'function' ? <this.props.icon /> : this.props.icon}
      </span>
    ) : null

    if (href) {
      return (
        <a {...getCommonAnchorProps(this)} className={classes} href={href}>
          {icon}
          {children}
        </a>
      )
    }

    return (
      <button {...getCommonButtonProps(this)} className={classes} type={type}>
        {icon}
        {children}
      </button>
    )
  }
}

export default Button
