import env from '@beam-australia/react-env'
import { LaunchContext } from '../../types/LaunchContextTypes'
import { DiscoServiceResult } from '../../types/DiscoTypes'
import { delay } from 'services/utility/delay'
import { appInsights } from 'services/AppInsights'
import { retainAllowedPropertiesStageV2 } from 'services/utility/disco.util'
import { buildHeaders } from 'services/utility/api/api.util'

const STAGE_V4_URL: string = `${env('HW_BASE_DISCO_URL')}/api/v4/stage`

export async function fetchDiscoveryV4(
  context: LaunchContext,
  appId: string,
  hwAuthToken: string
): Promise<DiscoServiceResult> {
  const searchParams = {
    method: 'POST',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: retainAllowedPropertiesStageV2(context),
  }
  searchParams.headers['app_id'] = appId

  let attempts = 0
  let maxAttempts = 5

  try {
    while (attempts < maxAttempts) {
      if (attempts === 0) {
        appInsights?.startTrackEvent(`Launch::Disco Stage`)
      }

      if (attempts > 0) {
        appInsights?.trackEvent({ name: `Launch::Disco Stage Retry` }, { attempts })
        await delay(2000) // TODO: why wait so long between calls?
      }

      const response = await fetch(STAGE_V4_URL, searchParams)

      if (response.ok) {
        appInsights?.stopTrackEvent(`Launch::Disco Stage`, {
          attempts: (attempts + 1).toString(),
          sessionId: context.contextSessionId,
          locationId: context.location?.id as string,
        })
        const responseData = await response.json()

        const { results, indexes, appId, queryKey } = responseData
        return {
          results,
          indexes,
          appId,
          queryKey,
          error: false,
        }
      }

      attempts++
    }

    return {
      results: [],
      error: true,
    }
  } catch {
    // Handle network drop errors
    return {
      results: [],
      error: true,
    }
  }
}
