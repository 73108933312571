import { LaunchContext } from './LaunchContextTypes'
import { DiscoFacet } from './DiscoTypes'

export interface SearchParams {
  context: LaunchContext
  filter: string
  pageSize: number
  page?: number
  skip?: number
  search?: string
}

export interface SearchBody extends LaunchContext {
  search: string
  searchParameters: {
    select: string
    filter?: string
  }
}

export enum SearchResultType {
  LAUNCH = 'launch',
  USER_SEARCH = 'manual search',
  FAVORITES = 'favorites',
  PREVIOUSLY_PRESCRIBED = 'previously prescribed',
  NONE = 'none',
}

export interface CodeGroup {
  displayName: string
  searchRequest: LaunchContext
}

export type FiltersMap = {
  [attribute: string]: string[]
}

export type ResultSectionsMap = {
  [sectionId: string]: ResultSectionType
}
export enum SectionType {
  SEARCH,
  LAUNCH,
}
export interface ResultSectionType {
  sectionId: string
  label: string
  context: LaunchContext
  baseFilterString?: string
  discoFacets: DiscoFacet[]
  sectionType?: SectionType
}

export interface ResultSectionTypeV2 {
  index: number
  label: string
  context: LaunchContext
  baseFilterString?: string
  discoFacets: DiscoFacet[]
}
export interface NewSearchResponse {
  Request: {
    codes: any[]
    diagnoses: any[]
    reasons: any[]
    encounterDetails: any[]
  }
  Results: {
    delivery: any
    maxAge: number
    minAge: number
    primaryConcepts: string[]
    secondaryConcepts?: string[]
    sex: string[]
  }
  codeGroup: CodeGroup
}

export interface AlgoliaSearchContext {
  filterString: string
  codeGroup: CodeGroup
  discoFacets: DiscoFacet[]
}
