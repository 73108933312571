import { Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core'
import HealthwiseContent from 'components/HealthwiseContent/HealthwiseContent'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { FC, useEffect, useState } from 'react'
import { fetchArchivedContent, fetchContent } from 'services/apis/content.api'
import { usePreview } from 'services/context/PreviewContentCtx'
import { PreviewHeader } from './PreviewHeader'
import { useFeatures } from 'services/context/FeatureContext'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import './Preview.scss'

type Props = {
  show: boolean
}

export const PreviewDialog: FC<Props> = ({ show }) => {
  const { state: preview } = usePreview()
  const { state: clientConfigurations } = useClientConfigurations()
  const [markupContent, setMarkupContent] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const { state: features } = useFeatures()

  useEffect(() => {
    if (preview.cartItem?.edited && preview.cartItem?.editedContent?.article) {
      setMarkupContent(preview.cartItem.editedContent.article)
      setIsLoading(false)
    } else if (preview.previewId && isLoading) {
      if (preview.contentArtifact) {
        fetchArchivedContent(preview.contentArtifact)
          .then(content => {
            setMarkupContent(content)
          })
          .catch(err => {
            console.error('Error fetching content ', err)
          })
          .finally(() => setIsLoading(false))
      } else if (preview.content) {
        setMarkupContent(preview.content)
        setIsLoading(false)
      } else {
        fetchContent(
          preview.previewId,
          preview.cartItem.inHouse,
          clientConfigurations['hwAccessToken'],
          preview.previewLanguageKey,
          clientConfigurations['customLogoUri']
        )
          .then(content => {
            setMarkupContent(content)
          })
          .catch(err => {
            console.error('Error fetching content ', err)
          })
          .finally(() => setIsLoading(false))
      }
    }
  }, [
    preview.previewId,
    preview.previewLanguageKey,
    preview.cartItem?.editedContent?.article,
    preview.cartItem?.edited,
    isLoading,
    clientConfigurations,
    preview.contentArtifact,
    preview.content,
    preview.cartItem.inHouse,
    features,
  ])

  const contentPreview = (
    <HealthwiseContent
      html={markupContent}
      hwid={preview.previewId}
      type={preview.previewType}
      aria-hidden={!preview.show}
      aria-describedby={''}
      tabIndex={-1}
    />
  )

  return (
    <Dialog
      className='preview-modal'
      data-testid='preview-modal'
      open={show}
      scroll='paper'
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle>
        <PreviewHeader allowEdit={clientConfigurations['allowEdit'] && !preview.cartItem.inHouse} />
        <Divider />
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <LoadingSpinner msg={'Getting content...'} />
        ) : (
          <div className='preview-content' data-testid='preview-content'>
            {contentPreview}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
