import { CreateAssignmentContentDTO, CreateAssignmentV3Params } from 'types/Assignment'
import { getPatientWritebackIdNumber, getPatientWritebackIdType } from './cart.util'
import { createEditedContent } from 'services/apis/content.api'
import { CartItem } from 'types/Cart'
import { Practitioner, LaunchContext } from 'types/LaunchContextTypes'
import { Patient } from 'types/Patient'
import { ContentPersist } from 'types/EventTypes'
import { PersistedContent } from 'types/PdfResult'

export function createAssignmentDto(
  assignmentType: string,
  practitioner: Practitioner,
  launch: LaunchContext,
  patient: Patient,
  items: CartItem[],
  logoUrl: string,
  createIndividualPdf: boolean,
  notes: string,
  skipWriteback: boolean
) {
  const content: CreateAssignmentContentDTO[] = items.map(item => {
    let contentItem: CreateAssignmentContentDTO = {
      hwid: item.hwid,
      language: item.localization.key,
      contentType: item.type,
      docType: item.docType,
      title: item.title,
      edited: item.edited,
      rawHtml: item.edited ? createEditedContent(item.editedContent) : '',
    }

    if (item.estimatedTimeToReadSeconds) {
      contentItem.estimatedTimeToReadSeconds = item.estimatedTimeToReadSeconds
    }

    // We dont have videoDurationSeconds yet, see ticket
    //https://healthwise.atlassian.net/browse/CLN-1310
    // if (item.videoDurationSeconds) {
    //   contentItem.videoDurationSeconds = item.videoDurationSeconds
    // }
    return contentItem
  })

  const params: CreateAssignmentV3Params = {
    practitionerId: practitioner.id,
    providerId: launch.practitioner?.writebackId,
    providerIdType: launch.practitioner?.writebackIdType,
    assignmentType: assignmentType,
    encounterFhirId: launch.encounter?.id,
    encounterId: launch.encounter?.visitNumber,
    encounterDate: launch.encounter.date,
    patientFhirId: patient.id,
    patientAddress: patient.address,
    patientId: getPatientWritebackIdNumber(launch),
    patientIdType: getPatientWritebackIdType(launch),
    patientPhoneNumber: patient.phoneNumber ?? '',
    patientPhoneNumberType: patient.phoneNumberType ?? '',
    patientDateOfBirth: patient.dateOfBirth ?? '',
    patientFirstName: patient.firstName ?? '',
    patientLastName: patient.lastName ?? '',
    patientGender: patient.gender ?? '',
    sessionId: window.sessionId,
    content: content,
    attribution: practitioner.name,
    logoUrl: logoUrl,
    createIndividualPdf: createIndividualPdf, // combinatino of two features,
    destinations: launch.writebackDestinations,
    isRedoxTestEnvironment: launch?.isRedoxTestEnvironment ?? false,
    notes: notes,
    skipWriteback: skipWriteback,
  }

  // We have extra validation for email and empty string fails that validation
  // only add email when it isn't empty.
  if (patient.email) {
    params.patientEmail = patient.email
  }

  return params
}

export function convertPersistedContentToContentPersist(
  content: PersistedContent[]
): ContentPersist[] {
  if (content.length < 1) {
    return [] as ContentPersist[]
  }

  return content.map(c => {
    return {
      Id: c.id,
      Localization: c.localization,
      Hwid: c.hwid,
      Link: c.url,
      Type: c.type,
    } as ContentPersist
  })
}
