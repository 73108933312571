import { IExceptionTelemetry } from '@microsoft/applicationinsights-web'
import { appInsights } from 'services/AppInsights'

export const trackErrorToAppInsights = (message: string, name: string): void => {
  const err: IExceptionTelemetry = {
    error: {
      name: name,
      message: message,
    },
  }

  appInsights.trackException(err)
}
