import { FC, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/Button/Button'
import { FavoritesActionEnum, useFavorites } from 'services/context/FavoritesContext'
import { updateFavorites } from 'services/apis/favorites.api'
import {
  errorCheck,
  getDefaultFolder,
  getUniqueFavoritesList,
} from 'services/utility/favorites.util'
import { InsightEventNames, sendSearchEvent } from 'services/utility/algoliainsight.util'
import { usePractitioner } from 'services/context/PractitionerContext'
import { useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { ValidationError } from 'types/Error'
import { AlgoliaInsightEventData } from 'types/AlgoliaInsightEventData'
import { actionKeys, keyCodes } from 'services/utility/common'
import { useSnackBar, SnackBarActionEnum } from 'services/context/SnackbarContext'
import { uuid } from 'services/utility/uuid.util'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'
import { appInsights } from 'services/AppInsights'
import afterFrame from 'afterframe'
import './Favorites.scss'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import {
  DONE_TEXT,
  FAVORITES_UPDATED_SUCCESSFULLY,
  PLUS_NEW_LIST_TEXT,
  UNABLE_TO_UPDATE_FAVORITES,
} from 'services/utility/strings/favorites'
import { TRY_AGAIN } from 'services/utility/strings'

type Props = {
  hwid: string
  isFavorite: boolean
  algoliaInsightEventData: AlgoliaInsightEventData
  close: () => void
}

const MANAGE_FAVORITES_ID = 'manage-favorites'
const MAX_CHARACTER_LENGTH = 50

export const ManageFavorites: FC<Props> = ({
  hwid,
  isFavorite,
  algoliaInsightEventData,
  close,
}) => {
  const { state: favorites, dispatch: favoritesDispatch } = useFavorites()
  const { state: practitioner } = usePractitioner()
  const { state: clientConfigurations } = useClientConfigurations()
  const { state: eventPayloadState } = useEventPayloadContext()
  const [showNewFolderInput, setShowNewFolderInput] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [charactersRemaining, setCharactersRemaining] = useState(MAX_CHARACTER_LENGTH)
  const { dispatch: snackDispatch } = useSnackBar()
  const [initialRender, setInitialRender] = useState(true)
  const [manageFavoritesFolders, setManageFavoritesFolders] = useState(() => {
    return favorites.favoritesFolders
      .filter(folder => folder.Favorites.find(fav => fav.hwid === hwid))
      .map(folder => folder.FolderId)
  })

  useEffect(() => {
    if (initialRender) {
      appInsights.stopTrackEvent('Favorites::Dialog Open', {
        sessionId: eventPayloadState.sessionId,
        locationId: eventPayloadState.location?.id as string,
      })
      setInitialRender(false)
    }
  }, [initialRender, eventPayloadState.sessionId, eventPayloadState.location?.id, setInitialRender])

  const renderFavoritesFolders = favorites.favoritesFolders
    ? favorites.favoritesFolders.map(folder => {
        const existsInFolder = manageFavoritesFolders.includes(folder.FolderId)
        return (
          <FormControlLabel
            data-testid='manage-favorites-favorite'
            control={
              <Checkbox
                data-testid='manage-favorites-favorite-checkbox'
                checked={existsInFolder}
                onChange={event => handleCheck(event.target.checked, folder.FolderId)}
                onClick={e => stopListItemPreviewFromPropagating(e)}
                name={folder.FolderName}
              />
            }
            label={folder.FolderName}
            key={folder.FolderId}
          />
        )
      })
    : null

  const handleCheck = async (checked: boolean, folderId: string) => {
    if (checked) {
      setManageFavoritesFolders(prev => [...prev, folderId])
    } else {
      const folders = manageFavoritesFolders.filter(val => val !== folderId)
      sendSearchEvent(InsightEventNames.unfavorited, algoliaInsightEventData)
      setManageFavoritesFolders([...folders])
    }
  }

  const handleSave = async () => {
    if (errors.length < 1) {
      // Start event tracking
      appInsights.startTrackEvent('Favorites::Saved')

      // If no folder is selected, but done is clicked, add to default folder (cln-475)
      const foldersToAdd = [...manageFavoritesFolders]
      if (foldersToAdd.length === 0 && !isFavorite && !newFolderName) {
        const defaultFolder = getDefaultFolder(favorites.favoritesFolders)
        if (defaultFolder) {
          foldersToAdd.push(defaultFolder.FolderId)
        }
      }
      const response = await updateFavorites(
        practitioner.id,
        hwid,
        foldersToAdd,
        clientConfigurations['hwAccessToken'],
        newFolderName
      )

      if (response.ok) {
        const json = await response.json()
        const uniqueFavorites = getUniqueFavoritesList(json)
        favoritesDispatch({ type: FavoritesActionEnum.SET_FAVORITES, data: json })
        favoritesDispatch({ type: FavoritesActionEnum.SET_UNIQUE_FAVORITES, data: uniqueFavorites })

        const snack = {
          show: true,
          message: FAVORITES_UPDATED_SUCCESSFULLY,
          backgroundColor: SnackbarBackgroundColor.SUCCESS,
          textColor: SnackbarTextColor.WHITE,
          duration: 5000,
          key: uuid(),
        }
        snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
      } else {
        const snack = {
          show: true,
          message: `${UNABLE_TO_UPDATE_FAVORITES} ${TRY_AGAIN}`,
          backgroundColor: SnackbarBackgroundColor.ERROR,
          textColor: SnackbarTextColor.WHITE,
          duration: 5000,
          key: uuid(),
        }
        snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
      }
      // end event tracking
      afterFrame(() => {
        appInsights.stopTrackEvent('Favorites::Saved', {
          sessionId: eventPayloadState.sessionId,
          locationId: eventPayloadState.location?.id as string,
        })
      })

      handleClose()
    } else {
      const snack = {
        show: true,
        message: `${UNABLE_TO_UPDATE_FAVORITES} ${TRY_AGAIN}`,
        backgroundColor: SnackbarBackgroundColor.ERROR,
        textColor: SnackbarTextColor.WHITE,
        duration: 5000,
        key: uuid(),
      }
      snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
    }
  }

  const stopListItemPreviewFromPropagating = (e: any) => {
    e.stopPropagation()
  }

  const handleKeyDown = async (e: any) => {
    stopListItemPreviewFromPropagating(e)
    var key: number = e.which || e.keyCode
    const keyCode = keyCodes[key]
    if (keyCode === actionKeys.Enter) {
      const folderNameErrors = errorCheck(newFolderName, favorites.favoritesFolders, true)
      setCharactersRemaining(MAX_CHARACTER_LENGTH - newFolderName.length)
      setErrors(folderNameErrors)
      if (folderNameErrors.length === 0) {
        await handleSave()
      }
    }
  }

  const handleSaveClick = async (e: any) => {
    stopListItemPreviewFromPropagating(e)
    const folderNameErrors = errorCheck(newFolderName, favorites.favoritesFolders, true)
    setCharactersRemaining(MAX_CHARACTER_LENGTH - newFolderName.length)
    setErrors(folderNameErrors)
    if (folderNameErrors.length === 0) {
      await handleSave()
    }
  }

  const handleFolderNameChange = (value: string) => {
    const folderNameErrors = errorCheck(value, favorites.favoritesFolders, true)
    setCharactersRemaining(MAX_CHARACTER_LENGTH - value.length)
    setErrors(folderNameErrors)
    setNewFolderName(value)
  }

  const handleClose = () => {
    close()
  }

  const handleNewFolderClick = (e: any) => {
    setErrors([])
    stopListItemPreviewFromPropagating(e)
    setShowNewFolderInput(true)
  }

  const hasErrors = errors.length > 0
  const maxFolderNameLengthExceeded = MAX_CHARACTER_LENGTH - newFolderName.length < 0
  const showErrors = hasErrors || maxFolderNameLengthExceeded
  const doneButtonClassname =
    showNewFolderInput && (hasErrors || maxFolderNameLengthExceeded || newFolderName.length === 0)
      ? 'controls-done controls-done-disabled'
      : 'controls-done'
  const charactersRemainingClassname = maxFolderNameLengthExceeded
    ? 'controls-characters-remaining-red'
    : 'controls-characters-remaining'
  const errorText = hasErrors ? errors[0].description : ''
  return (
    <div onClick={e => stopListItemPreviewFromPropagating(e)}>
      <Box id={MANAGE_FAVORITES_ID} className='manage-favorites' data-testid='manage-favorites'>
        <div className='manage-favorites-header'>
          <div className='header-title'>Save to...</div>
          <div className='header-close'>
            <IconButton
              size='small'
              onClick={e => {
                stopListItemPreviewFromPropagating(e)
                handleClose()
              }}
              aria-label='Close Favorite Dialog'
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <div className='manage-favorites-folders'>
          <FormControl className='' component='fieldset' variant='standard'>
            {renderFavoritesFolders}
          </FormControl>
        </div>

        <div className='manage-favorites-controls'>
          {showNewFolderInput ? (
            <div>
              <span
                className={charactersRemainingClassname}
                data-testid='control-characters-remaining'
              >
                {`Characters remaining: ${charactersRemaining}`}
              </span>
              <TextField
                error={showErrors}
                className='controls-new-folder'
                inputProps={{ 'data-testid': 'new-list-textfield' }}
                autoFocus={true}
                label='List Name'
                size='small'
                variant='filled'
                value={newFolderName}
                onKeyDown={e => handleKeyDown(e)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFolderNameChange(event.target.value)
                }
                helperText={errorText}
              />
            </div>
          ) : (
            <Button
              className='controls-add-list'
              data-testid='plus-new-list-button'
              aria-label={PLUS_NEW_LIST_TEXT}
              onClick={(e: any) => {
                handleNewFolderClick(e)
              }}
            >
              {PLUS_NEW_LIST_TEXT}
            </Button>
          )}
          <Button
            className={doneButtonClassname}
            data-testid='list-done-button'
            aria-label={DONE_TEXT}
            onClick={(e: any) => {
              handleSaveClick(e)
            }}
          >
            {DONE_TEXT}
          </Button>
        </div>
      </Box>
    </div>
  )
}
