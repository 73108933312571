import React from 'react'
import {
  Encounter,
  LaunchCode,
  Location,
  Organization,
  Practitioner,
  PractitionerRole,
} from 'types/LaunchContextTypes'
import { Patient } from 'types/Patient'
import { createCtx } from './ContextHelper'

export enum EventPayloadActionEnum {
  SET_EVENT_PAYLOAD,
}

export type EventPayloadAction = {
  type: EventPayloadActionEnum.SET_EVENT_PAYLOAD
  data: EventPayloadContextState
}

export type EventPayloadContextType = {
  state: EventPayloadContextState
  dispatch: React.Dispatch<EventPayloadAction>
}

export type EventPayloadContextState = {
  sessionId: string
  organization?: Organization
  practitioner?: Practitioner
  patient?: Patient
  location?: Location
  department?: Location
  launchCodes?: LaunchCode[]
  practitionerRole?: PractitionerRole
  encounter?: Encounter
  organizationId: string
  organizationName: string
  subscriptionId: string
  accessToken: string
}

export const EMPTY_EVENT_PAYLOAD_STATE: EventPayloadContextState = {
  sessionId: '',
  organizationId: '',
  organizationName: '',
  subscriptionId: '',
  accessToken: '',
}

type Props = {
  initialState?: EventPayloadContextState
}

const [useEventPayloadContext, CtxProvider] =
  createCtx<EventPayloadContextType>('Event Payload Context')

const EventPayloadContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(
    ctxState: EventPayloadContextState,
    action: EventPayloadAction
  ): EventPayloadContextState {
    switch (action.type) {
      case EventPayloadActionEnum.SET_EVENT_PAYLOAD:
        return { ...ctxState, ...action.data }
      default:
        console.warn('Action type not handled in Event Payload reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_EVENT_PAYLOAD_STATE)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { EventPayloadContextProvider, useEventPayloadContext }
