import Button from 'components/Button'
import { AlertDialog } from 'components/Dialog/AlertDialog'
import { useEffect } from 'react'
import { FC, useState } from 'react'

type Props = {
  inactivityTimeoutWarningSeconds: number
  inactivityTimeoutSeconds: number
  appTimeoutWarningSeconds: number
  appTimeoutSeconds: number
}

export const Timeout: FC<Props> = ({
  inactivityTimeoutWarningSeconds,
  inactivityTimeoutSeconds,
  appTimeoutSeconds,
  appTimeoutWarningSeconds,
}) => {
  const activityEvents = ['click', 'scroll', 'keypress']

  const [inactivityWarningTimer, setInactivityWarningTimer] = useState<number>(0)
  const [inactivityWarningTimedOut, setInactivityWarningTimedOut] = useState<boolean>(false)

  const [sessionWarningTimer, setSessionWarningTimer] = useState<number>(0)
  const [sessionWarningTimedOut, setSessionWarningTimedOut] = useState<boolean>(false)
  const [sessionWarningAcknowledged, setSessionWarningAcknowledged] = useState<boolean>(false)

  const [sessionTimer, setSessionTimer] = useState<number>(0)
  const [sessionTimedOut, setSessionTimedOut] = useState<boolean>(false)

  useEffect(() => {
    const timerId = setInterval(() => setInactivityWarningTimer(inactivityWarningTimer + 1), 1000)
    if (
      inactivityWarningTimer >= inactivityTimeoutWarningSeconds &&
      inactivityWarningTimer <= inactivityTimeoutSeconds
    ) {
      setInactivityWarningTimedOut(true)
    } else if (
      !sessionWarningTimedOut &&
      inactivityWarningTimer >= inactivityTimeoutWarningSeconds &&
      inactivityWarningTimer >= inactivityTimeoutSeconds
    ) {
      setSessionTimedOut(true)
    }
    return () => clearInterval(timerId)
  }, [
    inactivityWarningTimer,
    inactivityTimeoutWarningSeconds,
    sessionWarningTimedOut,
    inactivityTimeoutSeconds,
    setInactivityWarningTimer,
    setInactivityWarningTimedOut,
    setSessionTimedOut,
  ])

  useEffect(() => {
    const timerId = setInterval(() => setSessionWarningTimer(sessionWarningTimer + 1), 1000)
    if (sessionWarningTimer >= appTimeoutWarningSeconds) {
      setSessionWarningTimedOut(true)
    }
    return () => clearInterval(timerId)
  }, [sessionWarningTimer, appTimeoutWarningSeconds, setSessionWarningTimer])

  useEffect(() => {
    const timerId = setInterval(() => setSessionTimer(sessionTimer + 1), 1000)
    if (sessionTimer >= appTimeoutSeconds) {
      setSessionTimedOut(true)
    }
    return () => clearInterval(timerId)
  }, [sessionTimer, appTimeoutSeconds, setSessionTimer, setSessionTimedOut])

  useEffect(() => {
    activityEvents.forEach(event => document.addEventListener(event, resetInactivityTimer))
    return () => {
      activityEvents.forEach(event => document.removeEventListener(event, resetInactivityTimer))
    }
  })

  const resetInactivityTimer = () => {
    setInactivityWarningTimer(0)
    setInactivityWarningTimedOut(false)
  }

  return (
    <div className='timer-container'>
      <AlertDialog
        title='Still working?'
        message={`You have been inactive for ${Math.ceil(
          inactivityTimeoutWarningSeconds / 60
        )} minutes.`}
        show={inactivityWarningTimedOut && !sessionWarningTimedOut && !sessionTimedOut}
      >
        <Button
          rounded
          borderless
          neutral
          small
          type='button'
          onClick={() => resetInactivityTimer()}
        >
          Continue Working
        </Button>
      </AlertDialog>
      <AlertDialog
        title='Session Expires Soon'
        message={
          (appTimeoutSeconds - sessionTimer) / 60 > 1
            ? `Your hour-long session will end in ${Math.ceil(
                (appTimeoutSeconds - sessionTimer) / 60
              )} minutes`
            : `Your hour-long session will end in 1 minute`
        }
        subMessage={`You will need to re-launch the application`}
        show={sessionWarningTimedOut && !sessionWarningAcknowledged && !sessionTimedOut}
      >
        <Button
          className='session-warning-button'
          rounded
          borderless
          neutral
          small
          type='button'
          onClick={() => setSessionWarningAcknowledged(true)}
        >
          Continue
        </Button>
      </AlertDialog>
      <AlertDialog
        title='Session Expired'
        message={'You will need to re-launch the application'}
        show={sessionTimedOut}
      ></AlertDialog>
    </div>
  )
}
