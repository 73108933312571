import React, { FC, useEffect, useRef, useState } from 'react'
import { appInsights } from 'services/AppInsights'
import afterFrame from 'afterframe'
import { FormControl, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import { AutocompleteProvided, Hit } from 'react-instantsearch-core'
import { Configure, connectAutoComplete, Index } from 'react-instantsearch-dom'
import { useHistory, useLocation } from 'react-router-dom'

import { useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { PopoverActionEnum, usePopover } from 'services/context/PopoverContext'
import { PreviewActionEnum, usePreview } from 'services/context/PreviewContentCtx'
import { useSearchLanguage } from 'services/context/SearchLanguageContext'
import { useSearchTerm } from 'services/context/SearchTermContext'
import { FilterHandlerType, FilterPropertiesType } from 'services/filters/filter.config'
import {
  analyticsTags,
  InsightEventNames,
  sendSearchEvent,
} from 'services/utility/algoliainsight.util'
import { CartItemFromHit } from 'services/utility/cart.util'
import { actionKeys } from 'services/utility/common'
import { normalizeHits } from 'services/utility/index.util'
import { DEFAULT_LOCALIZATION, GetAvailableLocalizations } from 'services/utility/languages.util'
import { useWhiteInputBackground } from 'services/utility/styles.util'
import { Algolia } from 'types/Algolia'
import { AlgoliaHit, ConceptHit } from 'types/AlgoliaHit'
import { PopoverType } from 'types/Popover'
import { PreviewUserActions } from 'types/Preview'
import { AutocompleteResultsList } from './AutocompleteResultsList'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import './CustomAutocomplete.scss'
import { useFeatures } from 'services/context/FeatureContext'
import { ResultsActionEnum, useResults } from 'services/context/ResultsContext'
import { uuid } from 'services/utility/uuid.util'
import { ResultSectionType, ResultSection } from 'types/ResultSections'
import { SearchResultType } from 'types/Search'
import { fetchContent } from 'services/apis/content.api'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { SnackBarActionEnum, useSnackBar } from 'services/context/SnackbarContext'
import { previewInHouseContentPDF } from 'services/utility/collapsibleListItem.util'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'
import { useQueryClient } from '@tanstack/react-query'

type Props = AutocompleteProvided<AlgoliaHit> & {
  algolia: Algolia
  inverseColor?: boolean
  wrapperId: string
  className?: string
  activeSectionId?: string
  filterProperties: FilterPropertiesType
  filterHandler: FilterHandlerType
  setActiveSectionId?: (id: string) => void
}
const SEARCH_TEXT = 'Search'
const CLEAR_TEXT = 'Clear'
const AUTOCOMPLETE_ID = 'search-autocomplete-control'

const CustomSearchBarImpl: FC<Props> = ({
  algolia,
  hits,
  refine,
  currentRefinement,
  wrapperId,
  className,
  inverseColor = true,
  activeSectionId,
  setActiveSectionId,
}) => {
  const { state: searchTerm, setSearchTerm } = useSearchTerm()
  const [hasFocus, setFocus] = useState<boolean>(false)
  const [popperAnchorEl, setPopperAnchorEl] = useState<any>(null)
  const inputRef = useRef<HTMLInputElement>()
  const filledClass = useWhiteInputBackground()
  const [selectedListItemIndex, setSelectedListItemIndex] = useState<number>(0)
  const location = useLocation()
  const history = useHistory()
  const { dispatch: previewDispatch } = usePreview()
  const { state: searchLanguage } = useSearchLanguage()
  const { state: eventPayloadState } = useEventPayloadContext()
  const { dispatch: popoverDispatch } = usePopover()
  const { state: features } = useFeatures()
  const { state: results, dispatch: resultsDispatch } = useResults()
  const { state: clientConfigurations } = useClientConfigurations()
  const { dispatch: snackDispatch } = useSnackBar()
  const queryClient = useQueryClient()

  function goHome() {
    // navigate to main if on any other view
    if (location.pathname !== '/') {
      history.replace('/')
    }
  }

  const normalizedHits: Hit<AlgoliaHit>[] = normalizeHits<AlgoliaHit>(hits, algolia.state.index)
  const suggestionHits: Hit<ConceptHit>[] = normalizeHits<ConceptHit>(
    hits,
    algolia.state.suggestions
  )

  useEffect(() => {
    refine(searchTerm)

    const testElementIsOutsideSearchStack = (target: HTMLElement | Element | null): boolean => {
      const $wrapper = document.getElementById(wrapperId)
      const $auto = document.getElementById(AUTOCOMPLETE_ID)
      let elementIsInsideWrapper = $wrapper && $wrapper.contains(target)
      let elementIsInsideAutocomplete = $auto && $auto.contains(target)
      return !elementIsInsideWrapper && !elementIsInsideAutocomplete
    }

    const handleGlobalKey = (e: any) => {
      if (e.keyCode === actionKeys.Enter && !testElementIsOutsideSearchStack(e.target)) {
        e.preventDefault()
        setFocus(false)
        if (location.pathname !== '/') {
          history.replace('/')
        }
      } else if (
        document.activeElement?.tagName !== 'BODY' &&
        testElementIsOutsideSearchStack(document.activeElement)
      ) {
        setFocus(false)
      }
    }

    const handleOutsideClick = (e: any) => {
      if (testElementIsOutsideSearchStack(e.target)) {
        setSelectedListItemIndex(-1)
        setFocus(false)
      }
    }

    window.addEventListener('keydown', handleGlobalKey)
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('keydown', handleGlobalKey)
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [
    refine,
    searchTerm,
    currentRefinement,
    wrapperId,
    popperAnchorEl,
    history,
    location.pathname,
    features,
  ])

  const handleShowSearchResult = (show: boolean) => {
    // check to see if search exists before adding new one
    if (show) {
      // check if search results already exists..
      const searchSectionExists = results.find(result => result.type === ResultSectionType.SEARCH)
      if (searchSectionExists) {
        // figure this out
      } else {
        const searchResultSection: ResultSection = {
          id: uuid(),
          label: 'Search Results',
          data: {
            baseFilterString: '',
            discoFacets: [],
          },
          type: ResultSectionType.SEARCH,
          collapsed: false,
        }

        const newResults = [...results]
        newResults.unshift(searchResultSection)
        resultsDispatch({ type: ResultsActionEnum.SET_RESULTS, data: newResults })
      }
    } else {
      const arr = results.filter(res => res.type !== ResultSectionType.SEARCH)
      if (JSON.stringify(arr) !== JSON.stringify(results)) {
        resultsDispatch({ type: ResultsActionEnum.SET_RESULTS, data: arr })
      }
    }
  }

  const activateResult = () => {
    const $listItems: NodeListOf<HTMLElement> | undefined = document
      .getElementById(AUTOCOMPLETE_ID)
      ?.querySelectorAll('.MuiListItem-root')
    if ($listItems && $listItems.length > 0 && $listItems[selectedListItemIndex]) {
      const $selectedListItem = $listItems[selectedListItemIndex]
      const $checkbox = $selectedListItem.querySelector(
        'input[type="checkbox"]'
      ) as HTMLInputElement
      if ($checkbox) {
        $checkbox.click()
      } else {
        $selectedListItem.click()
        handleShowSearchResult(true)
      }
    } else if (selectedListItemIndex === -1) {
      handleShowSearchResult(true)
      setFocus(false)
    }
    setSelectedListItemIndex(-1)
  }

  const focusInput = () => {
    inputRef?.current?.focus()
    setSelectedListItemIndex(-1)
    setFocus(true)
  }

  const handleInputKeyUp = (e: any) => {
    if (e.keyCode === actionKeys.Enter && currentRefinement?.trim().length > 0) {
      import('services/utility/events/bee/event.util').then(event => {
        event.sendManualSearchEvent(eventPayloadState, currentRefinement, searchLanguage)
      })
      handleShowSearchResult(true)
    }
    if (e.keyCode === actionKeys.ArrowDown) {
      e.preventDefault()
      setSelectedListItemIndex(0)
    } else if (
      currentRefinement.length === 0 &&
      (e.keyCode === actionKeys.Backspace || e.keyCode === actionKeys.Delete)
    ) {
      focusInput()
    }
  }

  const handleItemKey = (e: any) => {
    const lastElementIndex = normalizedHits.length + suggestionHits.length - 1

    if (e.keyCode === actionKeys.ArrowDown || e.keyCode === actionKeys.ArrowRight) {
      e.preventDefault()
      if (selectedListItemIndex < lastElementIndex) {
        setSelectedListItemIndex(selectedListItemIndex + 1)
      }
    } else if (e.keyCode === actionKeys.ArrowUp || e.keyCode === actionKeys.ArrowLeft) {
      e.preventDefault()
      if (selectedListItemIndex > 0) {
        setSelectedListItemIndex(selectedListItemIndex - 1)
      } else {
        focusInput()
      }
    } else if (e.keyCode === actionKeys.Home) {
      e.preventDefault()
      setSelectedListItemIndex(0)
    } else if (e.keyCode === actionKeys.End) {
      e.preventDefault()
      setSelectedListItemIndex(lastElementIndex)
    } else if (e.keyCode === actionKeys.Enter || e.keyCode === actionKeys.Space) {
      e.preventDefault()
      activateResult()
    }
  }

  const handleFocus = (e: any) => {
    if (!popperAnchorEl) setPopperAnchorEl(e.target)
    setFocus(true)
  }

  const handleIconClick = () => {
    if (currentRefinement.length > 0) {
      setSearchTerm('')
      refine('')
      focusInput()
      handleShowSearchResult(false)
    }
  }

  const refineSearch = (label: string) => {
    if (label) {
      appInsights.startTrackEvent('Search::Autocomplete::Commit')
      setSearchTerm(label)
      refine(label)
      setFocus(false)
      goHome()
      handleShowSearchResult(true)

      afterFrame(() => {
        appInsights.stopTrackEvent('Search::Autocomplete::Commit', {
          sessionId: eventPayloadState.sessionId,
          locationId: eventPayloadState.location?.id as string,
        })
      })
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    appInsights.startTrackEvent('Search::Autocomplete::Keypress')
    handleShowSearchResult(false)
    setSearchTerm(e.currentTarget.value)
    refine(e.currentTarget.value)
    setSelectedListItemIndex(-1)
    afterFrame(() => {
      appInsights.stopTrackEvent('Search::Autocomplete::Keypress', {
        sessionId: eventPayloadState.sessionId,
        locationId: eventPayloadState.location?.id as string,
      })
    })
  }

  const handleListItemClick = async (hwid: string, checked: boolean) => {
    const hitsIndex = normalizedHits.findIndex(item => item.content.hwid === hwid)
    const result = hitsIndex !== -1 ? normalizedHits[hitsIndex] : null
    const searchResultRank = hitsIndex + 1
    if (result) {
      if (result.content?.inHouse) {
        const { hwid } = result.content
        try {
          const res = await queryClient.fetchQuery(
            ['content', `${hwid}-${DEFAULT_LOCALIZATION.key}`],
            {
              queryFn: () =>
                fetchContent(
                  hwid,
                  true,
                  clientConfigurations['hwAccessToken'],
                  DEFAULT_LOCALIZATION.key,
                  clientConfigurations['customLogoUri']
                ),
            }
          )
          previewInHouseContentPDF(res)
        } catch (e: any) {
          const snack = {
            show: true,
            message: `Unable to access In-House PDF`,
            backgroundColor: SnackbarBackgroundColor.ERROR,
            textColor: SnackbarTextColor.WHITE,
            duration: 5000,
            key: uuid(),
          }
          snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
        }
      } else {
        const sectionId = checked ? 'cart' : 'TEXT_SEARCH'
        const activeHitId = `${result?.content?.hwid}_${sectionId}_${hitsIndex}`
        const item = CartItemFromHit(
          result,
          algolia.state.suggestions,
          algolia.state.userToken,
          SearchResultType.USER_SEARCH,
          searchTerm,
          searchResultRank,
          result.content.purpose,
          GetAvailableLocalizations(
            result.content.languagePackage[algolia.state.languagePackageIdentifier]
          ),
          searchLanguage.SelectedLanguage
        )
        const previewData = {
          previewId: item.hwid,
          previewTitle: item.title,
          previewLanguageKey: DEFAULT_LOCALIZATION.key,
          previewType: item.type,
          show: true,
          previewActions: [
            PreviewUserActions.CART,
            PreviewUserActions.EDIT,
            PreviewUserActions.FAVORITES,
          ],
          cartItem: item,
          editMode: false,
        }
        sendSearchEvent(InsightEventNames.preview, item.algoliaInsightsEventData)
        previewDispatch({ type: PreviewActionEnum.SET_PREVIEW, data: previewData })
        popoverDispatch({
          type: PopoverActionEnum.SET_POPOVER,
          data: { type: PopoverType.PREVIEW },
        })

        if (setActiveSectionId && activeHitId !== activeSectionId) {
          setActiveSectionId(activeHitId)
          setFocus(false)
        }

        handleShowSearchResult(true)
      }
    }
  }

  const showSearchResults = popperAnchorEl != null && hasFocus && currentRefinement.length > 0

  return (
    <div role='search' className={classNames('customAutocomplete', className)} id={wrapperId}>
      <FormControl className='searchForm'>
        <TextField
          id={SEARCH_TEXT}
          placeholder={inverseColor ? undefined : SEARCH_TEXT}
          label={SEARCH_TEXT}
          size='small'
          autoComplete='off'
          variant={inverseColor ? 'filled' : 'outlined'}
          className={inverseColor ? filledClass.root : ''}
          inputRef={inputRef}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            handleOnChange(e)
          }
          onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => handleInputKeyUp(e)}
          onFocus={handleFocus}
          onSelect={handleFocus}
          value={currentRefinement}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <AdviseTooltip
                  text={currentRefinement.length < 1 ? SEARCH_TEXT : CLEAR_TEXT}
                  placement={'left'}
                >
                  <IconButton
                    data-testid='clear-search-button'
                    onClick={handleIconClick}
                    onFocus={handleFocus}
                    aria-label={currentRefinement.length < 1 ? SEARCH_TEXT : CLEAR_TEXT}
                  >
                    {currentRefinement.length < 1 ? <SearchIcon /> : <CloseIcon />}
                  </IconButton>
                </AdviseTooltip>
              </InputAdornment>
            ),
          }}
        />
        {showSearchResults ? (
          <div
            role='tooltip'
            id={AUTOCOMPLETE_ID}
            className={classNames('autocompleteListPopper', {
              'autocompleteListPopper-open': showSearchResults,
            })}
          >
            <Paper id={wrapperId + 'Paper'} className='autocompleteListPaper'>
              <Index indexName={algolia.state.suggestions}>
                <Configure
                  hitsPerPage={5}
                  filters=''
                  distinct={true}
                  facetingAfterDistinct={true}
                  analytics={true}
                  clickAnalytics={true}
                  enablePersonalization={true}
                  analyticsTags={analyticsTags()}
                />
                <AutocompleteResultsList
                  algolia={algolia}
                  handleKey={(e: any) => handleItemKey(e)}
                  results={normalizedHits}
                  suggestions={suggestionHits}
                  selectedListItemIndex={selectedListItemIndex}
                  query={currentRefinement}
                  refineSearch={refineSearch}
                  handleListItemClick={handleListItemClick}
                  handleShowSearchResult={handleShowSearchResult}
                />
              </Index>
            </Paper>
          </div>
        ) : null}
      </FormControl>
    </div>
  )
}

export const CustomAutocomplete = connectAutoComplete<Props, AlgoliaHit>(CustomSearchBarImpl)
