import { useState } from 'react'

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  useState(() => {
    const listener = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  })

  return {
    width,
  }
}
