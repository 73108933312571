import { ContextHydrator } from 'ContextHydrator'
import { useEffect, useState } from 'react'
import { AppContextWrapper } from 'services/context/AppContextWrapper'
import { FulfillmentSASTokenService } from 'services/FulfillmentSASTokenService'
import { getCurrentTimestamp } from 'services/utility/common'

function App() {
  const [timeStamp, setTimeStamp] = useState<number>(0)
  useEffect(() => {
    setTimeStamp(getCurrentTimestamp())
  }, [])

  FulfillmentSASTokenService.init()

  return (
    <AppContextWrapper>
      <ContextHydrator initialTimeStamp={timeStamp} />
    </AppContextWrapper>
  )
}

export default App
