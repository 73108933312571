import { FC } from 'react'

import './Error.scss'

type Props = {
  message: string
}
export const Error: FC<Props> = ({ message }) => {
  return (
    <div>
      <div className='error-nav'>
        <img
          className='error-nav-logo'
          src='healthwise_Advise_R.svg'
          alt='Healthwise Advise® Logo'
        />
      </div>
      <div className='error-body'>
        <div className='error-title'>Sorry. We're experiencing technical difficulties.</div>
        <div className='error-message'>{message}</div>
        <div className='error-sub-message'>
          We are working to resolve this issue as soon as possible.
        </div>
      </div>
    </div>
  )
}
