import classNames from 'classnames'
import React, { FC, MouseEventHandler } from 'react'
import styles from './ToggleSwitch.module.scss'

interface Props {
  forId: string
  text?: string
  describedById?: string
  ariaLabel?: string
  onChange?: MouseEventHandler<HTMLButtonElement> | undefined
  isChecked?: boolean
  disabled?: boolean
  redactText?: boolean
}

export const ToggleSwitch: FC<Props> = ({
  forId,
  describedById,
  text,
  isChecked,
  onChange,
  disabled,
  redactText,
  ariaLabel,
}) => {
  function renderLabelElement() {
    return (
      <label
        htmlFor={`${forId}-toggle`}
        aria-hidden
        className={classNames(`toggle-switch-label`, styles.toggle_switch_text, {
          [styles.active_label]: isChecked,
        })}
      >
        {text}
      </label>
    )
  }

  return (
    <div className={classNames(`toggle__switch`, styles.toggle_switch_wrapper)}>
      <button
        id={`${forId}-toggle`}
        role='switch'
        aria-checked={isChecked}
        className={classNames(styles.toggle_switch, {
          [styles.toggle_switch_checked]: isChecked,
        })}
        aria-describedby={describedById}
        onClick={onChange && !disabled ? onChange : undefined}
        aria-label={ariaLabel ? `${text} ${ariaLabel}` : text}
        disabled={disabled}
      >
        <span aria-hidden className={styles.toggle_switch_slider} />
      </button>
      {renderLabelElement()}
    </div>
  )
}
