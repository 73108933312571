import { uniq } from 'lodash'
import { Localization } from 'types/Localization'
import { EditableContent, EMPTY_EDITABLE_CONTENT } from 'types/Preview'
import { AlgoliaHit, Purpose } from '../../types/AlgoliaHit'
import { AlgoliaInsightEventData } from '../../types/AlgoliaInsightEventData'
import { CartItem, FileData } from '../../types/Cart'
import { LaunchContext, Practitioner } from '../../types/LaunchContextTypes'
import { CodeGroup, SearchResultType } from '../../types/Search'
import { capitalize, isEditableContentType } from './common'
import { DEFAULT_LOCALIZATION, GetLocalizationFromKey, IsDefaultLanguage } from './languages.util'
import { ContentPersist } from 'types/EventTypes'

export function ItemExistsInCart(cartItemId: string, cartItems: CartItem[]): boolean {
  return typeof cartItems.find(item => item.cartItemId === cartItemId) !== 'undefined'
}

export function getPatientSex(launchContext: LaunchContext): string {
  const sex = launchContext?.patient?.gender
  if (!sex) {
    return 'Unknown'
  } else if (sex === 'male' || sex === 'Male' || sex === 'female' || sex === 'Female') {
    return capitalize(sex)
  } else {
    return 'Other'
  }
}

export function CreateCartItem(
  hwid: string,
  title: string,
  description: string | null,
  html: string,
  type: string,
  docType: string,
  availableLocalizations: Localization[],
  localization: Localization,
  edited: boolean,
  editedContent: EditableContent,
  searchResultType: SearchResultType,
  searchString: string,
  searchResultRank: number,
  algoliaEventData: AlgoliaInsightEventData,
  purpose: Purpose[],
  inHouse: boolean,
  source: string,
  estimatedTimeToReadSeconds?: number
): CartItem {
  const cartItemId = CreateCartItemId(hwid, localization.key)
  return {
    cartItemId: cartItemId,
    content: html,
    hwid,
    type,
    docType,
    title,
    description,
    localization,
    availableLocalizations,
    edited,
    editedContent,
    searchResultType,
    searchString,
    searchResultRank,
    algoliaInsightsEventData: algoliaEventData,
    purpose,
    estimatedTimeToReadSeconds,
    inHouse,
    source,
  }
}

export function CartItemFromHit(
  hit: AlgoliaHit,
  index: string,
  userToken: string,
  searchResultType: SearchResultType,
  searchString: string,
  searchResultRank: number,
  purpose: Purpose[],
  availableLocalizations: Localization[],
  selectedLanguage?: Localization
): CartItem {
  const localization = selectedLanguage
    ? selectedLanguage
    : GetLocalizationFromKey(hit.content.localization)
  const cartItemId = CreateCartItemId(hit.content.hwid, localization.key)
  const { hwid, title, description, html, type, docType } = hit.content
  return {
    cartItemId: cartItemId,
    content: html,
    hwid,
    type,
    docType,
    title,
    description,
    localization,
    availableLocalizations,
    edited: false,
    editedContent: EMPTY_EDITABLE_CONTENT,
    searchResultType,
    searchString,
    searchResultRank,
    estimatedTimeToReadSeconds: GenerateEstimatedTimeToRead(hit),
    algoliaInsightsEventData: {
      userToken,
      index,
      objectIDs: [hit.objectID],
      positions: [hit.__position],
      queryId: hit.__queryID,
    },
    purpose,
    inHouse: hit.content.inHouse ?? false,
    source: hit.content.source,
  }
}

export function CreateCartItemId(hwid: string, localizationKey: string): string {
  return hwid + '_' + localizationKey
}

export function GetLocalizationsOfDuplicateCartItem(
  hwid: string,
  items: CartItem[]
): Localization[] {
  const localizations = items.filter(item => item.hwid === hwid).map(item => item.localization)
  return localizations
}

export function AddCartItem(items: CartItem[], item: CartItem): CartItem[] {
  return uniq([...items, item])
}

export function AddCartItems(originalItems: CartItem[], items: CartItem[]): CartItem[] {
  return uniq(originalItems.concat(items))
}

export function RemoveCartItem(items: CartItem[], item: CartItem): CartItem[] {
  return items.filter(cartItem => !(cartItem.cartItemId === item.cartItemId))
}

export function RemoveCartItems(items: CartItem[], itemsToRemove: CartItem[]): CartItem[] {
  // items is going to be what we return
  const copy = [...items]
  itemsToRemove.forEach(itemToRemove => {
    const index = copy.findIndex(item => itemToRemove.cartItemId === item.cartItemId)
    if (index !== -1) {
      // remove from items
      copy.splice(index, 1)
    }
  })

  return copy
}

export function UpdateCartItemData(items: CartItem[], itemToUpdate: CartItem): CartItem[] {
  const copy = [...items]
  const itemExists = copy.find(item => item.cartItemId === itemToUpdate.cartItemId)

  if (!itemExists) {
    return copy
  }

  const remainingItems = copy.filter(item => !(item.cartItemId === itemToUpdate.cartItemId))
  return [...remainingItems, itemToUpdate]
}

export function UpdateCartItemLanguage(
  items: CartItem[],
  itemId: string,
  localization: Localization
) {
  const copy = [...items]
  const itemExists = copy.find(item => item.cartItemId === itemId)

  if (!itemExists) {
    return copy
  }

  const remainingItems = items.filter(item => !(item.cartItemId === itemId))
  itemExists.localization = localization
  itemExists.cartItemId = CreateCartItemId(itemExists.hwid, localization.key)
  return [...remainingItems, itemExists]
}

export function getContextFromCodes(codeGroups: CodeGroup[]): LaunchContext {
  const codeGroup: CodeGroup = codeGroups.length > 0 ? codeGroups[0] : ({} as any)
  return codeGroup.searchRequest
}

export type HandleAssignmentParameters = {
  selectedContent: CartItem[]
  context: LaunchContext
  print: boolean
  createMultiplePDFs: boolean
  attachMultiplePDFs: boolean
  onAssignment: () => void
  notes?: string
  logoUrl?: string | null
  hwAccessToken?: string
  practitioner?: Practitioner
}

export function renderCartLanguageDropdown() {
  let header = document.getElementById('header-docflow')
  let list = document.querySelector<HTMLElement>('.list-expanded > .listbox-list')
  if (list != null && list.parentElement != null) {
    let listParent = list.parentElement
    let listParentHeight = listParent.offsetHeight
    let listParentPosition = listParent.getBoundingClientRect()
    let listTopPosition = Math.max(0, listParentPosition.top + listParentHeight)
    if (header != null) {
      listTopPosition = Math.min(listTopPosition, header.offsetHeight)
    }
    list.style.top = listTopPosition.toString() + 'px'
    list.style.left = listParentPosition.left.toString() + 'px'
  }
}

export function IsContentEditable(
  selectedLanguage: Localization,
  contentType: string,
  existsInCart: boolean,
  allowEdit: boolean,
  inHouseContent: boolean
): boolean {
  return (
    IsDefaultLanguage(selectedLanguage) &&
    existsInCart &&
    isEditableContentType(contentType) &&
    allowEdit &&
    !inHouseContent
  )
}

export function getPatientWritebackIdNumber(launchContext: LaunchContext): string {
  if (
    launchContext.patient?.medicalRecordNumber &&
    launchContext.patient?.medicalRecordNumberIdentifierType
  ) {
    return launchContext.patient?.medicalRecordNumber
  }

  return launchContext.patient?.id ?? ''
}

export function getPatientWritebackIdType(launchContext: LaunchContext): string {
  if (
    launchContext.patient?.medicalRecordNumber &&
    launchContext.patient?.medicalRecordNumberIdentifierType
  ) {
    return launchContext.patient?.medicalRecordNumberIdentifierType
  }

  return launchContext.ssoPatientIdentifier?.IDType
}

export function getFileInformation(
  pdfs: ContentPersist[],
  selectedContent: CartItem[]
): FileData[] {
  return selectedContent.map(content => {
    const selected = pdfs.find(
      pdf =>
        pdf.Type === 'Single' &&
        pdf.Hwid === content.hwid &&
        pdf.Localization === content.localization.key
    )
    return { fileUrl: selected?.Link as string, documentDescription: content.title }
  })
}

export function GenerateEstimatedTimeToRead(hit: AlgoliaHit): number | undefined {
  // Multimedia content will not have minutesToRead - Possbily other types as well
  if (!hit.content.minutesToRead) {
    return undefined
  }

  // Data is coming back from Algolia as a string in decimal minutes.  So 90 seconds would be
  // represented as "1.5"
  const minutesToReadStr = hit.content.minutesToRead
  const minutesFloat = parseFloat(minutesToReadStr)
  if (Number.isNaN(minutesFloat)) {
    return undefined
  }

  // Convert into whole seconds
  return Math.round(minutesFloat * 60)
}

export function allHitsExistInCart(hits: AlgoliaHit[], cartItems: CartItem[]) {
  if (hits.length <= 0 || cartItems.length <= 0) {
    return false
  }

  return hits.every(f =>
    ItemExistsInCart(CreateCartItemId(f.content.hwid, DEFAULT_LOCALIZATION.key), cartItems)
  )
}
