import { Patient } from 'types/Patient'
import { DiscoEntitlement } from './DiscoTypes'
import { FavoritesFolder } from './Favorites'

export interface LaunchCode {
  codes: SearchCode[]
  text: string
}
export interface Clinician {
  id: string
  medicalSpecialty?: string
  careSetting?: string
  name?: string
}

export interface EncounterClass {
  source: string
  status?: string
  codeSystem: string
  code: string
  display?: string
}

export interface MedicalSpecialty {
  codes: SearchCode[]
}

export interface SearchCode {
  code: string
  value: string
  source: string
  date: string
  codeSystem: string
  status: string
  display: string
}

export interface Location {
  id: string
  managingOrganization?: string
  name?: string
  medicalSpecialties: MedicalSpecialty[]
}

export const EMPTY_LOCATION: Location = {
  id: '',
  medicalSpecialties: [],
}
export interface Organization {
  id: string
  name?: string
}

export interface RedoxIdentifier {
  ID: string
  IDType: string
}

export interface Practitioner {
  id: string
  ehrUserId: string
  name?: string
  writebackId?: string
  writebackIdType?: string
  folders: FavoritesFolder[] | null
}

export interface Encounter {
  id: string
  visitNumber: string
  diagnoses: LaunchCode[]
  reasons: LaunchCode[]
  medications?: LaunchCode[]
  immunizations?: LaunchCode[]
  type: LaunchCode[]
  class: EncounterClass
  date: string
  careSetting?: CareSetting
  hospitalizationReadmission: LaunchCode | null
}

export enum CareSetting {
  Inpatient = 'Inpatient',
  Outpatient = 'Outpatient',
  Other = '',
}

export interface PractitionerRole {
  id: string
  specialties?: LaunchCode[]
  codes?: LaunchCode[]
  organizationId: string
}

export const EMPTY_PRACTITIONER_ROLE = {
  id: '',
  specialities: [],
  organizationId: '',
}

export interface Destination {
  ID: string
  Name: string
}

export interface ClientConfig {
  sendMultiplePDFs: boolean
  collapseAccordions: boolean
  allowEdit: boolean
  inHouseContent: boolean
  useDeliverySettingFilters: boolean
  skipWriteback: boolean
  unassign: boolean
}

export interface LaunchContext {
  clientConfig: ClientConfig
  patient: Patient
  location?: Location
  department?: Location
  organization?: Organization
  practitioner: Practitioner
  practitionerRole?: PractitionerRole
  encounter: Encounter
  ssoPatientIdentifier: RedoxIdentifier
  writebackDestinations: Destination[]
  contextSessionId: string
  hwOrganization: HwEntity
  hwSubscription: HwEntity
  hwAccessToken: string
  isRedoxTestEnvironment?: boolean
  error?: boolean
  entitlement: DiscoEntitlement
  ehrUserName?: string
}

export interface InitializePractitionerResponse {
  practitionerId: string
  folders: FavoritesFolder[]
  error?: boolean
}

export interface HwEntity {
  id: string
  name: string
}
