import Button from 'components/Button/Button'
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { patientHistoryQueryWithPagination } from 'services/apis/assignment.api'
import { useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { attributeLabels, FilterHandlerType } from 'services/filters/filter.config'
import {
  calculatePatientAge,
  capitalize,
  getAge,
  getTimestampMonthAbbr,
} from 'services/utility/common'
import {
  FilterableGenders,
  Patient,
  PatientHistory,
  PatientHistoryQueryResponse,
} from 'types/Patient'
import { DiscoComparator, DiscoFacet, DiscoJoiner } from 'types/DiscoTypes'

import './PatientSettings.scss'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { CareSettingRefinementList } from 'components/Filters/CareSetting/CareSettingRefinementList'

interface Props {
  patient: Patient
  filterHandler: FilterHandlerType
  launchFilterHandler: FilterHandlerType
  optionalFilterHandler: FilterHandlerType
  close: () => void
  hideCartOnLoad: () => void
}
const VIEW_EDUCATION_HISTORY_TEXT = 'View Education History'

export const PatientSettings: FC<Props> = ({
  patient,
  filterHandler,
  launchFilterHandler,
  optionalFilterHandler,
  hideCartOnLoad,
  close,
}) => {
  const [patientSex, setPatientSex] = useState('')
  const [patientAge, setPatientAge] = useState('0')
  const [patientName, setPatientName] = useState('Patient Name')
  const [patientHistory, setPatientHistory] = useState<PatientHistory[]>([])
  const { state: eventPayloadState } = useEventPayloadContext()
  const { state: clientConfigurations } = useClientConfigurations()
  const { push } = useHistory()

  const ageChecked = filterHandler.isActive(
    attributeLabels.rightAge,
    patient.age === '0' ? '1' : patient.age
  )
  const gender = patient.gender ?? ''
  const normalizedPatientSex = capitalize(gender)
  const sexChecked = filterHandler.isActive(attributeLabels.sex, normalizedPatientSex)
  const maxRecentHistoryItems = 2

  useEffect(() => {
    const fetchHistory = async () => {
      const pageSize = 2
      const page = 1
      let res = await patientHistoryQueryWithPagination(
        patient.id,
        page,
        pageSize,
        clientConfigurations['hwAccessToken']
      )

      if (res.ok) {
        const json: PatientHistoryQueryResponse = await res.json()
        setPatientHistory(json.results)
      }
    }

    fetchHistory()
  }, [patient.id, setPatientHistory, clientConfigurations])

  useEffect(() => {
    setPatientSex(normalizedPatientSex)

    let patAge = calculatePatientAge(patient?.dateOfBirth) || '0'
    if (patient?.dateOfBirth) {
      patAge = getAge(patient.dateOfBirth)
    }

    setPatientAge(patAge)

    if (patient?.firstName) {
      let patientName = patient.firstName
      if (patient?.lastName) {
        patientName += ` ${patient.lastName}`
      }
      setPatientName(patientName)
    }
  }, [patient, normalizedPatientSex])

  const handleClick = () => {
    hideCartOnLoad()
    close()
    push('/educationHistory')
    import('services/utility/events/bee/event.util').then(event => {
      event.sendHistoryViewedEvent(eventPayloadState)
    })
  }

  return (
    <>
      {patient ? (
        <div className='patient-settings-popup'>
          <h2 className='offscreen-text'>Patient Education History</h2>
          <h3 className='patient-setting-name'>
            <span className='offscreen-text'>Patient Name </span>
            {patientName}
          </h3>
          {gender || patient.age ? (
            <>
              <h3>Search Filters</h3>
              <div>
                {FilterableGenders.includes(gender.toLowerCase()) ? (
                  <AdviseTooltip text='Assigned Sex' placement='right'>
                    <div className='patient-setting-switch'>
                      <ToggleSwitch
                        forId={'patient-settings-sex-switch'}
                        isChecked={sexChecked}
                        redactText
                        onChange={() => {
                          if (sexChecked) {
                            filterHandler.reset(attributeLabels.sex)
                          } else {
                            const discoFacet: DiscoFacet = {
                              attributes: [attributeLabels.sex],
                              values: [patientSex],
                              joiner: DiscoJoiner.OR,
                            }
                            filterHandler.add(discoFacet)
                          }
                        }}
                        ariaLabel='Sex'
                        text={patientSex}
                      />
                    </div>
                  </AdviseTooltip>
                ) : null}
              </div>
              <div>
                {patient.age ? (
                  <AdviseTooltip text='Age' placement='right'>
                    <div className='patient-setting-switch'>
                      <ToggleSwitch
                        forId={'patient-settings-age-switch'}
                        isChecked={ageChecked}
                        redactText
                        onChange={() => {
                          if (ageChecked) {
                            filterHandler.reset(attributeLabels.leftAge)
                            filterHandler.reset(attributeLabels.rightAge)
                          } else if (patient.age) {
                            const discoFacetMaxAge: DiscoFacet = {
                              attributes: [attributeLabels.leftAge],
                              values: [patient.age],
                              joiner: DiscoJoiner.OR,
                              comparator: DiscoComparator.GreaterThan,
                            }
                            const discoFacetMinAge: DiscoFacet = {
                              attributes: [attributeLabels.rightAge],
                              values: [patient.age],
                              joiner: DiscoJoiner.OR,
                              comparator: DiscoComparator.LessThan,
                            }
                            filterHandler.add(discoFacetMaxAge)
                            filterHandler.add(discoFacetMinAge)
                          }
                        }}
                        ariaLabel='Age'
                        text={patientAge}
                      />
                    </div>
                  </AdviseTooltip>
                ) : null}
              </div>
              {clientConfigurations['useDeliverySettingFilters'] ? (
                <CareSettingRefinementList
                  filterHandler={filterHandler}
                  launchFilterHandler={launchFilterHandler}
                  optionalFilterHandler={optionalFilterHandler}
                />
              ) : (
                <></>
              )}
            </>
          ) : null}
          <h3>Recent Education History</h3>
          {patientHistory.length > 0 ? (
            <>
              <div className='education-list-wrapper'>
                <ul className='education-list-outer'>
                  {patientHistory.map((info, ix) => {
                    if (ix < maxRecentHistoryItems) {
                      return (
                        <li key={`patient-history-${ix}`}>
                          <div className='education-list-date'>
                            {getTimestampMonthAbbr(info?.assignmentDate)}
                          </div>
                          <ul className='education-list-inner'>
                            {info?.content?.map((education, ex) => {
                              return <li key={`education-${ix}-${ex}`}>{education?.title}</li>
                            })}
                          </ul>
                        </li>
                      )
                    }
                    return <></>
                  })}
                </ul>
                <div className='fade-out-gradient' aria-hidden role='presentation'></div>
              </div>

              <div className='view-full-education-button-wrapper'>
                <Button
                  rounded
                  small
                  className='view-full-education-button'
                  onClick={handleClick}
                  aria-label={VIEW_EDUCATION_HISTORY_TEXT}
                >
                  {VIEW_EDUCATION_HISTORY_TEXT}
                </Button>
              </div>
            </>
          ) : (
            <p className='no-education-history'>No history available</p>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
