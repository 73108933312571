export const PRINT_SELECTED_CONTENT_TEXT = 'Print selected content'
export const ADD_TO_AVS_TEXT = 'Add to AVS'
export const KEEP_EDITED_CONTENT_TEXT = 'Keep Edited Content'
export const REMOVE_TEXT = 'Remove'
export const NO_ENCOUNTER_TEXT = ' - No Encounter Available'
export const UNABLE_TO_PRINT = 'Unable to Print'
export const UNABLE_TO_ADD_TO_AVS = 'Unable to Add to AVS'
export const FAILED_TO_WRITE_CONTENT = 'Failed to write content to Epic.'
export const PLEASE_SELECT_CONTENT = 'Please select content and then'
export const ADD_TO_BASKET_TEXT = 'has been added to basket.'
export const REMOVE_FROM_BASKET_TEXT = 'has been removed from basket.'
