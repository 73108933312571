import { FC, useState, useRef, useEffect } from 'react'
import { Localization } from 'types/Localization'
import { ContentTypes } from 'types/HwContentTypes'
import { CreateCartItem, CreateCartItemId, IsContentEditable } from 'services/utility/cart.util'
import { LanguageSelect } from 'components/LanguageSelect/LanguageSelect'
import { InHouseContent } from 'components/Icons/Icons'
import CreateIconRounded from '@material-ui/icons/Create'
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  IconButton,
  Typography,
  Icon,
  Popover,
  Chip,
} from '@material-ui/core'
import {
  CheckRounded,
  DescriptionOutlined,
  StarOutlineRounded,
  StarRounded,
} from '@material-ui/icons'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

import { uuid } from 'services/utility/uuid.util'
import { usePatient } from 'services/context/PatientContext'
import { DEFAULT_LOCALIZATION } from 'services/utility/languages.util'
import { PreviewActionEnum, usePreview } from 'services/context/PreviewContentCtx'
import { EditableContent, EMPTY_EDITABLE_CONTENT } from 'types/Preview'
import { ListType } from 'types/ListTypes'
import { AlgoliaInsightEventData } from 'types/AlgoliaInsightEventData'
import { InsightEventNames, sendSearchEvent } from 'services/utility/algoliainsight.util'
import { ManageFavorites } from 'views/favorites/ManageFavorites'
import { PatientHistory, PreviouslyAssignedContent } from 'types/Patient'
import { SearchResultType } from 'types/Search'
import { EditActionEnum, useEdit } from 'services/context/EditContext'
import { PopoverActionEnum, usePopover } from 'services/context/PopoverContext'
import { PopoverType } from 'types/Popover'
import { actionKeys, getTimestampMonthAbbr } from 'services/utility/common'
import { Purpose } from 'types/AlgoliaHit'
import { PurposeMetadataChips } from 'components/PurposeMetadataChips/PurposeMetadataChips'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import { useFeatures } from 'services/context/FeatureContext'
import { fetchContent } from 'services/apis/content.api'
import { useQueryClient } from '@tanstack/react-query'
import { appInsights } from 'services/AppInsights'
import { LanguageBadge } from 'components/shared/Badges/LanguageBadge'

import './CollapsibleListItemStyles.scss'
import { useInView } from 'react-intersection-observer'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import {
  previewInHouseContentPDF,
  setItemToPreview,
} from 'services/utility/collapsibleListItem.util'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'
import { SnackBarActionEnum, useSnackBar } from 'services/context/SnackbarContext'

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  'data-testid'?: string
}

type Props = {
  hwid: string
  listType: ListType
  type: string
  docType: string
  title: string
  description: string | null
  availableLocalizations: Localization[]
  disabledLocalizations?: Localization[]
  selectedLanguage: Localization
  showLanguageSelect: boolean
  existsInCart: boolean
  isFavorite: boolean
  previouslyAssigned?: PatientHistory
  previouslyAssignedContent?: PreviouslyAssignedContent
  handleCheck: (newChecked: boolean, hwid: string, languageKey: string) => any
  handleLanguageChange?: (key: string, id?: string) => void
  algoliaInsightEventData: AlgoliaInsightEventData
  searchTerm: string
  searchResultRank: number
  isEdited: boolean
  editedContent: EditableContent
  purpose: Purpose[]
  inHouse: boolean
  source: string
}
const EDIT_TEXT = 'Edit'
const VIDEO_TEXT = 'Video'
const ARTICLE_TEXT = 'Article'
const INHOUSE_TEXT = 'In-house'

export const CollapsibleListItem: FC<Props> = ({
  listType,
  type,
  docType,
  title,
  description,
  availableLocalizations,
  disabledLocalizations,
  selectedLanguage,
  showLanguageSelect,
  existsInCart,
  isFavorite,
  previouslyAssignedContent,
  handleCheck,
  handleLanguageChange,
  algoliaInsightEventData,
  searchTerm,
  searchResultRank,
  isEdited,
  editedContent,
  purpose,
  inHouse,
  source,
  hwid,
}) => {
  const [titleId] = useState<string>(uuid())
  const listItemRef = useRef(document.createElement('div'))
  const favoritesButtonRef = useRef(null)
  const { state: patient } = usePatient()
  const { dispatch: previewDispatch } = usePreview()
  const { dispatch: editDispatch } = useEdit()
  const { dispatch: popoverDispatch } = usePopover()
  const { state: clientConfigurations } = useClientConfigurations()
  const [favoritesAnchorElement, setFavoritesAnchorElement] = useState<null | HTMLElement>(null)
  const showManageFavorites = Boolean(favoritesAnchorElement)
  const secondaryText = description ? description.substring(0, 100) + '...' : null
  const checkboxTooltip = existsInCart ? 'Remove Item from Basket' : 'Add Item to Basket'
  const favoriteText = isFavorite ? 'Favorited' : 'Add to Favorites'
  const { state: features } = useFeatures()
  const queryClient = useQueryClient()

  const { dispatch: snackDispatch } = useSnackBar()

  const { ref, inView } = useInView()
  const [isContentEditable, setIsContentEditable] = useState(
    IsContentEditable(
      selectedLanguage,
      type,
      listType === ListType.CART,
      clientConfigurations['allowEdit'],
      inHouse
    )
  )

  const searchResultType =
    listType === ListType.SEARCH ? SearchResultType.USER_SEARCH : SearchResultType.LAUNCH

  const previewLanguageKey =
    listType === ListType.CART ? selectedLanguage.key : DEFAULT_LOCALIZATION.key

  useEffect(() => {
    const prefetchContent = async () => {
      await queryClient.prefetchQuery({
        // other options are set in the queryclient init
        // in the context wrapper
        queryKey: ['content', `${hwid}-${previewLanguageKey}`],
        queryFn: async () => {
          var res = await fetchContent(
            hwid,
            inHouse,
            clientConfigurations['hwAccessToken'],
            previewLanguageKey,
            clientConfigurations['customLogoUri']
          )

          return res
        },
        retry: 5,
      })
    }
    if (inView && !inHouse) {
      // basically only go run the query once the inView ref is true
      // uses intersectionObserver under the hood

      prefetchContent()
    }
  }, [inView, hwid, clientConfigurations, previewLanguageKey, queryClient, features, inHouse])

  useEffect(() => {
    setIsContentEditable(
      IsContentEditable(
        selectedLanguage,
        type,
        listType === ListType.CART,
        clientConfigurations['allowEdit'],
        inHouse
      )
    )
  }, [selectedLanguage, type, listType, clientConfigurations, inHouse])

  const handleEditButtonClick = () => {
    const cartItem = CreateCartItem(
      hwid,
      title,
      description,
      '',
      type,
      docType,
      availableLocalizations,
      selectedLanguage,
      isEdited,
      editedContent,
      searchResultType,
      searchTerm,
      searchResultRank,
      algoliaInsightEventData,
      purpose,
      inHouse,
      source
    )
    editDispatch({ type: EditActionEnum.SET_EDIT, data: cartItem })
    popoverDispatch({ type: PopoverActionEnum.SET_POPOVER, data: { type: PopoverType.EDIT } })
  }

  const handlePreviewButtonClick = async () => {
    try {
      var res = await queryClient.fetchQuery(['content', `${hwid}-${previewLanguageKey}`], {
        queryFn: () =>
          fetchContent(
            hwid,
            inHouse,
            clientConfigurations['hwAccessToken'],
            previewLanguageKey,
            clientConfigurations['customLogoUri']
          ),
      })
      if (inHouse) {
        previewInHouseContentPDF(res)
      } else {
        const preview = setItemToPreview(
          hwid,
          title,
          description,
          '',
          type,
          docType,
          availableLocalizations,
          selectedLanguage,
          isEdited,
          isEdited ? editedContent : EMPTY_EDITABLE_CONTENT,
          searchResultType,
          searchTerm,
          searchResultRank,
          algoliaInsightEventData,
          purpose,
          inHouse,
          source,
          previewLanguageKey,
          res
        )

        previewDispatch({ type: PreviewActionEnum.SET_PREVIEW, data: preview })
        popoverDispatch({
          type: PopoverActionEnum.SET_POPOVER,
          data: { type: PopoverType.PREVIEW },
        })
      }
    } catch (e: any) {
      const snack = {
        show: true,
        message: `Unable to access ${inHouse ? 'in-house ' : ''}content`,
        backgroundColor: SnackbarBackgroundColor.ERROR,
        textColor: SnackbarTextColor.WHITE,
        duration: 5000,
        key: uuid(),
      }
      snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
    }

    sendSearchEvent(InsightEventNames.preview, algoliaInsightEventData)
  }

  const toggleFavoritesModal = () => {
    if (favoritesButtonRef.current) {
      appInsights.startTrackEvent('Favorites::Dialog Open')
      setFavoritesAnchorElement(favoritesButtonRef.current)
    }
  }

  const closeFavoritesModal = () => {
    setFavoritesAnchorElement(null)
  }
  const stopListItemPreviewFromPropagating = (e: any) => {
    e.stopPropagation()
  }
  const handleListItemKeyDown = (event: React.KeyboardEvent<HTMLHeadingElement>) => {
    if (event.keyCode === actionKeys.Enter) {
      handlePreviewButtonClick()
    }
  }

  function getDocIconFromType(type: string): JSX.Element {
    let element = <></>
    switch (type) {
      case ContentTypes.VIDEO:
        element = (
          <AdviseTooltip text={VIDEO_TEXT} placement={existsInCart ? 'left' : 'top'}>
            <OndemandVideoIcon color='disabled' data-testid='video-icon' />
          </AdviseTooltip>
        )
        break
      default:
        element = (
          <AdviseTooltip text={ARTICLE_TEXT} placement={existsInCart ? 'left' : 'top'}>
            <DescriptionOutlined
              color='disabled'
              data-testid={`article${inHouse ? '-inhouse' : ''}`}
            />
          </AdviseTooltip>
        )
        break
    }
    return <div className='list-item-type-element'>{element}</div>
  }

  const renderLanguageSelect =
    availableLocalizations && showLanguageSelect && handleLanguageChange ? (
      <LanguageSelect
        className='list-item-language-selector'
        id={CreateCartItemId(hwid, selectedLanguage.key)}
        selectedLanguage={selectedLanguage}
        availableLanguages={availableLocalizations}
        disabledLanguages={disabledLocalizations ? disabledLocalizations : []}
        handleLanguageChange={handleLanguageChange}
        languageSelectElementId={'ContentLanguageSelector'}
        disabled={isEdited || availableLocalizations.length <= 1}
      />
    ) : (
      <></>
    )

  const contentExistsInPatientPreferredLanguage = availableLocalizations.find(
    loc => loc.key === patient.preferredLocalization.key
  )

  function renderPPL() {
    return listType === ListType.LAUNCH && !contentExistsInPatientPreferredLanguage ? (
      <LanguageBadge languageKey={DEFAULT_LOCALIZATION.key} />
    ) : (
      <></>
    )
  }

  const renderPreviouslyAssignedContent = previouslyAssignedContent ? (
    <span className='content-assigned-indicator'>
      <AdviseTooltip
        text={`${getTimestampMonthAbbr(previouslyAssignedContent.assignmentDate)} Provided`}
        placement={'top'}
      >
        <Icon>
          <CheckRounded />
        </Icon>
      </AdviseTooltip>
    </span>
  ) : (
    <></>
  )

  const showFavoritesIcon = !isEdited && listType !== ListType.CART

  const renderPurposeMetaData =
    purpose.length > 0 ? <PurposeMetadataChips purposes={purpose} /> : null

  const renderInHouseIcon = inHouse ? (
    <AdviseTooltip text={INHOUSE_TEXT} placement={existsInCart ? 'left' : 'top'}>
      <InHouseContent color={'dark'} />
    </AdviseTooltip>
  ) : (
    <></>
  )

  const renderEditableIconOrDocType = isContentEditable ? (
    <AdviseTooltip text={EDIT_TEXT} placement={existsInCart ? 'left' : 'top'}>
      <IconButton
        onClick={(e: any) => {
          stopListItemPreviewFromPropagating(e)
          handleEditButtonClick()
        }}
        className='list-item-edit-btn'
        aria-label={EDIT_TEXT}
        data-testid='list-item-edit-btn'
      >
        <CreateIconRounded color='primary' />
      </IconButton>
    </AdviseTooltip>
  ) : (
    <div className='list-item-control-icon'>{getDocIconFromType(type)}</div>
  )

  const renderEditedChip =
    isEdited && existsInCart ? (
      <Chip label='Edited' size='small' className='content-edited-chip' />
    ) : (
      <></>
    )
  const renderSecondaryText =
    (listType === ListType.LAUNCH || listType === ListType.SEARCH) &&
    type !== ContentTypes.ANATOMY ? (
      <div className='list-item-secondary' data-testid='secondary-text'>
        <Typography
          component='p'
          color='textSecondary'
          variant='subtitle2'
          className='list-item-secondary-text'
        >
          {renderPPL()}
          {secondaryText}
        </Typography>
      </div>
    ) : null

  const renderMetadataChips =
    listType === ListType.LAUNCH || listType === ListType.SEARCH ? (
      <div className='list-item-purpose-meta' data-testid='purpose-meta'>
        {renderPurposeMetaData}
      </div>
    ) : null

  return (
    <div
      data-testid='collapsible-list-item-root'
      className='list-item-root'
      tabIndex={0}
      onClick={handlePreviewButtonClick}
      onKeyDown={handleListItemKeyDown}
      ref={ref}
    >
      {features['enableDebugMode'] ? `${type} ${hwid} ${previewLanguageKey}` : null}
      <ListItem
        className={`list-item list-item-${listType.toString()}`}
        key={hwid}
        ContainerComponent='div'
        data-testid={`collapsible-list-item-${
          type === ContentTypes.VIDEO ? 'video' : inHouse ? 'inHouse' : 'article'
        }`}
      >
        <div className='list-item-control'>
          <ListItemIcon className='list-item-checkbox-wrapper'>
            <AdviseTooltip text={checkboxTooltip} placement={'right'}>
              <Checkbox
                color='primary'
                checked={existsInCart}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleCheck(e.target.checked, hwid, selectedLanguage.key)
                }}
                onClick={(e: any) => stopListItemPreviewFromPropagating(e)}
                inputProps={
                  {
                    'data-testid': 'list-item-checkbox',
                    'aria-label': `${checkboxTooltip} ${title}`,
                  } as InputProps
                }
                aria-checked={existsInCart}
              />
            </AdviseTooltip>
          </ListItemIcon>
          {showFavoritesIcon ? (
            <ListItemIcon
              data-testid='list-item-favorites-icon'
              className='list-item-favorites-wrapper'
            >
              <AdviseTooltip text={favoriteText} placement={existsInCart ? 'bottom' : 'top'}>
                <IconButton
                  ref={favoritesButtonRef}
                  aria-label={favoriteText}
                  className='show-favorites-button'
                  data-testid='show-manage-favorites'
                  size='medium'
                  onClick={(e: any) => {
                    stopListItemPreviewFromPropagating(e)
                    toggleFavoritesModal()
                  }}
                >
                  {isFavorite ? (
                    <StarRounded data-testid='favorite-selected-icon' />
                  ) : (
                    <StarOutlineRounded data-testid='favorite-unselected-icon' />
                  )}
                </IconButton>
              </AdviseTooltip>

              <Popover
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                open={showManageFavorites}
                anchorEl={favoritesAnchorElement}
                onClose={(e: any) => {
                  stopListItemPreviewFromPropagating(e)
                  closeFavoritesModal()
                }}
                data-testid={'manage-favorites-popper'}
              >
                <ManageFavorites
                  algoliaInsightEventData={algoliaInsightEventData}
                  hwid={hwid}
                  isFavorite={isFavorite}
                  close={closeFavoritesModal}
                />
              </Popover>
            </ListItemIcon>
          ) : (
            <></>
          )}
          {renderLanguageSelect}
        </div>

        <div className='list-item-content'>
          <div className='content-title'>
            <Typography
              id={titleId}
              component='h3'
              variant='subtitle1'
              ref={listItemRef}
              className='list-item-content-subtitle'
            >
              {renderPreviouslyAssignedContent}
              <span className='list-item-content-title'>{title}</span>
            </Typography>
            {renderEditedChip}
          </div>
          <div className='content-sub'> {renderSecondaryText}</div>
          <div className='content-meta'> {renderMetadataChips}</div>
        </div>

        <div className='list-item-control'>
          {renderInHouseIcon}
          {renderEditableIconOrDocType}
        </div>
      </ListItem>
    </div>
  )
}
