import { FC } from 'react'
import { Localizations } from 'services/utility/languages.util'
import './LanguageBadge.scss'

interface LanguageProps {
  languageKey: string
}

export const LanguageBadge: FC<LanguageProps> = ({ languageKey }) => {
  const baseClass = 'language-badge'
  const abbr = Localizations.find(l => l.key === languageKey)?.abbreviation
  const languageFullName = Localizations.find(l => l.key === languageKey)?.name
  return abbr ? (
    <span
      tabIndex={0}
      className={baseClass}
      role='treeitem'
      aria-selected='false'
      aria-label={languageFullName}
    >
      <span className='badge-text' aria-hidden>
        {abbr}
      </span>
    </span>
  ) : (
    <></>
  )
}
