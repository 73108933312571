import React from 'react'
import { CircularProgress } from '@material-ui/core'
import './LoadingSpinner.scss'

export const LoadingSpinner: React.FC<{ msg: string }> = ({ msg }) => {
  return (
    <div id='LoadingSpinner' data-testid='loading-spinner'>
      <CircularProgress color='primary' className='loader' />
      <h2 color='primary'>{msg}</h2>
    </div>
  )
}
