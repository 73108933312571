import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import './RefinementToggle.scss'

interface RefinementToggleProps {
  toggleActive: () => void
  active: boolean
  children: ReactNode
  label: string
  toolTipPlacement: string
  pos?: string
}

export const RefinementToggle: React.FC<RefinementToggleProps> = ({
  toggleActive,
  active,
  label,
  children,
  toolTipPlacement,
  pos,
}) => {
  const position = pos ? pos : ''
  const classnames = classNames('refinement-toggle', active ? 'active' : '', position)
  return (
    <AdviseTooltip text={label} placement={toolTipPlacement}>
      <button
        data-testid='refinement-toggle'
        onClick={toggleActive}
        className={classnames}
        aria-label={label}
        aria-current={active}
      >
        {children}
      </button>
    </AdviseTooltip>
  )
}
