import { FC, useEffect, useState } from 'react'
import { fetchPreviouslyAssigned } from 'services/apis/assignment.api'
import { EventPayloadActionEnum, useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { PatientActionEnum, usePatient } from 'services/context/PatientContext'
import { LanguageActionTypes, useSearchLanguage } from 'services/context/SearchLanguageContext'
import { attributeLabels, filterManagerIds } from 'services/filters/filter.config'
import {
  createEventPayloadContextFromLaunch,
  sendClinicalLaunchEvent,
} from 'services/utility/events/bee/event.util'
import { DiscoComparator, DiscoFacet, DiscoJoiner } from 'types/DiscoTypes'
import { getFeatures } from './services/apis/features.api'
import { Layout } from 'Layout'
import { FeatureActionEnum, useFeatures } from 'services/context/FeatureContext'
import { transitionsDisabledTheme } from './theme'
import './theme-overrides.scss'
import 'fontsource-roboto'
import '@fontsource/roboto-slab'
import { ThemeProvider } from '@material-ui/styles'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { useLaunch } from 'services/hooks/useLaunch/useLaunch'
import { FhirResourceQuery, FhirResourceTypes, FhirResourceQueryStatus } from 'types/Fhir'
import { getQueryParams } from 'services/utility/launch.util'
import { fetchFhir } from 'services/apis/launch.api'
import { EMPTY_LOCATION, EMPTY_PRACTITIONER_ROLE } from 'types/LaunchContextTypes'
import { isEmpty } from 'lodash'
import { CareSetting } from 'types/LaunchContextTypes'
import { useQueryClient } from '@tanstack/react-query'
import { trackErrorToAppInsights } from 'services/utility/error.util'
import { FilterableGenders } from 'types/Patient'
import useFilterManager from 'services/hooks/useFilterManager'

type Props = {
  initialTimeStamp: number
}

// UI Language for content coming from Algolia
const DEFAULT_CONTENT_LANGUAGE = 'en-us'
export const TEXT_SEARCH_SECTION = 'TEXT_SEARCH'
const { cacheId } = getQueryParams(window.location.search)

export const ContextHydrator: FC<Props> = ({ initialTimeStamp }) => {
  const queryClient = useQueryClient()
  const { isLoading: launchLoading, error: launchError, algolia, launch: launchData } = useLaunch() // needed for algolia to connect up as well
  const { state: searchLanguage, dispatch: searchLanguageDispatch } = useSearchLanguage()
  const { state: features, dispatch: FeatureDispatch } = useFeatures()
  const { state: clientConfigurations } = useClientConfigurations()
  const { state: patient, dispatch: patientDispatch } = usePatient()
  const { state: eventPayloadState, dispatch: eventPayloadDispatch } = useEventPayloadContext()
  const { filterProperties: globalFilterProperties, filterHandler: globalFilterHandler } =
    useFilterManager(filterManagerIds.global)
  const { filterProperties: launchFilterProperties, filterHandler: launchFilterHandler } =
    useFilterManager(filterManagerIds.launch)
  const { filterProperties: optionalFilterProperties, filterHandler: optionalFilterHandler } =
    useFilterManager(filterManagerIds.optional)
  const { filterHandler: favoritesFilterHandler } = useFilterManager(filterManagerIds.favorites)
  const [genderFilterApplied, setGenderFilterApplied] = useState(false)
  const [ageFilterApplied, setAgeFilterApplied] = useState(false)
  const [patientId, setPatientId] = useState('')
  const [packageId, setPackageId] = useState('')
  const [encounterId, setEncounterId] = useState('')
  const [launchEventSent, setLaunchEventSent] = useState<boolean>(false)
  const [sendLaunchEvent, setSendLaunchEvent] = useState<boolean>(false)
  const [queryRemainingFhirResources, setQueryRemainingFhirResources] = useState<boolean>(false)
  const [featuresFetched, setFeaturesFetched] = useState<boolean>(false)

  const AUTH_TOKEN = clientConfigurations['hwAccessToken']

  useEffect(() => {
    const send: boolean =
      !launchEventSent &&
      eventPayloadState !== null &&
      !launchLoading &&
      !isEmpty(eventPayloadState.location) &&
      !isEmpty(eventPayloadState.practitionerRole) &&
      searchLanguage.InitialLanguage !== null &&
      initialTimeStamp !== 0
    setSendLaunchEvent(send)
  }, [
    launchEventSent,
    eventPayloadState,
    launchLoading,
    searchLanguage.InitialLanguage,
    initialTimeStamp,
  ])

  useEffect(() => {
    const send: boolean =
      !launchLoading &&
      !isEmpty(cacheId) &&
      isEmpty(eventPayloadState.practitionerRole) &&
      isEmpty(eventPayloadState.location)
    setQueryRemainingFhirResources(send)
  }, [launchLoading, eventPayloadState.practitionerRole, eventPayloadState.location])

  useEffect(() => {
    // get Features
    const fetchFeatures = async () => {
      const response = await getFeatures(AUTH_TOKEN)

      if (response.ok) {
        const json = await response.json()
        FeatureDispatch({ type: FeatureActionEnum.SET_FEATURES, data: json })
        setFeaturesFetched(true)
      }
    }

    if (!featuresFetched && AUTH_TOKEN) {
      fetchFeatures()
    }
  }, [AUTH_TOKEN, featuresFetched, FeatureDispatch])

  useEffect(() => {
    if (
      algolia.state.languagePackageIdentifier !== '' &&
      algolia.state.languagePackageIdentifier !== packageId
    ) {
      setPackageId(algolia.state.languagePackageIdentifier)

      const packageFacet: DiscoFacet = {
        attributes: [attributeLabels.languagePackage],
        values: [algolia.state.languagePackageIdentifier],
        joiner: DiscoJoiner.OR,
      }
      launchFilterHandler.add(packageFacet)
      optionalFilterHandler.add(packageFacet)
      globalFilterHandler.add(packageFacet)
    }
  }, [
    algolia.state.languagePackageIdentifier,
    launchFilterHandler,
    optionalFilterHandler,
    globalFilterHandler,
    packageId,
  ])

  useEffect(() => {
    // patient is loaded
    if (patient?.id && patient.id !== patientId && patient.preferredLocalization) {
      setPatientId(patient.id)
      searchLanguageDispatch({
        type: LanguageActionTypes.LANGUAGE_SELECTED,
        data: patient.preferredLocalization,
      })
      const languageFacet: DiscoFacet = {
        attributes: [attributeLabels.selectedLanguage],
        values: [patient.preferredLocalization.key],
        joiner: DiscoJoiner.OR,
      }

      optionalFilterHandler.add(languageFacet)
      globalFilterHandler.add(languageFacet)
    }
  }, [
    patient.id,
    patientId,
    optionalFilterHandler,
    globalFilterHandler,
    patient.preferredLocalization,
    searchLanguageDispatch,
  ])

  useEffect(() => {
    if (clientConfigurations['useDeliverySettingFilters']) {
      // encounter is loaded
      if (launchData?.encounter?.id && launchData?.encounter?.id !== encounterId) {
        setEncounterId(launchData.encounter.id)

        if (
          launchData?.encounter?.careSetting?.toUpperCase() === CareSetting.Inpatient.toUpperCase()
        ) {
          const discoFacet: DiscoFacet = {
            attributes: [attributeLabels.delivery],
            values: [CareSetting.Inpatient],
            joiner: DiscoJoiner.OR,
          }
          optionalFilterHandler.add(discoFacet)
        } else if (
          launchData?.encounter?.careSetting?.toUpperCase() ===
          CareSetting.Outpatient?.toUpperCase()
        ) {
          const discoFacet: DiscoFacet = {
            attributes: [attributeLabels.delivery],
            values: [CareSetting.Outpatient],
            joiner: DiscoJoiner.OR,
          }
          optionalFilterHandler.add(discoFacet)
        }
      }
    }
  }, [
    clientConfigurations,
    launchData?.encounter?.careSetting,
    launchData?.encounter?.id,
    encounterId,
    optionalFilterHandler,
  ])

  useEffect(() => {
    const fetchPreviouslyAssignedContent = async (): Promise<void> => {
      try {
        const res = await queryClient.fetchQuery(['previously-assigned', 'bar'], {
          queryFn: () => fetchPreviouslyAssigned(patient.id, AUTH_TOKEN),
        })
        if (res) {
          patientDispatch({ type: PatientActionEnum.SET_HISTORY, data: res })
        }
      } catch (e: any) {
        console.log('Error fetching assignment history', e)
      }
    }
    if (patient && patient.id && patient.id !== patientId) {
      fetchPreviouslyAssignedContent()
    }
  }, [patient.id, queryClient, patientId, patient, patientDispatch, AUTH_TOKEN, features])

  // TODO: This sets the display language for content to English,
  // if we decide to have a multiple language UI we'll have to wire this up differently
  useEffect(() => {
    if (
      !launchError &&
      !globalFilterHandler.isActive(attributeLabels.displayLanguage, DEFAULT_CONTENT_LANGUAGE)
    ) {
      const discoFacet: DiscoFacet = {
        attributes: [attributeLabels.displayLanguage],
        values: [DEFAULT_CONTENT_LANGUAGE],
        joiner: DiscoJoiner.OR,
      }
      globalFilterHandler.add(discoFacet)
      favoritesFilterHandler.add(discoFacet)
    }
  }, [globalFilterHandler, launchError, favoritesFilterHandler])

  useEffect(() => {
    if (patient.gender) {
      if (!genderFilterApplied) {
        if (FilterableGenders.includes(patient.gender.toLowerCase())) {
          const genderFacet: DiscoFacet = {
            attributes: [attributeLabels.sex],
            values: [patient.gender],
            joiner: DiscoJoiner.OR,
          }
          globalFilterHandler.add(genderFacet)
          launchFilterHandler.add(genderFacet)
        }
        setGenderFilterApplied(true)
      }
    }
  }, [patient.gender, genderFilterApplied, globalFilterHandler, launchFilterHandler])

  useEffect(() => {
    if (patient.age && !ageFilterApplied) {
      const discoFacetMaxAge: DiscoFacet = {
        attributes: [attributeLabels.leftAge],
        values: [patient.age],
        joiner: DiscoJoiner.OR,
        comparator: DiscoComparator.GreaterThan,
      }
      const discoFacetMinAge: DiscoFacet = {
        attributes: [attributeLabels.rightAge],
        values: [patient.age],
        joiner: DiscoJoiner.OR,
        comparator: DiscoComparator.LessThan,
      }
      globalFilterHandler.add(discoFacetMaxAge)
      globalFilterHandler.add(discoFacetMinAge)
      launchFilterHandler.add(discoFacetMaxAge)
      launchFilterHandler.add(discoFacetMinAge)

      setAgeFilterApplied(true)
    }
  }, [ageFilterApplied, setAgeFilterApplied, globalFilterHandler, patient.age, launchFilterHandler])

  if (features['enableDebugMode'] && window.console && console.info) {
    console.info(globalFilterProperties.aisFilterString, optionalFilterProperties.aisOptionalList)
  }

  useEffect(() => {
    const fetchRemainingFhirResources = async () => {
      const { cacheId /* use */ } = getQueryParams(window.location.search)
      // const useSSO = use === 'SSO'
      const includeFhirTypes: FhirResourceQuery = {
        fhirTypes: [
          FhirResourceTypes.LOCATION,
          FhirResourceTypes.DEPARTMENT,
          FhirResourceTypes.PRACTITIONER_ROLE,
        ],
        status: FhirResourceQueryStatus.INCLUDE,
      }
      // TODO: awaiting validation
      // if (useSSO) {
      //   includeFhirTypes.fhirTypes.push(FhirResourceTypes.PRACTITIONER)
      // }
      const newPayloadState = { ...launchData }
      try {
        const fhirResponse = await fetchFhir(cacheId, includeFhirTypes)
        newPayloadState.location = isEmpty(fhirResponse?.location)
          ? EMPTY_LOCATION
          : fhirResponse.location
        newPayloadState.department = isEmpty(fhirResponse?.department)
          ? EMPTY_LOCATION
          : fhirResponse.department
        newPayloadState.practitionerRole = isEmpty(fhirResponse?.practitionerRole)
          ? EMPTY_PRACTITIONER_ROLE
          : fhirResponse.practitionerRole
        // if (useSSO) {
        // TODO: awaiting validation
        // newPayloadState.practitioner.writebackId = fhir.practitioner?.writebackId
        // }
        eventPayloadDispatch({
          type: EventPayloadActionEnum.SET_EVENT_PAYLOAD,
          data: createEventPayloadContextFromLaunch(newPayloadState),
        })
      } catch (e: any) {
        console.error(e)
        trackErrorToAppInsights(e, 'fetchRemainingfhirResources')
        newPayloadState.location = EMPTY_LOCATION
        newPayloadState.department = EMPTY_LOCATION
        newPayloadState.practitionerRole = EMPTY_PRACTITIONER_ROLE
        eventPayloadDispatch({
          type: EventPayloadActionEnum.SET_EVENT_PAYLOAD,
          data: createEventPayloadContextFromLaunch(newPayloadState),
        })
      }
    }
    if (queryRemainingFhirResources) {
      fetchRemainingFhirResources()
    }
  }, [eventPayloadDispatch, launchData, queryRemainingFhirResources])

  useEffect(() => {
    if (sendLaunchEvent) {
      sendClinicalLaunchEvent(eventPayloadState, initialTimeStamp, searchLanguage)
      setLaunchEventSent(true)
    }
  }, [eventPayloadState, initialTimeStamp, searchLanguage, setLaunchEventSent, sendLaunchEvent])

  return (
    <ThemeProvider theme={transitionsDisabledTheme}>
      <Layout
        launchLoading={launchLoading}
        launchError={launchError ?? ''}
        filterProperties={globalFilterProperties}
        filterHandler={globalFilterHandler}
        optionalProperties={optionalFilterProperties}
        optionalFilterHandler={optionalFilterHandler}
        launchFilterProperties={launchFilterProperties}
        launchFilterHandler={launchFilterHandler}
        algolia={algolia}
        features={features}
      />
    </ThemeProvider>
  )
}
