import { AllIcon } from 'components/Icons/Icons'
import { RefinementToggle } from '../RefinementToggle'
import { DirectionsWalkRounded, HotelOutlined } from '@material-ui/icons'
import { attributeLabels, FilterHandlerType } from 'services/filters/filter.config'
import { CareSetting } from 'types/LaunchContextTypes'
import { DiscoFacet, DiscoJoiner } from 'types/DiscoTypes'
import './CareSettingRefinementList.scss'

export const sectionLabel = 'Care Setting'
export const allLabel = 'Show All Care Setting Content'
export const inpatientLabel = 'Show Inpatient Only'
export const outpatientLabel = 'Show Outpatient Only'

type Props = {
  filterHandler: FilterHandlerType
  launchFilterHandler: FilterHandlerType
  optionalFilterHandler: FilterHandlerType
}

export const CareSettingRefinementList: React.FC<Props> = ({
  filterHandler,
  launchFilterHandler,
  optionalFilterHandler,
}) => {
  const inPatientActive = optionalFilterHandler.isActive(
    attributeLabels.delivery,
    CareSetting.Inpatient
  )
  const outPatientActive = optionalFilterHandler.isActive(
    attributeLabels.delivery,
    CareSetting.Outpatient
  )

  function allFilterActive(): boolean {
    return (inPatientActive && outPatientActive) || (!inPatientActive && !outPatientActive)
  }

  const allActive = allFilterActive()

  const inPatientFacet: DiscoFacet = {
    attributes: [attributeLabels.delivery],
    values: [CareSetting.Inpatient],
    joiner: DiscoJoiner.OR,
  }

  const outPatientFacet: DiscoFacet = {
    attributes: [attributeLabels.delivery],
    values: [CareSetting.Outpatient],
    joiner: DiscoJoiner.OR,
  }

  const clearAllFilters = () => {
    remove([inPatientFacet])
    remove([outPatientFacet])
  }

  function toggleAllActive() {
    clearAllFilters()
  }

  function toggleInpatient() {
    if (!inPatientActive) {
      add([inPatientFacet])
      remove([outPatientFacet])
    }
  }

  function toggleOutPatient() {
    if (!outPatientActive) {
      add([outPatientFacet])
      remove([inPatientFacet])
    }
  }

  function remove(facets: DiscoFacet[]) {
    facets.forEach(f => {
      filterHandler.remove(f)
      launchFilterHandler.remove(f)
      optionalFilterHandler.remove(f)
    })
  }

  function add(facets: DiscoFacet[]) {
    facets.forEach(f => {
      filterHandler.add(f)
      launchFilterHandler.add(f)
      optionalFilterHandler.add(f)
    })
  }

  return (
    <div className='caresetting-filters-wrapper'>
      <h3>{sectionLabel}</h3>
      <div className='filters-box' aria-label={sectionLabel}>
        <RefinementToggle
          active={allActive}
          toggleActive={toggleAllActive}
          label={allLabel}
          toolTipPlacement={'right'}
        >
          <AllIcon />
        </RefinementToggle>
        <RefinementToggle
          active={inPatientActive}
          toggleActive={toggleInpatient}
          label={inpatientLabel}
          toolTipPlacement={'bottom'}
        >
          <HotelOutlined />
        </RefinementToggle>
        <RefinementToggle
          pos={'last'}
          active={outPatientActive}
          toggleActive={toggleOutPatient}
          label={outpatientLabel}
          toolTipPlacement={'bottom'}
        >
          <DirectionsWalkRounded />
        </RefinementToggle>
      </div>
    </div>
  )
}
