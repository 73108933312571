import _ from 'lodash'
import moment from 'moment'
import { DiscoResourceTypes } from 'types/DiscoTypes'
import { LaunchCode, LaunchContext } from 'types/LaunchContextTypes'
import { Patient } from 'types/Patient'

export function getDiscoveryHeaders(entitlementKey: string, appId: string): HeadersInit {
  let headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  headers['app_id'] = appId
  headers['query_key'] = entitlementKey
  return headers
}

export function convertLaunchCodes(lcs?: LaunchCode[]) {
  return lcs?.map(lc => {
    const { text, codes } = lc
    return {
      text,
      codes: codes?.map(c => {
        const { codeSystem, code, display } = c
        return {
          codeSystem,
          code,
          display,
        }
      }),
    }
  })
}

export function retainAllowedProperties(context: LaunchContext): string {
  const { patient, practitioner, encounter } = context
  return JSON.stringify({
    patient: _.pick(patient, ['preferredLocalization', 'age', 'gender']),
    practitioner: _.pick(practitioner, ['id']),
    encounter: {
      diagnoses: convertLaunchCodes(encounter?.diagnoses),
      reasons: convertLaunchCodes(encounter?.reasons),
      medications: convertLaunchCodes(encounter?.medications),
      immunizations: convertLaunchCodes(encounter?.immunizations),
    },
  })
}

export function convertLaunchCodesToDiscoStagev4Resources(
  resourceType: DiscoResourceTypes,
  lcs?: LaunchCode[]
) {
  if (resourceType === DiscoResourceTypes.Immunization) {
    return lcs?.map(lc => {
      const { text, codes } = lc
      return {
        resource: {
          vaccineCode: {
            text,
            coding: codes?.map(c => {
              const { codeSystem, code, display } = c
              return {
                system: codeSystem,
                code,
                display,
              }
            }),
          },
          resourceType: resourceType,
        },
      }
    })
  } else {
    return lcs?.map(lc => {
      const { text, codes } = lc
      return {
        resource: {
          code: {
            text,
            coding: codes?.map(c => {
              const { codeSystem, code, display } = c
              return {
                system: codeSystem,
                code,
                display,
              }
            }),
          },
          resourceType: resourceType,
        },
      }
    })
  }
}

export function convertPatientForStage(patient: Patient): any {
  let stagePatient = {} as any

  stagePatient.communication = [
    {
      language: {
        text: patient.preferredLocalization.name,
      },
      preferred: true,
    },
  ]

  if (patient.gender) {
    stagePatient.gender = patient.gender.toLowerCase()
  }

  if (patient.dateOfBirth) {
    stagePatient.birthDate = moment(patient?.dateOfBirth).format('YYYY-MM-DD')
  }

  return stagePatient
}

export function retainAllowedPropertiesStageV2(context: LaunchContext): string {
  const { patient, encounter } = context

  return JSON.stringify({
    patient: convertPatientForStage(patient),
    medications: {
      entry: convertLaunchCodesToDiscoStagev4Resources(
        DiscoResourceTypes.Medication,
        encounter.medications
      ),
    },
    // Disco API wants us to send reasons over as type of condition.
    reasons: {
      entry: convertLaunchCodesToDiscoStagev4Resources(
        DiscoResourceTypes.Condition,
        encounter?.reasons
      ),
    },
    conditions: {
      entry: convertLaunchCodesToDiscoStagev4Resources(
        DiscoResourceTypes.Condition,
        encounter?.diagnoses
      ),
    },
    immunizations: {
      entry: convertLaunchCodesToDiscoStagev4Resources(
        DiscoResourceTypes.Immunization,
        encounter?.immunizations
      ),
    },
  })
}
