/* polyfills for IE10/epic */
import 'core-js/stable'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'intersection-observer'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
declare global {
  interface Window {
    appInsights: any
    sessionId: string
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
