import { createTheme } from '@material-ui/core/styles'

const defaultThemeBase = {
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
  palette: {
    primary: {
      main: '#006272',
    },
  },
  overrides: {
    MuiTextField: {
      root: {},
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: '#676767',
      },
    },
  },
}

const transitionsDisabled = { ...defaultThemeBase, transitions: { create: () => 'none' } }

export const defaultTheme = createTheme(defaultThemeBase)
export const transitionsDisabledTheme = createTheme(transitionsDisabled)
