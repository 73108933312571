import { Localization } from 'types/Localization'
import { Address, AssignmentContent, AssignmentType } from 'types/Assignment'
import { LaunchCode } from './LaunchContextTypes'

export interface Patient {
  id: string
  address?: Address
  firstName?: string
  lastName?: string
  age?: string
  gender?: string
  preferredLanguage?: string
  preferredLocalization: Localization
  zipCode?: string
  history: PatientHistory[]
  phoneNumber?: string
  phoneNumberType?: string
  email?: string
  dateOfBirth?: string
  medicalRecordNumber?: string
  medicalRecordNumberIdentifierType?: string
  race?: string
  ethnicity?: string
  previouslyAssigned: PreviouslyAssignedContent[]
  problems?: LaunchCode[]
}

export interface PatientHistory {
  id: string
  assignmentType: AssignmentType
  notes: string
  practitionerName: string
  assignmentDate: Date
  content: AssignmentContent[]
}

export interface PreviouslyAssignedContent {
  hwid: string
  assignmentType: AssignmentType
  assignmentDate: Date
}

export interface PatientHistoryQueryResponse {
  page: number
  pageCount: number
  pageSize: number
  resultCount: number
  results: PatientHistory[]
}

export const FilterableGenders: string[] = ['male', 'female', 'm', 'f']
