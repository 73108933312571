import React from 'react'

// contextType is for logging purposes
export function createCtx<ContextType>(contextType?: string) {
  const ctx = React.createContext<ContextType | undefined>(undefined)
  function useCtx() {
    const c = React.useContext(ctx)
    if (!c) throw new Error('useCtx must be inside a Provider with a value: ' + contextType)
    return c
  }

  return [useCtx, ctx.Provider] as const
}
