export enum FhirResourceTypes {
  PRACTITIONER = 'practitioner',
  PATIENT = 'patient',
  ENCOUNTER = 'encounter',
  DIAGNOSIS = 'diagnosis',
  PRACTITIONER_ROLE = 'practitionerRole',
  LOCATION = 'location',
  DEPARTMENT = 'department',
  IMMUNIZATION = 'immunization',
  MEDICATION = 'medication',
}

export enum FhirResourceQueryStatus {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

export interface FhirResourceQuery {
  fhirTypes: FhirResourceTypes[]
  status: FhirResourceQueryStatus
}
