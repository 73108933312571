import PropTypes from 'prop-types'

export const clinicianShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  specialty: PropTypes.string,
  entitlementKey: PropTypes.string.isRequired,
}

export const patientShape = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number,
  gender: PropTypes.oneOf([
    'Male',
    'Female',
    'Trans',
    'Trans Male',
    'Trans Female',
    'Non-binary',
    'Neutral',
    'Agender',
  ]),
  clinician: PropTypes.shape(clinicianShape),
  language: PropTypes.string,
}

export const encounterDataShape = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  codeSystem: PropTypes.string.isRequired,
  concepts: PropTypes.arrayOf(PropTypes.string),
  fhir: PropTypes.object,
}

export const encounterShape = {
  id: PropTypes.string,
  title: PropTypes.string,
  patient: PropTypes.shape(patientShape),
  delivery: PropTypes.string,
  data: PropTypes.arrayOf(encounterDataShape),
}

export const encounterContextShape = {
  code: PropTypes.string.isRequired,
  codeSystem: PropTypes.string.isRequired,
  patient: PropTypes.shape(patientShape),
  delivery: PropTypes.string,
}

export const filterShape = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  currentRefinement: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export const getCommonButtonProps = component => {
  const buttonProps = [
    'autofocus',
    'disabled',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'id',
    'name',
    'readOnly',
    'rel',
    'required',
    'tabIndex',
    'title',
    'type',
    'value',
  ]
  return getCommonProps(component, buttonProps)
}

export const getCommonAnchorProps = component => {
  const anchorProps = [
    'href',
    'hreflang',
    'media',
    'ping',
    'referrerpolicy',
    'download',
    'id',
    'rel',
    'target',
    'disabled',
    'readOnly',
    'tabIndex',
    'title',
    'type',
  ]
  return getCommonProps(component, anchorProps)
}

export const getCommonSelectProps = component => {
  const selectProps = [
    'autofocus',
    'disabled',
    'form',
    'multiple',
    'name',
    'id',
    'readOnly',
    'required',
    'size',
    'tabIndex',
    'title',
    'value',
  ]
  return getCommonProps(component, selectProps)
}

export const getCommonCheckboxProps = component => {
  const checkboxProps = [
    'autofocus',
    'checked',
    'defaultChecked',
    'disabled',
    'name',
    'id',
    'readOnly',
    'required',
    'tabIndex',
    'title',
    'value',
  ]
  return getCommonProps(component, checkboxProps)
}

export const getCommonRadioProps = component => {
  const radioButtonProps = [
    'autofocus',
    'checked',
    'defaultChecked',
    'defaultValue',
    'disabled',
    'form',
    'name',
    'id',
    'required',
    'tabIndex',
    'title',
    'value',
  ]
  return getCommonProps(component, radioButtonProps)
}

export const getCommonInputProps = component => {
  const inputProps = [
    'autocomplete',
    'autofocus',
    'defaultValue',
    'disabled',
    'getValidator',
    'height',
    'id',
    'list',
    'max',
    'maxLength',
    'min',
    'multiple',
    'name',
    'placeholder',
    'pattern',
    'readOnly',
    'size',
    'step',
    'tabIndex',
    'type',
    'validationErrors',
    'validators',
    'width',
    'value',
  ]
  return getCommonProps(component, inputProps)
}

const getCommonProps = (component, knownProps) => {
  const commonProps = {}
  Object.keys(component.props).forEach(key => {
    const localProp = component.props[key]
    if (key.startsWith('data-') || key.startsWith('aria-')) {
      commonProps[key] = localProp
    } else if (knownProps.includes(key)) {
      commonProps[key] = localProp
    } else if (
      key.startsWith('on') &&
      typeof localProp === 'function' &&
      !(typeof component[key] === 'function')
    ) {
      commonProps[key] = localProp
    }
  })
  return commonProps
}
