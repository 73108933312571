import React from 'react'
import { createCtx } from 'services/context/ContextHelper'
import { Preview, EMPTY_PREVIEW } from 'types/Preview'

export enum PreviewActionEnum {
  SET_PREVIEW,
  CLEAR_PREVIEW,
}

type PreviewAction =
  | { type: PreviewActionEnum.SET_PREVIEW; data: Preview }
  | { type: PreviewActionEnum.CLEAR_PREVIEW }

export type PreviewContextType = {
  state: Preview
  dispatch: React.Dispatch<PreviewAction>
}

type Props = {
  initialState?: Preview
}

const [usePreview, CtxProvider] = createCtx<PreviewContextType>('Preview Context')

const PreviewContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: Preview, action: PreviewAction): Preview {
    switch (action.type) {
      case PreviewActionEnum.SET_PREVIEW:
        return action.data
      case PreviewActionEnum.CLEAR_PREVIEW:
        return EMPTY_PREVIEW
      default:
        console.warn('Action type not handled in Preview reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_PREVIEW)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { PreviewContextProvider, usePreview }
