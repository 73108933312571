import { FC, useEffect, useRef } from 'react'
import { ListItem, ListItemAvatar, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import './ConceptListItem.scss'

type Props = {
  isSelected: boolean
  handleConceptItemClick?: (label: any, checked?: boolean) => any
  label: string
  handleKey: (e: any) => void
}

export const ConceptListItem: FC<Props> = ({
  isSelected,
  handleConceptItemClick: handleListItemClick,
  label,
  handleKey,
}) => {
  var className = 'conceptListItem'
  className += isSelected ? ' selectedListItem' : ''

  const focusRef = useRef<any>(null)

  useEffect(() => {
    if (isSelected) {
      focusRef?.current?.focus({ preventScroll: true })
    }
  }, [isSelected])

  return (
    <ListItem
      button
      component='li'
      tabIndex={isSelected ? 0 : -1}
      selected={isSelected}
      className={className}
      ref={focusRef}
      onKeyDown={(e: any) => handleKey(e)}
      onClick={() => (handleListItemClick ? handleListItemClick(label) : null)}
    >
      <div className='conceptListItem-icon'>
        <ListItemAvatar className='iconWrapper'>
          <SearchIcon />
        </ListItemAvatar>
      </div>
      <div className='conceptListItem-title'>
        <Typography className='label' variant='subtitle1' component='h2'>
          {label}
        </Typography>
      </div>
    </ListItem>
  )
}
