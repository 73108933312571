import { CartItem } from 'types/Cart'
import { LaunchContext } from 'types/LaunchContextTypes'
import { InsightEventNames, sendSearchEvent } from './algoliainsight.util'
import { openUrlNewWindow } from './common'
import { EventPayloadContextState } from 'services/context/EventPayloadCtx'
import { sendContentAssignedEvent } from './events/bee/event.util'
import { CreateAssignmentResponse } from 'types/Assignment'

export const DESCRIPTION_CHAR_LIMIT = 255

export const printPDF = (url: string) => {
  openUrlNewWindow(url)
}

export const sendAssignedEvents = (
  items: CartItem[],
  eventPayloadState: EventPayloadContextState,
  assignment: CreateAssignmentResponse
) => {
  items?.forEach(item => {
    sendSearchEvent(InsightEventNames.assigned, item.algoliaInsightsEventData)
    sendContentAssignedEvent(eventPayloadState, assignment, item)
  })
}

export async function handleAttach(
  pdfUrl: string,
  print: boolean,
  isMultipleAttach: boolean,
  context: LaunchContext,
  selectedContent: CartItem[],
  hwAccessToken?: string
): Promise<string> {
  let error = ''
  selectedContent?.forEach(v =>
    sendSearchEvent(InsightEventNames.assigned, v.algoliaInsightsEventData)
  )

  if (print) {
    openUrlNewWindow(pdfUrl)
  }

  // If attaching multiple documents
  // ignore
  if (isMultipleAttach) {
    return Promise.resolve('')
  }

  return error
}

export function getDescription(documents: string[], charLimit = DESCRIPTION_CHAR_LIMIT) {
  switch (documents.length) {
    case 0:
      return ''
    case 1:
      return documents[0].length <= charLimit ? documents[0] : '1 title'
    default:
      var description = documents.join(', ')
      if (description.length <= charLimit) return description

      // check length of adding first title
      var truncatedDescription =
        documents[0].length + ', '.length + `, and ${documents.length - 1} other titles`.length >
        charLimit
          ? ''
          : documents.shift()!

      // check length of adding each *next* title
      while (
        documents.length &&
        truncatedDescription.length +
          ', '.length +
          documents[0].length +
          `, and ${documents.length - 1} other titles`.length <
          charLimit
      ) {
        truncatedDescription += ', ' + documents.shift()
      }
      return (truncatedDescription += `, and ${documents.length} other titles`)
  }
}
