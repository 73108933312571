import { MultiIndexHits } from 'types/AlgoliaHit'
import { Hit } from 'react-instantsearch-core'

export const normalizeHits = <Type>(
  hits: Hit<Type>[] | MultiIndexHits[] | any,
  indexName: string
): Hit<Type>[] => {
  let items = hits
  if (Array.isArray(hits) && hits[0]?.index && Array.isArray(hits[0].hits)) {
    items = hits[0].hits
    hits.forEach(hitIndex => {
      if (hitIndex.index === indexName) {
        items = hitIndex?.hits || hitIndex
      }
    })
  }
  return items?.hits || items
}
