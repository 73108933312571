import { isEqual } from 'lodash'
import { Localization } from 'types/Localization'

export const IsDefaultLanguage = (selectedLanguage: Localization): boolean => {
  return isEqual(selectedLanguage, DEFAULT_LOCALIZATION)
}

export const Localizations: Localization[] = [
  {
    key: 'en-us',
    indexKey: 'en_us',
    name: 'English - US',
    abbreviation: 'EN',
    fhirCodes: ['en', 'en-US'],
  },
  {
    key: 'en-ca',
    indexKey: 'en_ca',
    name: 'English - CA',
    abbreviation: 'EN',
    fhirCodes: ['en-CA'],
  },
  {
    key: 'es-us',
    indexKey: 'es_us',
    name: 'Spanish',
    abbreviation: 'ES',
    fhirCodes: ['es'],
  },
  {
    key: 'ar-us',
    indexKey: 'ar_us',
    name: 'Arabic',
    abbreviation: 'AR',
    fhirCodes: ['ar'],
  },
  {
    key: 'hy-us',
    indexKey: 'hy_us',
    name: 'Armenian',
    abbreviation: 'HY',
  },
  {
    key: 'bn-us',
    indexKey: 'bn_us',
    name: 'Bengali',
    abbreviation: 'BN',
    fhirCodes: ['bn'],
  },
  {
    key: 'bs-us',
    indexKey: 'bs_us',
    name: 'Bosnian',
    abbreviation: 'BS',
  },
  {
    key: 'zh-us',
    indexKey: 'zh_us',
    name: 'Chinese Simplified',
    abbreviation: 'ZH',
    fhirCodes: ['zh'],
  },
  {
    key: 'fa-us',
    indexKey: 'fa_us',
    name: 'Farsi',
    abbreviation: 'FA',
  },
  {
    key: 'fr-us',
    indexKey: 'fr_us',
    name: 'French',
    abbreviation: 'FR',
    fhirCodes: ['fr'],
  },
  {
    key: 'ht-us',
    indexKey: 'ht_us',
    name: 'Haitian Creole',
    abbreviation: 'HT',
  },
  {
    key: 'hm-us',
    indexKey: 'hm_us',
    name: 'Hmong',
    abbreviation: 'HM',
  },
  {
    key: 'km-us',
    indexKey: 'km_us',
    name: 'Khmer',
    abbreviation: 'KM',
  },
  {
    key: 'ko-us',
    indexKey: 'ko_us',
    name: 'Korean',
    abbreviation: 'KO',
    fhirCodes: ['ko'],
  },
  {
    key: 'ne-us',
    indexKey: 'ne_us',
    name: 'Nepali',
    abbreviation: 'NE',
  },
  {
    key: 'pl-us',
    indexKey: 'pl_us',
    name: 'Polish',
    abbreviation: 'PL',
    fhirCodes: ['pl'],
  },
  {
    key: 'pt-us',
    indexKey: 'pt_us',
    name: 'Portuguese, Brazil',
    abbreviation: 'PT',
    fhirCodes: ['pt'],
  },
  {
    key: 'ru-us',
    indexKey: 'ru_us',
    name: 'Russian',
    abbreviation: 'RU',
    fhirCodes: ['ru'],
  },
  {
    key: 'so-us',
    indexKey: 'so_us',
    name: 'Somali',
    abbreviation: 'SO',
  },
  {
    key: 'tl-us',
    indexKey: 'tl_us',
    name: 'Tagalog',
    abbreviation: 'TL',
  },
  {
    key: 'vi-us',
    indexKey: 'vi_us',
    name: 'Vietnamese',
    abbreviation: 'VI',
  },
]

export const DEFAULT_LOCALIZATION: Localization = Localizations.find(
  l => l.key === 'en-us'
) as Localization

export function IsEnglishLocalization(loc: Localization): boolean {
  return loc.key.startsWith('en')
}

export function GetLocalizationAbbreviation(key: any): string {
  const localization = Localizations.find(localization => localization.key === key)
  if (localization) {
    return localization.abbreviation
  }
  return ''
}

export function GetLocalizationFromKey(key: string): Localization {
  // TODO:
  // this needs to be more robust to so that we can handle
  // values like 'En', 'English', etc. currently only handles
  // en-us, en-US, fr-US, etc.
  const localization = Localizations.find(localization => localization.key === key?.toLowerCase())
  return localization ? localization : DEFAULT_LOCALIZATION
}

export function GetLocalizationFromPreferredLanguage(preferredLanguage: string): Localization {
  const localization = Localizations.find(
    localization =>
      localization.key.toLowerCase() === preferredLanguage.toLocaleLowerCase() ||
      localization.fhirCodes
        ?.map(code => code.toLowerCase())
        .includes(preferredLanguage.toLowerCase())
  )
  return localization ? localization : DEFAULT_LOCALIZATION
}

export function GetAvailableLocalizations(locs: string[]) {
  if (locs.length > 0) {
    return Localizations.filter(localization => locs.includes(localization.key))
  }
  return []
}
