import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import env from '@beam-australia/react-env'

const browserHistory = createBrowserHistory()
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: env('AI_INSTRUMENTATION_KEY'),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})

// Ignore loading app insights if no key was passed (like in tests)
if (env('AI_INSTRUMENTATION_KEY')) {
  appInsights.loadAppInsights()
}
export { reactPlugin, appInsights }
