import React from 'react'
import { createCtx } from './ContextHelper'
import { Practitioner } from '../../types/LaunchContextTypes'

export const EMPTY_PRACTITIONER: Practitioner = {
  id: '',
  ehrUserId: '',
  name: '',
  folders: [],
}

export enum PractitionerActionEnum {
  SET_PRACTITIONER,
}

type Props = {
  initialState?: Practitioner
}

type PractitionerAction = { type: PractitionerActionEnum.SET_PRACTITIONER; data: Practitioner }

type PractitionerContextType = {
  state: Practitioner
  dispatch: React.Dispatch<PractitionerAction>
}

const [usePractitioner, CtxProvider] = createCtx<PractitionerContextType>('Practitioner Context')

const PractitionerContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: Practitioner, action: PractitionerAction): Practitioner {
    switch (action.type) {
      case PractitionerActionEnum.SET_PRACTITIONER:
        return { ...action.data }
      default:
        console.warn('Action type not handled in Practitioner reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_PRACTITIONER)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { PractitionerContextProvider, usePractitioner }
