import env from '@beam-australia/react-env'
import { CreateAssignmentResponse, CreateAssignmentV3Params } from 'types/Assignment'
import { buildHeaders } from 'services/utility/api/api.util'
import { PatientHistory, PreviouslyAssignedContent } from 'types/Patient'
import { encodeEditedContent } from 'services/utility/assignment.util'
const ASSIGNMENT_URL = `${env('HW_API_ASSIGNMENT_URL')}/assignment`
const ASSIGNMENT_V3_URL = `${env('HW_API_ASSIGNMENT_URL')}/v3/assignment`

export enum RuntimeStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  Running = 'Running',
  Failed = 'Failed',
}

export async function createAssignmentV3(
  params: CreateAssignmentV3Params,
  hwAuthToken: string
): Promise<CreateAssignmentResponse> {
  try {
    const headers = buildHeaders(hwAuthToken, 'application/json')
    // although this isn't pure, I'm leaving it here
    // since it's sort of a middleware required for the network interaction
    params.content = encodeEditedContent(params.content)

    let res = await fetch(ASSIGNMENT_V3_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(params),
    })

    if (res.ok) {
      return res.json()
    }
    return {
      assignmentId: '',
      fileUrl: '',
      isWritebackSuccess: false,
      persist: { pdf: [], html: [] },
      assignment: {} as PatientHistory,
    } as CreateAssignmentResponse
  } catch {
    return {
      assignmentId: '',
      fileUrl: '',
      isWritebackSuccess: false,
      persist: { pdf: [], html: [] },
      assignment: {} as PatientHistory,
    } as CreateAssignmentResponse
  }
}

export async function getUniquePatientHistory(
  patientId: string,
  hwAuthToken: string
): Promise<Response> {
  return fetch(`${ASSIGNMENT_URL}/unique/${patientId}`, {
    method: 'GET',
    headers: buildHeaders(hwAuthToken),
  })
}

export async function patientHistoryQueryWithPagination(
  patientId: string,
  page: number,
  pageSize: number,
  hwAuthToken: string
) {
  return await fetch(
    `${ASSIGNMENT_V3_URL}/patient/${patientId}?page=${page}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: buildHeaders(hwAuthToken),
    }
  )
}

async function fetchPrevious(patientId: string, accessToken: string) {
  return await getUniquePatientHistory(patientId, accessToken)
}

export async function fetchPreviouslyAssigned(
  patientId: string,
  accessToken: string
): Promise<PreviouslyAssignedContent[] | null> {
  try {
    const response = await fetchPrevious(patientId, accessToken)
    if (response.ok) {
      return await response.json()
    }
  } catch (e: any) {
    console.log('Error fetching assignment history')
  }
  return null
}
