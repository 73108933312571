import { DiscoFacet } from './DiscoTypes'

export interface ResultSection {
  id: string
  label: string
  type: ResultSectionType
  collapsed: boolean
  data: ResultSectionData
}

export interface ResultSectionData {
  baseFilterString?: string
  discoFacets: DiscoFacet[]
}

export enum ResultSectionType {
  SEARCH = 'search',
  LAUNCH = 'launch',
}
