import { FC } from 'react'
import { TextField } from '@material-ui/core'
import { useWhiteInputBackground } from '../../services/utility/styles.util'

import './NotesField.scss'

type Props = {
  note: string
  updateNote: (note: string) => void
  variant?: string
}

export const NotesField: FC<Props> = ({ variant, note, updateNote }) => {
  const filledClass = useWhiteInputBackground()
  return (
    <div className='notes-container'>
      <TextField
        id='filled-full-width'
        multiline
        label='Notes'
        fullWidth
        margin='normal'
        InputLabelProps={{
          shrink: true,
          style: {},
          'aria-label': 'Content Notes',
        }}
        className={variant === 'filled' ? filledClass.root : undefined}
        autoFocus
        variant={'outlined'}
        minRows={2}
        maxRows={5}
        value={note}
        onChange={(e: any) => updateNote(e.target.value)}
      />
    </div>
  )
}
