import env from '@beam-australia/react-env'
import { buildHeaders } from 'services/utility/api/api.util'
const FEATURES_URL = `${env('HW_API_ASSIGNMENT_URL')}/features`

export async function getFeatures(hwAuthToken: string): Promise<Response> {
  const headers = buildHeaders(hwAuthToken, 'application/json')
  return fetch(FEATURES_URL, {
    method: 'GET',
    headers,
  })
}
