export interface Snack {
  show: boolean
  message: string
  duration: number
  backgroundColor: SnackbarBackgroundColor
  textColor: SnackbarTextColor
  key: string
}

export enum SnackbarBackgroundColor {
  DEFAULT = '#000000',
  SUCCESS = '#00856A',
  ERROR = '#DD372F',
}

export enum SnackbarTextColor {
  DEFAULT = '#000000',
  WHITE = '#FFFFFF',
}
