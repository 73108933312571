import { flatMap, map, uniq } from 'lodash'
import { FavoritesFolder } from '../../types/Favorites'
import { ValidationError } from 'types/Error'

export const NEW_FAVORITE_FOLDER = {
  FolderId: '',
  FolderName: '',
  FolderIndex: -1,
  Favorites: [],
  IsDefault: false,
  SortOrder: 0,
}

export const MAX_CHARACTER_LENGTH = 50
export const DUPLICATE_NAME_MESSAGE = 'List name already exists.'
export const BLANK_NAME_MESSAGE = 'Name cannot be blank.'
export const EXCEED_MAX_LENGTH_MESSAGE = 'Name exceeds max length.'

export function getUniqueFavoritesList(folders: FavoritesFolder[]): string[] {
  const favorites = flatMap(folders, folder => folder.Favorites)
  const ids = map(favorites, favorite => favorite.hwid)
  return uniq(ids)
}

export function getDefaultFolder(favoritesFolders: FavoritesFolder[]) {
  return favoritesFolders.find(folder => folder.IsDefault)
}

export const errorCheck = (
  value: string,
  folders: FavoritesFolder[],
  ignoreBlankNameRule: boolean = false
) => {
  const errors: ValidationError[] = []

  if (!ignoreBlankNameRule) {
    if (value.length === 0) {
      errors.push({ name: 'blankName', description: BLANK_NAME_MESSAGE })
    }
  }

  if (value.length > MAX_CHARACTER_LENGTH) {
    errors.push({ name: 'exceedMaxlength', description: EXCEED_MAX_LENGTH_MESSAGE })
  }

  const isFolderNameDuplicate = folders.find(folder => folder.FolderName === value) ? true : false
  if (isFolderNameDuplicate) {
    errors.push({ name: 'duplicateName', description: DUPLICATE_NAME_MESSAGE })
  }

  return errors
}
