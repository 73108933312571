import { FC } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'components/Button'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import './Edit.scss'

export const UNDO_BUTTON_TEST_ID = 'undo-edit-button'
export const SAVE_BUTTON_TEST_ID = 'save-edit-button'

type Props = {
  allowSave: boolean
  title: string
  showEditControls: boolean
  showUndo: boolean
  undoAll: () => any
  save: () => any
  close: () => any
}
const CLOSE_PREVIEW_TEXT = 'Close Preview'
const REMOVE_EDITS_TEXT = 'Remove Edits'
const SAVE_AND_CLOSE_TEXT = 'Save & Close'

export const EditHeader: FC<Props> = ({
  title,
  showEditControls,
  allowSave,
  showUndo,
  undoAll,
  save,
  close,
}) => {
  const handleSave = () => {
    if (allowSave) {
      save()
    }
  }

  const renderCloseIcon = (
    <AdviseTooltip text={CLOSE_PREVIEW_TEXT} placement={'left'}>
      <IconButton
        onClick={close}
        className='edit-header-close'
        aria-label={CLOSE_PREVIEW_TEXT}
        aria-controls={'modal'}
      >
        <CloseIcon />
      </IconButton>
    </AdviseTooltip>
  )

  return (
    <div className='edit-header'>
      <div className='edit-header-title'>
        <Typography id='modal-title' variant='h6' component='h2' data-testid='edit-header-title'>
          {title}
        </Typography>
      </div>
      <div className='edit-header-controls'>
        {showUndo ? (
          <Button
            className='edit-undo'
            aria-label={REMOVE_EDITS_TEXT}
            rounded
            small
            borderless
            onClick={undoAll}
            data-testid={UNDO_BUTTON_TEST_ID}
          >
            {REMOVE_EDITS_TEXT}
          </Button>
        ) : (
          <></>
        )}
        {showEditControls ? (
          <Button
            className={`edit-save ${allowSave ? '' : 'disabled'}`}
            aria-label={SAVE_AND_CLOSE_TEXT}
            rounded
            small
            onClick={handleSave}
            data-testid={SAVE_BUTTON_TEST_ID}
          >
            {SAVE_AND_CLOSE_TEXT}
          </Button>
        ) : (
          renderCloseIcon
        )}
      </div>
    </div>
  )
}
