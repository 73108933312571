export interface Popover {
  type: PopoverType
}

export enum PopoverType {
  'NONE',
  'PREVIEW',
  'EDIT',
  'REMOVEEDIT',
}
