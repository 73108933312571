import React from 'react'
import { createCtx } from './ContextHelper'
import { Snack, SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'

export const EMPTY_SNACKBAR: Snack = {
  show: false,
  message: '',
  duration: 0,
  key: '',
  backgroundColor: SnackbarBackgroundColor.DEFAULT,
  textColor: SnackbarTextColor.WHITE,
}

export enum SnackBarActionEnum {
  SET_SNACKBAR,
  CLEAR_SNACKBAR,
}

type SnackBarAction =
  | { type: SnackBarActionEnum.SET_SNACKBAR; data: Snack }
  | { type: SnackBarActionEnum.CLEAR_SNACKBAR }

type SnackBarContextType = {
  state: Snack
  dispatch: React.Dispatch<SnackBarAction>
}

const [useSnackBar, CtxProvider] = createCtx<SnackBarContextType>('SnackBar Context')

type Props = {
  initialState?: Snack
}

const SnackBarContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: Snack, action: SnackBarAction): Snack {
    switch (action.type) {
      case SnackBarActionEnum.SET_SNACKBAR:
        return { ...ctxState, ...action.data }
      case SnackBarActionEnum.CLEAR_SNACKBAR:
        return EMPTY_SNACKBAR
      default:
        console.warn('Action type not handled in SnackBar reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_SNACKBAR)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { SnackBarContextProvider, useSnackBar }
