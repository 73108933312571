import { useState } from 'react'
import { FilterStringBuilder } from 'services/filters/filter.string.builder'
import { FilterPropertiesType, FilterHandlerType } from 'services/filters/filter.config'
import { DiscoFacet, FilterStringBuilderSettings } from 'types/DiscoTypes'

const filterManagers: { [key: string]: FilterStringBuilder } = {}

export default function useFilterManager(id: string, settings?: FilterStringBuilderSettings) {
  if (!filterManagers[id]) {
    filterManagers[id] = new FilterStringBuilder(settings)
  }

  const filterStringBuilder = filterManagers[id]

  const [aisFilterString, setAisFilter] = useState('')
  const [aisFilterList, setFilterList] = useState<string[]>([])
  const [aisOptionalList, setOptionalList] = useState<string[]>([])
  const [isInDefaultState, setIsInDefaultState] = useState(true)
  const [activeFilterCount, setActiveFilterCount] = useState(0)

  filterStringBuilder.onChange = settings => {
    setAisFilter(settings.aisFilterString)
    setFilterList(settings.aisFilterList)
    setOptionalList(settings.optionalFilterList)
    setIsInDefaultState(settings.isInDefaultState)
    setActiveFilterCount(settings.activeFilterCount)
  }

  const filterHandler: FilterHandlerType = {
    initialize: (discoFacets: DiscoFacet[]) => {
      filterStringBuilder.initialize(discoFacets)
    },
    merge: (
      mergeFilters?: (string | number | undefined)[] | string,
      mergeAttributes?: string[] | undefined,
      replaceAttributes?: string[] | undefined
    ) => {
      filterStringBuilder.merge(mergeFilters, mergeAttributes, replaceAttributes)
    },
    add: (discoFacet: DiscoFacet) => {
      filterStringBuilder.add(discoFacet)
    },
    remove: (discoFacet: DiscoFacet) => {
      filterStringBuilder.remove(discoFacet)
    },
    toggle: (discoFacet: DiscoFacet) => {
      filterStringBuilder.update(discoFacet)
    },
    reset: (attributeLabel?: string) => {
      filterStringBuilder.reset(attributeLabel)
    },
    isActive: (attribute: string | undefined, label: string | number | undefined) =>
      filterStringBuilder.testFilterActive(attribute, label),
    setDebug: (debugOn: boolean) => filterStringBuilder.setDebug(debugOn),
  }

  const filterProperties: FilterPropertiesType = {
    aisFilterString,
    aisFilterList,
    aisOptionalList,
    isInDefaultState,
    activeFilterCount,
  }

  return { filterProperties, filterHandler }
}
