export const PLUS_NEW_LIST_TEXT = '+ New List'
export const DONE_TEXT = 'Done'
export const UNABLE_TO_UPDATE_FAVORITES = 'Unable to update favorites.'
export const FAVORITES_UPDATED_SUCCESSFULLY = 'Favorites updated successfully.'
export const COULD_NOT_CREATE = 'Could not create'
export const ARRANGE_LISTS_TEXT = 'Arrange Lists'
export const ADD_NEW_LIST_TEXT = '+ New List'
export const UNABLE_TO_SAVE = 'was unable to be saved.'
export const UNABLE_TO_DELETE = 'was unable to be deleted.'
export const SUCCESSFUL_SAVE = 'was successfully saved.'
export const SUCCESSFUL_DELETE = 'successfully deleted.'
export const SUCCESSFUL_CREATE = 'successfully created.'
export const UNSUCCESSFUL_SAVE_ARRANGE = 'Saving list changes was unsuccessful.'
export const SUCCESSFUL_SAVE_ARRANGE = 'List changes saved.'
export const LOSE_CHANGES_DIALOG_TITLE = 'Lose Changes?'
export const LOSE_CHANGES_DIALOG_MESSAGE = 'All of your list changes will be lost.'
export const LOSE_CHANGES_DIALOG_CANCEL_TEXT = 'Continue'
export const LOSE_CHANGES_DIALOG_CONFIRM_TEXT = 'Lose Changes'
export const SEARCH_RESULTS_VIEW_PATH = '/'
export const MANANGE_FAVORITES_VIEW_PATH = '/favorites/manage'
export const ARRANGE_FAVORITES_FOLDERS_VIEW_PATH = '/favorites/manage/arrange'

export enum FavoritesDialogCaller {
  MANAGE_CLOSE = 'manage close',
  MANAGE_ARRANGE_LISTS = 'manage arrange lists',
  ARRANGE_CLOSE = 'arrange close',
  NONE = 'none',
}

export interface PendingChangesDialogData {
  pendingChanges: boolean
  showDialog: boolean
  dialogCaller: FavoritesDialogCaller
}
