import { Dispatch, FC, useReducer } from 'react'
import { createCtx } from './ContextHelper'
import { Localization } from 'types/Localization'
import { GetAvailableLocalizations } from 'services/utility/languages.util'

export enum LanguageActionTypes {
  LANGUAGE_SELECTED,
  SET_INITIAL_LANGUAGE,
  SET_AVAILABLE_LANGUAGES,
}

export type LanguageState = {
  SelectedLanguage: Localization
  InitialLanguage: Localization | null
  AvailableLanguages: Localization[]
}

export const EMPTY_SEARCH_LANGUAGE_STATE: LanguageState = {
  SelectedLanguage: {} as any,
  InitialLanguage: null,
  AvailableLanguages: [],
}

type LanguageAction =
  | {
      type: LanguageActionTypes.LANGUAGE_SELECTED
      data: Localization
    }
  | {
      type: LanguageActionTypes.SET_AVAILABLE_LANGUAGES
      data: string[]
    }

export type SearchLanguageDispatch = Dispatch<LanguageAction>
const [useSearchLanguage, CtxProvider] = createCtx<{
  state: LanguageState
  dispatch: SearchLanguageDispatch
}>('Search Language Context')

type Props = {
  initialState?: LanguageState
}

const SearchLanguageCtxProvider: FC<Props> = ({ initialState, children }) => {
  const reducer = (state: LanguageState, action: LanguageAction) => {
    switch (action.type) {
      case LanguageActionTypes.LANGUAGE_SELECTED:
        if (state.InitialLanguage === null) {
          return {
            ...state,
            SelectedLanguage: action.data,
            InitialLanguage: action.data,
          }
        }
        return { ...state, SelectedLanguage: action.data }
      case LanguageActionTypes.SET_AVAILABLE_LANGUAGES:
        return { ...state, AvailableLanguages: GetAvailableLocalizations(action.data) }
      default:
        console.warn('Action not handled in Search Language Context', action)
        return EMPTY_SEARCH_LANGUAGE_STATE
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState ?? EMPTY_SEARCH_LANGUAGE_STATE)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { SearchLanguageCtxProvider, useSearchLanguage }
