import { FC } from 'react'
import './PageWrapper.scss'

type Props = {
  headerHeight: number
}

export const PageWrapper: FC<Props> = ({ headerHeight, children }) => {
  // Add 10px to the header height
  const headerPadding = headerHeight + 10
  return (
    <div className='view-page-wrapper' style={{ paddingTop: `${headerPadding}px` }}>
      {children}
    </div>
  )
}
