import React, { ReactNode } from 'react'
import { ResultSection } from 'types/ResultSections'
import { createCtx } from './ContextHelper'

export enum ResultsActionEnum {
  SET_RESULTS,
  UPDATE_ACCORDION_COLLAPSE,
}

type ResultAction =
  | { type: ResultsActionEnum.SET_RESULTS; data: ResultSection[] }
  | {
      type: ResultsActionEnum.UPDATE_ACCORDION_COLLAPSE
      data: { accordionId: string; value: boolean }
    }

type ResultContextType = {
  state: ResultSection[]
  dispatch: React.Dispatch<ResultAction>
}

const [useResults, CtxProvider] = createCtx<ResultContextType>('Results Context')

type Props = {
  initialState?: ResultSection[]
  children: ReactNode
}

const ResultsContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: ResultSection[], action: ResultAction): ResultSection[] {
    switch (action.type) {
      case ResultsActionEnum.SET_RESULTS:
        return action.data
      case ResultsActionEnum.UPDATE_ACCORDION_COLLAPSE:
        const newNew = ctxState.map(result => {
          if (result.id === action.data.accordionId) {
            result.collapsed = action.data.value
            return result
          }
          return result
        })
        return newNew
      default:
        console.warn('Action type not handled in Results reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? [])
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { ResultsContextProvider, useResults }
