import { List, ListItem } from '@material-ui/core'
import afterFrame from 'afterframe'
import { ConceptListItem } from 'components/ConceptListItem/ConceptListItem'
import { CustomListItem } from 'components/ListItem/CustomListItem'
import lodash from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Hit } from 'react-instantsearch-core'
import { useHistory, useLocation } from 'react-router'
import { appInsights } from 'services/AppInsights'
import { CartActionEnum, useCart } from 'services/context/CartContext'
import { useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { useSearchLanguage } from 'services/context/SearchLanguageContext'
import { useSnackBar, SnackBarActionEnum } from 'services/context/SnackbarContext'
import { CartItemFromHit, CreateCartItemId, ItemExistsInCart } from 'services/utility/cart.util'
import { GetAvailableLocalizations, GetLocalizationFromKey } from 'services/utility/languages.util'
import { ADD_TO_BASKET_TEXT, REMOVE_FROM_BASKET_TEXT } from 'services/utility/strings/cart'
import { uuid } from 'services/utility/uuid.util'
import { Algolia } from 'types/Algolia'
import { AlgoliaHit, ConceptHit } from 'types/AlgoliaHit'
import { SearchResultType } from 'types/Search'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'

type Props = {
  algolia: Algolia
  results: Hit<AlgoliaHit>[]
  suggestions: Hit<ConceptHit>[]
  selectedListItemIndex: number
  refineSearch: (searchTerm: string) => void
  query: string | undefined
  handleListItemClick: (hwid: string, checked?: any) => void
  handleKey: (e: any) => void
  handleShowSearchResult: (show: boolean) => void
}

type listItemMetadata = {
  id: string
  title: string
  position: number
  hasCheck: boolean
  checked?: boolean
}

export const AutocompleteResultsList: FC<Props> = ({
  algolia,
  results,
  suggestions,
  selectedListItemIndex,
  query,
  refineSearch,
  handleListItemClick,
  handleKey,
  handleShowSearchResult,
}) => {
  const { state: cart, dispatch: cartDispatch } = useCart()
  const { state: searchLanguage } = useSearchLanguage()
  const [resultsMetadata, setResultsMetadata] = useState<listItemMetadata[]>([])
  const [lastMessageIndex, setLastMessageIndex] = useState<number>()
  const [selectedMessage, setSelectedMessage] = useState<string>('')
  const location = useLocation()
  const history = useHistory()
  const { state: eventPayloadState } = useEventPayloadContext()
  const { dispatch: snackDispatch } = useSnackBar()

  useEffect(() => {
    const getSelectedMessage = (): string => {
      const metadata = resultsMetadata[selectedListItemIndex]
      if (metadata?.title) {
        let message = `${metadata.title}, ${metadata.position} of ${resultsMetadata.length}, `
        if (metadata.hasCheck) {
          message += getCheckedMessage(metadata.checked)
        } else {
          message = `Refine search, ${message}`
        }
        return message
      }
      return ''
    }

    if (lastMessageIndex !== selectedListItemIndex) {
      setLastMessageIndex(selectedListItemIndex)
      setSelectedMessage(getSelectedMessage())
    }
  }, [lastMessageIndex, selectedListItemIndex, resultsMetadata])

  const getCheckedMessage = (checked: boolean | undefined): string => {
    return checked ? 'checked' : 'not checked'
  }

  const handleCheck = (newChecked: boolean, hwid: string, languageKey: string) => {
    if (newChecked) {
      appInsights.startTrackEvent('Cart::AddToCart')
    }
    const index = results.findIndex(item => item.content.hwid === hwid)
    const result = index !== -1 ? results[index] : null
    const searchResultRank = index + 1

    if (result) {
      const loc = GetLocalizationFromKey(languageKey)
      const searchTerm = query ?? 'none'
      const item = CartItemFromHit(
        result,
        algolia.state.index,
        algolia.state.userToken,
        SearchResultType.USER_SEARCH,
        searchTerm,
        searchResultRank,
        result.content.purpose,
        GetAvailableLocalizations(
          result.content.languagePackage[algolia.state.languagePackageIdentifier]
        ),
        loc
      )
      setSelectedMessage(getCheckedMessage(newChecked))

      if (newChecked) {
        cartDispatch({
          type: CartActionEnum.ADD_ITEM,
          data: { ...item, localization: searchLanguage.SelectedLanguage },
        })
      } else {
        cartDispatch({
          type: CartActionEnum.REMOVE_ITEM,
          data: { ...item, localization: searchLanguage.SelectedLanguage },
        })
      }
      const message = newChecked ? ADD_TO_BASKET_TEXT : REMOVE_FROM_BASKET_TEXT
      const snack = {
        show: true,
        message: `"${item.title}" ${message}`,
        backgroundColor: SnackbarBackgroundColor.SUCCESS,
        textColor: SnackbarTextColor.WHITE,
        duration: 5000,
        key: uuid(),
      }
      snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })

      if (newChecked) {
        afterFrame(() => {
          appInsights.stopTrackEvent('Cart::AddToCart', {
            sessionId: eventPayloadState.sessionId,
            locationId: eventPayloadState.location?.id as string,
          })
        })
      }
    }
  }

  const handleSuggestionItemClick = (label: any) => {
    import('services/utility/events/bee/event.util').then(event => {
      event.sendManualSearchEvent(eventPayloadState, label, searchLanguage)
    })
    function goHome() {
      // navigate to main if on any other view
      if (location.pathname !== '/') {
        history.replace('/')
      }
    }
    goHome()
    refineSearch(label)
    handleShowSearchResult(true)
  }

  const resultsList: any[] = []
  const resultsMetadataBuilder: listItemMetadata[] = []

  let selectedResultIndex = 0
  suggestions.forEach((suggestion, sx) => {
    const suggestedItem = suggestion.query
    resultsMetadataBuilder.push({
      id: suggestedItem,
      title: suggestedItem,
      position: selectedResultIndex + 1,
      hasCheck: false,
    })
    if (suggestedItem) {
      resultsList.push(
        <ConceptListItem
          handleKey={(e: any) => handleKey(e)}
          key={`${sx}-suggestion`}
          label={suggestedItem}
          handleConceptItemClick={(label: any) => handleSuggestionItemClick(label)}
          isSelected={selectedResultIndex === selectedListItemIndex}
        />
      )
      selectedResultIndex++
    }
  })

  results.forEach((res, rx) => {
    const cartItemId = CreateCartItemId(res.content.hwid, searchLanguage.SelectedLanguage.key)
    const itemExistsInCart = ItemExistsInCart(cartItemId, cart.items)
    const availableLocalizations = GetAvailableLocalizations(
      res.content.languagePackage[algolia.state.languagePackageIdentifier]
    )

    resultsMetadataBuilder.push({
      id: res.content.hwid,
      title: res.content.title,
      position: selectedResultIndex + 1,
      checked: itemExistsInCart,
      hasCheck: true,
    })

    resultsList.push(
      <CustomListItem
        handleKey={(e: any) => handleKey(e)}
        key={`${rx}-result`}
        hwid={res.content.hwid}
        title={res.content.title}
        type={res.content.type}
        inHouse={res.content.inHouse}
        isSelected={selectedResultIndex === selectedListItemIndex}
        showLanguageSelect={false}
        availableLocalizations={availableLocalizations}
        selectedLanguage={searchLanguage.SelectedLanguage}
        existsInCart={itemExistsInCart}
        handleCheck={handleCheck}
        handleListItemClick={handleListItemClick}
      />
    )

    selectedResultIndex++
  })

  if (!lodash.isEqual(resultsMetadataBuilder, resultsMetadata)) {
    setResultsMetadata(resultsMetadataBuilder)
  }

  return (
    <List role='application'>
      <li role='presentation' className='offscreen-text'>
        <p aria-hidden={!selectedMessage} role='alert'>
          {selectedMessage}
        </p>
      </li>
      {resultsList.length > 0 ? (
        resultsList
      ) : (
        <ListItem component='li' button>
          <p>
            <i>No results found.</i>
          </p>
        </ListItem>
      )}
    </List>
  )
}
