import { FC } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import './AlertDialog.scss'

type Props = {
  show: boolean
  title?: string
  message?: string
  subMessage?: string
}
export const AlertDialog: FC<Props> = ({ show, title, message, subMessage, children }) => {
  const renderTitle = title ? <DialogTitle id='dialog-title'>{title}</DialogTitle> : null

  return (
    <Dialog
      data-testid='alert-dialog'
      PaperProps={{
        className: 'alert-dialog-paper',
      }}
      open={show}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      aria-modal='true'
    >
      {renderTitle}
      <DialogContent>
        <DialogContentText id='alert-dialog-description' className='dialog-content-text'>
          {message}
        </DialogContentText>
        <DialogContentText id='alert-dialog-subDescription' className='dialog-content-text'>
          {subMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='alert-dialog-actions'>{children}</DialogActions>
    </Dialog>
  )
}
