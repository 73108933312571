import { Purpose } from 'types/AlgoliaHit'
import { AlgoliaInsightEventData } from 'types/AlgoliaInsightEventData'
import { Localization } from 'types/Localization'
import { EditableContent, EMPTY_EDITABLE_CONTENT, PreviewUserActions } from 'types/Preview'
import { SearchResultType } from 'types/Search'
import { CreateCartItem } from './cart.util'
import { openUrlNewWindow } from './common'

export const previewInHouseContentPDF = (contentUrl: string) => {
  openUrlNewWindow(contentUrl)
}

export const setItemToPreview = (
  hwid: string,
  title: string,
  description: string | null,
  html: string,
  type: string,
  docType: string,
  availableLocalizations: Localization[],
  selectedLanguage: Localization,
  isEdited: boolean,
  editedContent: EditableContent,
  searchResultType: SearchResultType,
  searchTerm: string,
  searchResultRank: number,
  algoliaInsightEventData: AlgoliaInsightEventData,
  purpose: Purpose[],
  inHouse: boolean,
  source: string,
  previewLanguageKey: string,
  content: string
) => {
  const cartItem = CreateCartItem(
    hwid,
    title,
    description,
    '',
    type,
    docType,
    availableLocalizations,
    selectedLanguage,
    isEdited,
    isEdited ? editedContent : EMPTY_EDITABLE_CONTENT,
    searchResultType,
    searchTerm,
    searchResultRank,
    algoliaInsightEventData,
    purpose,
    inHouse,
    source
  )

  // This just does some object building and returns a preview object
  // mostly pulled this out into a util so it can be better tested
  return {
    previewId: hwid,
    previewTitle: title,
    previewLanguageKey, // means it was in the cart
    previewType: type,
    show: true,
    previewActions: [
      PreviewUserActions.CART,
      PreviewUserActions.FAVORITES,
      PreviewUserActions.EDIT,
    ],
    cartItem: cartItem,
    editMode: false,
    content: content,
  }
}
