import env from '@beam-australia/react-env'
import { buildHeaders } from 'services/utility/api/api.util'
import { InitializePractitionerResponse } from 'types/LaunchContextTypes'
const INITIALIZE_PRACTITIONER_URL_V2 = `${env('HW_API_ASSIGNMENT_URL')}/v2/practitioner`

export async function initializePractitioner(
  practitionerId: string,
  hwOrgId: string,
  hwAuthToken: string,
  practitionerName?: string,
  hwOrgName?: string
) {
  const response = await fetch(INITIALIZE_PRACTITIONER_URL_V2, {
    method: 'POST',
    headers: buildHeaders(hwAuthToken, 'application/json'),
    body: JSON.stringify({
      ehrUserId: practitionerId,
      practitionerName: practitionerName,
      hwOrganizationId: hwOrgId,
      hwOrganizationName: hwOrgName,
    }),
  })

  if (response.ok) {
    const json: InitializePractitionerResponse = await response.json()

    return json
  } else {
    return {
      practitionerId: '',
      folders: [],
      error: true,
    }
  }
}
