import { cloneDeep } from 'lodash'
import { maxUserAge, minUserAge, attributeLabels } from './filter.config'
import {
  LanguagePackageFilterSettingsType,
  AgeFilterSettingsType,
  BaseFilterType,
  DiscoFacet,
  DiscoJoiner,
  FilterStringBuilderSettings,
  Modifier,
  OnChangeFunctionType,
} from 'types/DiscoTypes'

export const getFilterStringBuilder = (settings?: FilterStringBuilderSettings) => {
  return new FilterStringBuilder(settings)
}

const buildOrFilterString = (
  itemList: (string | number | undefined)[],
  itemLabel: string | undefined
): string => {
  if (itemList?.length > 0) {
    const items: string[] = []
    itemList.forEach(item => items.push(filterLabelCompose(itemLabel, item)))
    const orFilter = items.length > 0 ? `(${items.join(DiscoJoiner.OR)})` : ''
    return orFilter
  }
  return ''
}

const getFilterLabelsList = (
  itemList: (string | number | undefined)[],
  itemLabel: string | undefined
): string[] => {
  const aisFilterList: string[] = []
  if (itemList?.length > 0) {
    itemList.forEach(item => aisFilterList.push(filterLabelCompose(itemLabel, item)))
  }
  return aisFilterList
}

export const areFiltersInDefaultState = (selectedFilters: string[]) => {
  if (Array.isArray(selectedFilters) && selectedFilters.length === defaultFilterValues.length) {
    if (
      selectedFilters[0] === defaultFilterValues[0] &&
      selectedFilters[1] === defaultFilterValues[1]
    ) {
      return true
    }
    if (
      selectedFilters[1] === defaultFilterValues[0] &&
      selectedFilters[0] === defaultFilterValues[1]
    ) {
      return true
    }
  }

  return false
}

export const getActiveFilterCount = (selectedFilters: string[]) => {
  if (Array.isArray(selectedFilters)) {
    const filters = cloneDeep(selectedFilters)
    defaultFilterValues.forEach(filterValue => {
      const filterIndex = filters.findIndex(filter => filter === filterValue)
      if (filterIndex !== -1) {
        filters.splice(filterIndex, 1)
      }
    })
    return filters.length
  }

  return 0
}

export const filterStringParse = (filterString = ''): string[] => {
  const aisFilterList: string[] = []

  let filter = filterString.replace(/ >= /, ':').replace(/ <= /, ':').replace(/[()]/g, '')
  const andSplit = filter.split(DiscoJoiner.AND)
  andSplit.forEach(andSplitItem => {
    if (andSplitItem.includes(DiscoJoiner.OR)) {
      const orSplit = andSplitItem.split(DiscoJoiner.OR)
      orSplit.forEach(orSplitItem => {
        aisFilterList.push(orSplitItem.trim())
      })
    } else {
      aisFilterList.push(andSplitItem.trim())
    }
  })
  return aisFilterList
}

export const filterLabelParse = (filterLabel: string | number | undefined) => {
  if (filterLabel !== undefined && `${filterLabel}`.includes(':')) {
    const filterParts = `${filterLabel}`.split(':')
    const attribute = filterParts[0]
    let label = filterParts[1].replace(/'/g, '')
    return { attribute, label }
  }
  return { attribute: '', label: '' }
}

export const filterLabelCompose = (
  attribute: string | undefined,
  label?: string | number | undefined,
  addQuotes: boolean = false
): string => {
  if (attribute && label !== undefined) {
    if (addQuotes || typeof label === 'string') {
      return `${attribute}:'${label}'`
    }
    return `${attribute}:${label}`
  }
  return ''
}

const isValidAttribute = (attribute: string | undefined) => {
  let valid = false
  for (var key in attributeLabels) {
    if (attribute === attributeLabels[key]) {
      valid = true
      break
    }
  }
  return valid
}

const defaultFilterValues = [
  filterLabelCompose(attributeLabels.leftAge, minUserAge),
  filterLabelCompose(attributeLabels.rightAge, maxUserAge),
]

export class FilterStringBuilder {
  onChange: OnChangeFunctionType | undefined
  isInDefaultState = true
  aisFilterString = ''
  aisFilterList: string[] = []
  optionalFilterList: string[] = []
  filters: any[] = []
  debug = false
  activeFilterCount = 0
  languagePackageIdentifier = ''
  selectedLanguage = ''

  constructor(settings: FilterStringBuilderSettings = {}) {
    this.onChange = settings.onChange

    const filters = []
    const {
      age,
      audience,
      audiences,
      leftAge,
      rightAge,
      concepts,
      concept,
      sex,
      sexes,
      type,
      types,
      delivery,
      deliveries,
      specialty,
      specialties,
      localization,
      localizations,
      product,
      products,
      hwid,
      hwids,
      debug,
      displayLanguage,
      languagePackageIdentifier,
      selectedLanguage,
    } = settings

    //Warning: changing the order here will probably break tests
    filters.push(new AgeFilter({ age, rightAge, leftAge, debug }))
    filters.push(new AudienceFilter({ item: audience, items: audiences, debug }))
    filters.push(new SexFilter({ item: sex, items: sexes, debug }))
    filters.push(new ConceptFilter({ item: concept, items: concepts, debug }))
    filters.push(new TypeFilter({ item: type, items: types, debug }))
    filters.push(new DeliveryFilter({ item: delivery, items: deliveries, debug }))
    filters.push(new SpecialtyFilter({ item: specialty, items: specialties, debug }))
    filters.push(
      new AvailableLocalizationsFilter({ item: localization, items: localizations, debug })
    )
    filters.push(new LanguagePackageFilter({ languagePackageIdentifier, selectedLanguage, debug }))
    filters.push(new ProductFilter({ item: product, items: products, debug }))
    filters.push(new KeywordsOneFilter({}))
    filters.push(new KeywordsTwoFilter({}))
    filters.push(new IdFilter({ item: hwid, items: hwids }))
    filters.push(new DisplayLanguageFilter({ item: displayLanguage, debug }))

    this.filters = filters
    this.debug = !!settings.debug
    this.build()
  }

  public reset(attributeLabel?: string) {
    if (attributeLabel) {
      this.filters.forEach(filter => {
        if (
          filter.attributeLabel === attributeLabel ||
          filter.attributeLabels?.includes(attributeLabel)
        ) {
          filter.reset(attributeLabel)
        }
      })
    } else {
      this.aisFilterString = ''
      this.aisFilterList = []
      this.filters.forEach(filter => {
        filter.reset()
      })
    }

    this.build()
  }

  public initialize(discoFacets: DiscoFacet[]) {
    this.reset()
    discoFacets.forEach(discoFacet => {
      this.update(discoFacet)
    })
  }

  public merge(
    mergeFilters?: (string | number | undefined)[] | string,
    mergeAttributes?: string[] | undefined,
    replaceAttributes?: string[] | undefined
  ) {
    // Duplicate attributes causes problems
    if (Array.isArray(mergeAttributes) && Array.isArray(replaceAttributes)) {
      const duplicates = mergeAttributes.filter(attr => {
        return replaceAttributes.includes(attr)
      })
      if (duplicates.length > 0) {
        throw new Error(
          `Duplicate attributes found in merge attributes and replace attributes: '${duplicates.join(
            "', '"
          )}`
        )
      }
    }

    // Add merge attributes with existing attributes
    if (Array.isArray(mergeFilters) && Array.isArray(mergeAttributes)) {
      mergeFilters.forEach(mergeFilter => {
        const { attribute: mergeAttribute, label: mergeLabel } = filterLabelParse(mergeFilter)
        const filterIsActive = this.testFilterActive(mergeAttribute, mergeLabel)
        if (!filterIsActive && mergeAttributes.includes(mergeAttribute)) {
          this.debug && console.info('adding', mergeAttribute, mergeLabel)
          const discoFacet: DiscoFacet = {
            attributes: [mergeAttribute],
            values: [mergeLabel],
            joiner: DiscoJoiner.OR,
          }
          this.add(discoFacet)
        }
      })
    }

    if (Array.isArray(mergeFilters) && Array.isArray(replaceAttributes)) {
      // Remove filters missing from merge filters
      replaceAttributes.forEach(requiredAttribute => {
        this.debug && console.info('removing', requiredAttribute)
        this.reset(requiredAttribute)
      })

      // Add new merge filters
      mergeFilters.forEach(mergeFilter => {
        const { attribute: mergeAttribute, label: mergeLabel } = filterLabelParse(mergeFilter)
        if (replaceAttributes.includes(mergeAttribute)) {
          this.debug && console.info('replacing', mergeAttribute, mergeLabel)
          const discoFacet: DiscoFacet = {
            attributes: [mergeAttribute],
            values: [mergeLabel],
            joiner: DiscoJoiner.OR,
          }
          this.add(discoFacet)
        }
      })
    }
  }

  public setDebug(debugOn: boolean) {
    this.debug = debugOn
  }

  public add(discoFacet: DiscoFacet) {
    this.update(discoFacet, Modifier.Add)
  }

  public remove(discoFacet: DiscoFacet) {
    this.update(discoFacet, Modifier.Remove)
  }

  public update(discoFacet: DiscoFacet, modifier?: 'add' | 'remove' | undefined) {
    discoFacet.attributes.forEach(attribute => {
      if (!attribute) {
        throw new Error(
          `Update failed - no attribute supplied for label(s): '${discoFacet.values}'`
        )
      }

      const isAgeAttribute = [attributeLabels.leftAge, attributeLabels.rightAge].includes(attribute)

      const isLanguagePackageAttribute = [
        attributeLabels.languagePackage,
        attributeLabels.selectedLanguage,
      ].includes(attribute)

      if (
        !discoFacet.values &&
        discoFacet.values !== 0 &&
        !(isAgeAttribute || modifier === Modifier.Remove)
      ) {
        throw new Error(`No label for attribute: '${attribute}'`)
      }

      if (!isValidAttribute(attribute)) {
        throw new Error(`Update failed - invalid attribute: ${attribute}`)
      }

      let found = false
      this.filters.forEach(filter => {
        if (
          !found &&
          (filter.attributeLabel === attribute ||
            (Array.isArray(filter.attributeLabels) && filter.attributeLabels.includes(attribute)))
        ) {
          found = true

          discoFacet.values.forEach(label => {
            if (modifier === Modifier.Add) {
              if (isAgeAttribute || isLanguagePackageAttribute) {
                filter.setItem(label, attribute)
              } else if (!filter.items.includes(label)) {
                filter.setItem(label, attribute)
              }
            } else if (modifier === Modifier.Remove) {
              if (isAgeAttribute || isLanguagePackageAttribute) {
                filter.reset(attribute)
              } else if (filter.items.includes(label)) {
                filter.setItem(label, attribute)
              }
            } else {
              filter.setItem(label, attribute)
            }
          })
        }
      })
      if (!found) {
        console.error('No case with attribute: ', attribute)
      }
      this.build()
    })
  }

  testFilterActive(attribute: string | undefined, label?: string | number | undefined): boolean {
    let found = false
    this.filters.forEach(filter => {
      if (filter)
        if (
          !found &&
          (filter.attributeLabel === attribute ||
            (Array.isArray(filter.attributeLabels) && filter.attributeLabels.includes(attribute)))
        ) {
          if (attributeLabels.leftAge === attribute) {
            found = found || filter.leftAge > minUserAge
          } else if (attributeLabels.rightAge === attribute) {
            found = found || filter.rightAge < maxUserAge
          } else if (attributeLabels.languagePackage === attribute) {
            found = found || filter.languagePackageIdentifier === label
          } else if (attributeLabels.selectedLanguage === attribute) {
            found = found || filter.selectedLanguage === label
          } else if (filter.items && label) {
            found = found || filter.items.includes(label)
          } else if (!label) {
            found = found || filter.items.length > 0
          }
        }
    })

    return found
  }

  build() {
    const nonConceptFilterStrings: string[] = []
    let conceptFilterString = ''
    let aisFilterList: string[] = []

    this.filters.forEach(filter => {
      const filterString = filter.getFilterString()
      if (filterString) {
        if (filter.attributeLabel === attributeLabels.concepts) {
          conceptFilterString = conceptFilterString
            ? `${conceptFilterString} AND ${filterString}`
            : filterString
        } else {
          nonConceptFilterStrings.push(filterString)
        }
      }

      const labelList = filter.getLabelList()
      if (Array.isArray(labelList)) {
        aisFilterList = aisFilterList.concat(labelList)
      } else if (labelList && typeof labelList === 'string') {
        aisFilterList.push(labelList)
      }
    })

    this.aisFilterList = aisFilterList
    this.aisFilterString = conceptFilterString

    const nonConceptFilter = nonConceptFilterStrings.join(DiscoJoiner.AND)
    if (nonConceptFilter) {
      if (conceptFilterString) {
        this.aisFilterString = `${conceptFilterString} AND ${nonConceptFilter}`
      } else {
        this.aisFilterString = nonConceptFilter
      }
    }

    this.isInDefaultState = areFiltersInDefaultState(aisFilterList)
    this.activeFilterCount = getActiveFilterCount(aisFilterList)

    const optionalFiltersToIgnore = [
      `${attributeLabels.leftAge}:${minUserAge}`,
      `${attributeLabels.rightAge}:${maxUserAge}`,
    ]

    this.optionalFilterList = this.aisFilterList.filter(
      filterString => !optionalFiltersToIgnore.includes(filterString)
    )

    if (this.debug) {
      console.info({
        aisFilterList: this.aisFilterList,
        optionalFilterList: this.optionalFilterList,
        aisFilterString: this.aisFilterString,
        isInDefaultState: this.isInDefaultState,
        activeFilterCount: this.activeFilterCount,
      })
    }

    if (typeof this.onChange === 'function') {
      this.onChange({
        aisFilterList: this.aisFilterList,
        optionalFilterList: this.optionalFilterList,
        aisFilterString: this.aisFilterString,
        isInDefaultState: this.isInDefaultState,
        activeFilterCount: this.activeFilterCount,
      })
    }
  }
}

class LanguagePackageFilter {
  debug = false
  attributeLabels: (string | undefined)[]
  languagePackageIdentifier: string = ''
  selectedLanguage: string = ''
  constructor(settings: LanguagePackageFilterSettingsType) {
    this.debug = !!settings.debug
    this.attributeLabels = [attributeLabels.languagePackage, attributeLabels.selectedLanguage]
  }

  setItem(value: string | undefined, attribute: string | undefined) {
    if (attribute === attributeLabels.languagePackage) {
      this.languagePackageIdentifier = value ?? ''
    }

    if (attribute === attributeLabels.selectedLanguage) {
      this.selectedLanguage = value ?? ''
    }
  }

  getLabelList() {
    return [
      this.selectedLanguage &&
        filterLabelCompose(attributeLabels.selectedLanguage, this.selectedLanguage),
      this.languagePackageIdentifier &&
        filterLabelCompose(attributeLabels.languagePackage, this.languagePackageIdentifier),
    ].filter(Boolean)
  }

  reset(attribute?: string | undefined) {
    if (attribute === attributeLabels.languagePackage) {
      this.languagePackageIdentifier = ''
    } else if (attribute === attributeLabels.selectedLanguage) {
      this.selectedLanguage = ''
    }
  }

  getFilterString() {
    return (
      (this.languagePackageIdentifier &&
        this.selectedLanguage &&
        `(${attributeLabels.languagePackage}.${
          this.languagePackageIdentifier
        }:'${this.selectedLanguage?.toString().replace(/[']/g, `\\'`)}')`) ??
      ''
    )
  }
}

class AgeFilter {
  debug = false
  attributeLabels: (string | number | undefined)[]
  leftAge = minUserAge
  rightAge = maxUserAge

  constructor(settings: AgeFilterSettingsType) {
    this.debug = !!settings.debug
    this.attributeLabels = [attributeLabels.rightAge, attributeLabels.leftAge]
    this.setItem(
      ((settings.age as number) || -1) >= 0
        ? settings.age
        : ((settings.rightAge as number) || -1) >= 0
        ? settings.rightAge
        : maxUserAge,
      attributeLabels.rightAge
    )
    this.setItem(
      settings.age || -1 >= 0
        ? settings.age
        : settings.leftAge || -1 >= 0
        ? settings.leftAge
        : minUserAge,
      attributeLabels.leftAge
    )
  }

  setItem(age: number | string | undefined, attribute: string | undefined) {
    age = typeof age === 'number' ? age : parseInt(age || '0', 10)
    const ageKey = attribute === attributeLabels.rightAge ? 'rightAge' : 'leftAge'
    if (age >= minUserAge && age <= maxUserAge) {
      this[ageKey] = age
    } else if (age < minUserAge) {
      this[ageKey] = minUserAge
    } else if (age > maxUserAge) {
      this[ageKey] = maxUserAge
    }
  }

  getFilterString() {
    const filterStrings = []
    const isLeftAgeLegit = typeof this.leftAge === 'number' && this.leftAge !== minUserAge
    const isRightAgeLegit = typeof this.rightAge === 'number' && this.rightAge !== maxUserAge

    if (isLeftAgeLegit) {
      filterStrings.push(`${attributeLabels.leftAge} >= ${this.leftAge}`)
    }
    if (isRightAgeLegit) {
      filterStrings.push(`${attributeLabels.rightAge} <= ${this.rightAge}`)
    }

    if (filterStrings.length > 0) {
      return `(${filterStrings.join(DiscoJoiner.AND)})`
    }

    return ''
  }

  getLabelList() {
    const labelList = []
    if (typeof this.leftAge === 'number') {
      labelList.push(filterLabelCompose(attributeLabels.rightAge, this.rightAge))
    }
    if (typeof this.rightAge === 'number') {
      labelList.push(filterLabelCompose(attributeLabels.leftAge, this.leftAge))
    }

    return labelList
  }

  reset(attribute?: string | number | undefined) {
    if (attribute === attributeLabels.rightAge) {
      this.rightAge = maxUserAge
    } else if (attribute === attributeLabels.leftAge) {
      this.leftAge = minUserAge
    } else {
      this.leftAge = minUserAge
      this.rightAge = maxUserAge
    }
  }
}

class BaseFilter {
  item: string | number | undefined
  items: (string | number | undefined)[]
  attributeLabel: string | undefined

  constructor(settings: BaseFilterType = {}) {
    this.items = []
    if (Array.isArray(settings.items)) {
      this.setItem(settings.items)
    } else {
      this.setItem(settings.item)
    }
  }

  setItem(itemsCandidate: (string | number | undefined)[] | string | number | undefined) {
    if (itemsCandidate || itemsCandidate === 0) {
      const items = Array.isArray(itemsCandidate) ? itemsCandidate : [itemsCandidate]
      items.forEach(ic => {
        const item = this.normalize(ic)
        const index = this.items.findIndex(storedItem => storedItem === item)
        if (index === -1) {
          this.items.push(item)
        } else {
          this.items.splice(index, 1)
        }
      })
    }
  }

  normalize(itemsCandidate: string | number | undefined): string | number | undefined {
    return itemsCandidate
  }

  reset() {
    this.items = []
  }

  getFilterString() {
    return buildOrFilterString(this.items, this.attributeLabel)
  }

  getLabelList() {
    return getFilterLabelsList(this.items, this.attributeLabel)
  }
}

class SexFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.sex
  }

  normalize(itemsCandidate: string | number | undefined): string | number | undefined {
    const candidate = `${itemsCandidate}`.toLowerCase()
    if (candidate.startsWith('m')) {
      return 'Male'
    }
    if (candidate.startsWith('f')) {
      return 'Female'
    }
    return itemsCandidate
  }
}

class ConceptFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.concepts
  }

  getFilterString() {
    const concepts: string[] = []
    this.items.forEach(item =>
      concepts.push(
        `${attributeLabels.concepts}:'${item?.toString().replace(/[']/g, `\\'`)}'${DiscoJoiner.OR}${
          attributeLabels.keywords1
        }:'${item?.toString().replace(/[']/g, `\\'`)}'${DiscoJoiner.OR}${
          attributeLabels.keywords2
        }:'${item?.toString().replace(/[']/g, `\\'`)}'`
      )
    )
    return concepts.length > 0 ? `(${concepts.join(DiscoJoiner.OR)})` : ''
  }
}

class TypeFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.contentType
  }
}

class DeliveryFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.delivery
  }
}

class AudienceFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.audience
  }
}

class SpecialtyFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.specialty
  }
}

class AvailableLocalizationsFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.availableLocalizations
  }
}

class DisplayLanguageFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.displayLanguage
  }
}

class ProductFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.product
  }
}

class KeywordsOneFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.keywords1
  }

  // Ignore since we're doing this stuff in the concept filter
  getFilterString() {
    return ''
  }

  getLabelList() {
    return []
  }
}

class KeywordsTwoFilter extends KeywordsOneFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.keywords2
  }
}

class IdFilter extends BaseFilter {
  constructor(settings: BaseFilterType) {
    super(settings)
    this.attributeLabel = attributeLabels.id
  }
}
