import { createFallbackableCache, createNullCache } from '@algolia/cache-common'
import { createInMemoryCache } from '@algolia/cache-in-memory'

import sendAlgoliaEvent from 'search-insights'
import { AlgoliaInsightEventData } from '../../types/AlgoliaInsightEventData'
import env from '@beam-australia/react-env'
import algoliasearch from 'algoliasearch'

enum InsightsEventTypes {
  click = 'clickedObjectIDs',
  view = 'viewedObjectIDs',
  conversion = 'convertedObjectIDs',
  afterClick = 'clickedObjectIDsAfterSearch',
  afterConversion = 'convertedObjectIDsAfterSearch',
  filterViewed = 'viewedFilters',
  filterClicked = 'clickedFilters',
}

export enum InsightEventNames {
  basketAdd = 'Add To Basket',
  basketRemove = 'Remove From Basket',
  preview = 'Item Previewed',
  assigned = 'Item Assigned',
  favorited = 'Item Favorited',
  unfavorited = 'Item Unfavorited',
}

type AlgoliaEventData = {
  userToken: string
  index: string
  objectIDs: string[]
  positions?: number[]
  queryId?: string
  eventName: InsightEventNames
}

const eventTypesRequiteQueryId = [InsightsEventTypes.afterClick, InsightsEventTypes.afterConversion]
const AlgoliaApplicationName = 'adviseclinical'
export const initSearchEvent = (appId: string, apiKey: string, userToken: string) => {
  sendAlgoliaEvent('init', {
    appId: appId,
    apiKey: apiKey,
    userToken,
  })
}

export const sendSearchEvent = (
  eventName: InsightEventNames,
  algoliaInsightEventData: AlgoliaInsightEventData
) => {
  let insightsEventType = mapInsightEventNamesToAlgoliaTypes(eventName)

  let requiresQueryId = eventTypesRequiteQueryId.includes(insightsEventType)
  let hasQueryIdIfNeeded = !requiresQueryId || (algoliaInsightEventData.queryId && requiresQueryId)

  // Normalize click and conversion events to fit the data
  const hasDefinedPositions =
    algoliaInsightEventData.positions && algoliaInsightEventData.positions.length > 0
  if (algoliaInsightEventData.queryId && hasDefinedPositions) {
    if (insightsEventType === InsightsEventTypes.click) {
      insightsEventType = InsightsEventTypes.afterClick
      requiresQueryId = true
      hasQueryIdIfNeeded = true
    } else if (insightsEventType === InsightsEventTypes.conversion) {
      insightsEventType = InsightsEventTypes.afterConversion
      requiresQueryId = true
      hasQueryIdIfNeeded = true
    }
  } else {
    if (insightsEventType === InsightsEventTypes.afterClick) {
      insightsEventType = InsightsEventTypes.click
      requiresQueryId = false
      hasQueryIdIfNeeded = true
    } else if (insightsEventType === InsightsEventTypes.afterConversion) {
      insightsEventType = InsightsEventTypes.conversion
      requiresQueryId = false
      hasQueryIdIfNeeded = true
    }
  }

  let eventData: AlgoliaEventData = {
    userToken: algoliaInsightEventData.userToken,
    index: algoliaInsightEventData.index,
    eventName: eventName,
    objectIDs: algoliaInsightEventData.objectIDs,
  }

  if (requiresQueryId && algoliaInsightEventData.queryId) {
    eventData.queryId = algoliaInsightEventData.queryId
    if (hasDefinedPositions && insightsEventType !== InsightsEventTypes.afterConversion) {
      eventData.positions = algoliaInsightEventData.positions
    }
  }

  if (eventName && eventData.objectIDs.length > 0 && hasQueryIdIfNeeded) {
    sendAlgoliaEvent(insightsEventType, eventData)
  }
}
function mapInsightEventNamesToAlgoliaTypes(eventName: InsightEventNames): InsightsEventTypes {
  switch (eventName) {
    case InsightEventNames.assigned:
    case InsightEventNames.basketAdd:
    case InsightEventNames.favorited:
    case InsightEventNames.unfavorited:
      return InsightsEventTypes.conversion
    case InsightEventNames.preview:
      return InsightsEventTypes.view
    case InsightEventNames.basketRemove:
      return InsightsEventTypes.click
  }
}

export function analyticsTags(): String[] {
  let environmentName = env('ENVIRONMENT') ?? 'dev'
  return [`environment:${environmentName.toLowerCase()}`, `application:${AlgoliaApplicationName}`]
}

export const setAlgoliaSearchClient = (appId: string, queryKey: string) => {
  return algoliasearch(appId, queryKey, {
    hostsCache: createFallbackableCache({
      caches: [createInMemoryCache(), createNullCache()],
    }),
  })
}
