import { useEffect, useState } from 'react'
import afterFrame from 'afterframe'

import { appInsights } from 'services/AppInsights'
import {
  fetchFhir,
  fetchLaunchContextData,
  fetchNullLaunchStatusUrl,
  fetchPlatform,
} from 'services/apis/launch.api'
import { fetchDiscoveryV4 } from 'services/apis/disco.api'
import { EventPayloadActionEnum, useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { FavoritesActionEnum, useFavorites } from 'services/context/FavoritesContext'
import { PatientActionEnum, usePatient } from 'services/context/PatientContext'
import { PractitionerActionEnum, usePractitioner } from 'services/context/PractitionerContext'
import { initSearchEvent, setAlgoliaSearchClient } from 'services/utility/algoliainsight.util'
import { createEventPayloadContextFromLaunch } from 'services/utility/events/bee/event.util'
import { getUniqueFavoritesList } from 'services/utility/favorites.util'
import { Algolia, INITIAL_ALGOLIA } from 'types/Algolia'
import { DiscoIndexResult, DiscoServiceResult } from 'types/DiscoTypes'
import { LaunchContext } from 'types/LaunchContextTypes'
import { uuid } from 'services/utility/uuid.util'
import { ResultSection } from 'types/ResultSections'
import { ResultsActionEnum, useResults } from 'services/context/ResultsContext'
import { LaunchActionEnum, useLaunchContext } from 'services/context/LaunchContext'
import { LanguageActionTypes, useSearchLanguage } from 'services/context/SearchLanguageContext'
import { getAlgoliaLanguagePackageId } from 'services/utility/common'
import {
  ConfigurationActionEnum,
  useClientConfigurations,
} from 'services/context/ClientConfigurationContext'
import {
  getQueryParams,
  addSessionId,
  getResultSectionsFromDiscoResult,
  startFetchContextEventTracking,
  startLaunchEventTracking,
  stopFetchContextEventTracking,
  setPatient,
  startInitializePractitionerEventTracking,
  stopInitializePractitionerEventTracking,
  validateLaunchData,
  trackUsernameMismatchEvent,
} from 'services/utility/launch.util'
import { setConfigurationsFromLaunch } from 'services/utility/configuration.util'
import { initializePractitioner } from 'services/apis/practitioner.api'
import { FhirResourceQuery, FhirResourceQueryStatus, FhirResourceTypes } from 'types/Fhir'
import { trackErrorToAppInsights } from 'services/utility/error.util'

const LOCALE = 'default' // Use Disco.API default locale for now

let searchQueryKey: string = ''
let searchAppId: string = ''
let searchIndexes: {
  [key: string]: DiscoIndexResult
}
const SESSION_ID = uuid()

const NULL_LAUNCH_ERROR = 'Launch error, no status url.'
const DISCO_ERROR = 'Cannot reach discovery API'
const PRACTITIONER_ERROR = 'Cannot initialize practitioner'

export function useLaunch() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [algolia, setAlgolia] = useState<Algolia>(INITIAL_ALGOLIA)
  const [launch, setLaunch] = useState<LaunchContext>({} as LaunchContext)
  const { dispatch: eventPayloadDispatch } = useEventPayloadContext()
  const { dispatch: patientDispatch } = usePatient()
  const { dispatch: favoritesDispatch } = useFavorites()
  const { dispatch: practitionerDispatch } = usePractitioner()
  const { dispatch: clientConfigurationDispatch } = useClientConfigurations()
  const { dispatch: ResultsDispatch } = useResults()
  const { dispatch: LaunchDispatch } = useLaunchContext()
  const { dispatch: searchLanguageDispatch } = useSearchLanguage()

  useEffect(() => {
    const fetchAsync = async () => {
      startLaunchEventTracking()
      let { statusUrl, cacheId, testContext /* , use */ } = getQueryParams(window.location.search)
      const launchUsingCache = cacheId ? true : false
      // TODO: awaiting validation
      // const useSSO = use === 'SSO'
      if (testContext !== '') {
        statusUrl = await fetchNullLaunchStatusUrl(testContext, SESSION_ID)
        if (statusUrl === '') {
          setIsLoading(false)
          trackErrorToAppInsights(NULL_LAUNCH_ERROR, 'Null launch error')
          setError(NULL_LAUNCH_ERROR)
          return
        }
      }

      let launch = {} as LaunchContext
      if (launchUsingCache) {
        const excludeFhirResources: FhirResourceQuery = {
          fhirTypes: [
            FhirResourceTypes.LOCATION,
            FhirResourceTypes.DEPARTMENT,
            FhirResourceTypes.PRACTITIONER_ROLE /* , TODO: awaiting validation FhirResourceTypes.PRACTITIONER */,
          ],
          status: FhirResourceQueryStatus.EXCLUDE,
        }
        // TODO: awaiting validation that values are the same between fhirPractitioner.name and sso.PractitionerName
        // then we can enable this behavior when an SSO was found
        // if(useSSO) {
        // startFetchContextEventTracking()

        // const platformAndPrac = async () => {
        //   const platformResponse = await fetchPlatform(cacheId)
        //   const { hwOrganization, hwAccessToken, ehrUserName, ehrUserId } = platformResponse

        //   startInitializePractitionerEventTracking()
        //   const practitionerResponse = await initializePractitioner(ehrUserId, hwOrganization.id, ehrUserName, hwOrganization.name, hwAccessToken)
        //   stopInitializePractitionerEventTracking(
        //     launch.contextSessionId,
        //     launch?.location?.id as string
        //   )

        //   return { platformResponse, practitionerResponse }
        // }
        // const [ fhirRes, platformAndPracRes ] = await Promise.all([fetchFhir(cacheId, excludeFhirResources),platformAndPrac()])
        // stopFetchContextEventTracking(SESSION_ID, launch?.location?.id ?? '')
        // const { platformResponse, practitionerResponse } = platformAndPracRes
        // const practitioner : Practitioner = {
        //   id: practitionerResponse.practitionerId,
        //   folders: practitionerResponse.folders,
        //   ehrUserId: platformResponse.ehrUserId,
        //   name: platformResponse.ehrUserName
        // }

        // launch = {...fhirRes, practitioner, ...platformResponse  }
        // launch.contextSessionId = SESSION_ID
        // } else {
        startFetchContextEventTracking()
        const [fhir, platform] = await Promise.all([
          fetchFhir(cacheId, excludeFhirResources),
          fetchPlatform(cacheId),
        ])
        stopFetchContextEventTracking(SESSION_ID, launch?.location?.id ?? '')

        launch = { ...fhir, ...platform }
        launch.error = fhir.error || platform.error ? true : false
        launch.contextSessionId = SESSION_ID
        // TODO: awaiting validation re: sso userName and fhir practitioner name being the same
        // then we do more than trackEvent
        if (
          platform?.ehrUserName &&
          fhir?.practitioner?.name &&
          platform?.ehrUserName !== fhir?.practitioner?.name
        ) {
          trackUsernameMismatchEvent(
            platform?.ehrUserName,
            fhir?.practitioner?.name,
            SESSION_ID,
            launch?.location?.id
          )
        }
        // }
      } else {
        launch = await fetchLaunchContextData(statusUrl, SESSION_ID)
      }

      const err = validateLaunchData(launch)
      if (err) {
        setError(err)
        setIsLoading(false)
        trackErrorToAppInsights(err, 'Validate launch')
        stopFetchContextEventTracking(SESSION_ID, '')
        return
      }

      // configuration setup
      addSessionId(SESSION_ID)

      const clientConfiguration = setConfigurationsFromLaunch(launch)
      const patient = setPatient(launch.patient)

      const algoliaLanguagePackageIdentifier = getAlgoliaLanguagePackageId(
        launch.entitlement.languagePackageAttribute
      )
      searchAppId = launch.entitlement.appId
      searchQueryKey = launch.entitlement.queryKey
      searchIndexes = launch.entitlement.indexes

      let disco: DiscoServiceResult
      disco = await fetchDiscoveryV4(launch, searchAppId, clientConfiguration.hwAccessToken)

      if (disco.error) {
        setError(DISCO_ERROR)
        trackErrorToAppInsights(DISCO_ERROR, 'Disco fetch error')
        setIsLoading(false)
        return
      }

      let uniqueFavorites: string[] = []
      if (launchUsingCache) {
        // TODO: awaiting validation that we can rely on the SSO token name
        // if (!useSSO) {
        let favoritesByPractitionerResponse
        startInitializePractitionerEventTracking()
        favoritesByPractitionerResponse = await initializePractitioner(
          launch.practitioner.ehrUserId,
          launch.hwOrganization.id,
          clientConfiguration.hwAccessToken,
          launch.practitioner.name,
          launch.hwOrganization.name
        )
        stopInitializePractitionerEventTracking(
          launch.contextSessionId,
          launch?.location?.id as string
        )

        if (favoritesByPractitionerResponse.error) {
          setIsLoading(false)
          setError(PRACTITIONER_ERROR)
          trackErrorToAppInsights(PRACTITIONER_ERROR, 'Initialize Practitioner Error')
        }

        launch.practitioner.folders = favoritesByPractitionerResponse.folders
        launch.practitioner.id = favoritesByPractitionerResponse.practitionerId
        uniqueFavorites = getUniqueFavoritesList(favoritesByPractitionerResponse.folders)
        // }
      } else {
        uniqueFavorites = getUniqueFavoritesList(launch.practitioner?.folders ?? [])
      }
      const userToken = launch.practitioner.id
      initSearchEvent(searchAppId, searchQueryKey, userToken)
      const resultSections: ResultSection[] = getResultSectionsFromDiscoResult(
        disco,
        clientConfiguration.collapseAccordions
      )

      setAlgolia({
        client: setAlgoliaSearchClient(searchAppId, searchQueryKey),
        state: {
          key: { QueryAppId: searchAppId, QueryKey: searchQueryKey },
          appId: searchAppId ?? '',
          index: searchIndexes[LOCALE].wordsIndex,
          suggestions: searchIndexes[LOCALE].suggestionsIndex,
          userToken,
          languagePackageIdentifier: algoliaLanguagePackageIdentifier,
        },
      })

      setLaunch(launch)

      clientConfigurationDispatch({
        type: ConfigurationActionEnum.SET_CONFIGURATION,
        data: clientConfiguration,
      })
      eventPayloadDispatch({
        type: EventPayloadActionEnum.SET_EVENT_PAYLOAD,
        data: createEventPayloadContextFromLaunch(launch),
      })

      searchLanguageDispatch({
        type: LanguageActionTypes.SET_AVAILABLE_LANGUAGES,
        data: launch.entitlement.availableLanguages,
      })

      patientDispatch({ type: PatientActionEnum.SET_PATIENT, data: patient })
      ResultsDispatch({ type: ResultsActionEnum.SET_RESULTS, data: resultSections })
      LaunchDispatch({ type: LaunchActionEnum.SET_LAUNCH, data: launch })
      practitionerDispatch({
        type: PractitionerActionEnum.SET_PRACTITIONER,
        data: launch.practitioner,
      })

      favoritesDispatch({
        type: FavoritesActionEnum.SET_FAVORITES,
        data: launch.practitioner?.folders ?? [],
      })
      favoritesDispatch({
        type: FavoritesActionEnum.SET_UNIQUE_FAVORITES,
        data: uniqueFavorites,
      })
      afterFrame(() => {
        appInsights?.stopTrackEvent(`Launch::Launch`, {
          sessionId: launch.contextSessionId,
          locationId: launch?.location?.id as string,
        })
      })

      setIsLoading(false)
    }

    if (!error && isLoading) {
      fetchAsync()
    }
  }, [
    setAlgolia,
    patientDispatch,
    favoritesDispatch,
    searchLanguageDispatch,
    practitionerDispatch,
    eventPayloadDispatch,
    clientConfigurationDispatch,
    setError,
    isLoading,
    error,
    ResultsDispatch,
    LaunchDispatch,
  ])

  return { isLoading, error, algolia, launch }
}
