import { useState, useEffect, FC } from 'react'
import { Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core'
import { useEdit } from 'services/context/EditContext'
import { PopoverActionEnum, usePopover } from 'services/context/PopoverContext'
import { CartActionEnum, useCart } from 'services/context/CartContext'
import { fetchFormattedContentForEdit } from 'services/apis/content.api'
import { EditHeader } from './EditHeader'
import { Edit } from './Edit'
import { AlertDialog } from 'components/Dialog/AlertDialog'
import Button from 'components/Button'
import { EditableContent, EMPTY_EDITABLE_CONTENT } from 'types/Preview'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { useFeatures } from 'services/context/FeatureContext'
import './Edit.scss'

const CONTINUE_EDITING_TEXT = 'Continue Editing'
const REMOVE_TEXT = 'Remove'

type Props = {
  show: boolean
}

export const EditDialog: FC<Props> = ({ show }) => {
  const [editableContent, setEditableContent] = useState<EditableContent>(EMPTY_EDITABLE_CONTENT)
  const [userStartedEdit, setUserStartedEdit] = useState<boolean>(false)
  const { state: editItem } = useEdit()
  const { dispatch: popoverDispatch } = usePopover()
  const { state: clientConfigurations } = useClientConfigurations()
  const { dispatch: cartDispatch } = useCart()
  const [isLoading, setIsLoading] = useState(true)
  const [showUndoDialog, setShowUndoDialog] = useState(false)
  const { state: features } = useFeatures()

  useEffect(() => {
    if (editItem.edited) {
      setEditableContent(editItem.editedContent)
      setIsLoading(false)
    } else {
      if (editItem.hwid && isLoading) {
        fetchFormattedContentForEdit(
          editItem.hwid,
          clientConfigurations['hwAccessToken'],
          editItem.localization.key,
          clientConfigurations['customLogoUri']
        )
          .then(data => {
            setEditableContent(data)
          })
          .catch(err => {
            console.error('Error fetching content ', err)
          })
          .finally(() => setIsLoading(false))
      }
    }
  }, [
    editItem.hwid,
    editItem.localization.key,
    editItem.edited,
    editItem.editedContent,
    isLoading,
    clientConfigurations,
    features,
  ])

  const handleUndoEdits = () => {
    setShowUndoDialog(true)
  }

  const handleContentChange = (content: string) => {
    const item = { ...editableContent }
    item.article = content
    setEditableContent(item)
    setUserStartedEdit(true)
  }

  const closeModal = () => {
    popoverDispatch({ type: PopoverActionEnum.CLEAR_POPOVER })
  }

  const cancelUndoEdit = () => {
    setShowUndoDialog(false)
  }

  const clearEdits = () => {
    setEditableContent(EMPTY_EDITABLE_CONTENT)
    setShowUndoDialog(false)
    cartDispatch({
      type: CartActionEnum.UPDATE_ITEM,
      data: setCartItem(false, EMPTY_EDITABLE_CONTENT),
    })
    closeModal()
  }

  function handleSave() {
    cartDispatch({ type: CartActionEnum.UPDATE_ITEM, data: setCartItem(true, editableContent) })
    closeModal()
  }

  const setCartItem = (edited: boolean, content: EditableContent) => {
    const editedItem = { ...editItem }
    editedItem.editedContent = content
    editedItem.edited = edited
    return editedItem
  }

  const allowSave = editableContent.article !== ''

  return (
    <Dialog
      className='edit'
      data-testid='edit-wrapper'
      open={show}
      scroll='paper'
      fullWidth={true}
      maxWidth={'lg'}
    >
      <DialogTitle>
        <EditHeader
          title={editItem.title}
          showEditControls={userStartedEdit}
          showUndo={editItem.edited || userStartedEdit}
          allowSave={allowSave}
          save={handleSave}
          undoAll={handleUndoEdits}
          close={closeModal}
        />
        <Divider />
      </DialogTitle>
      <DialogContent className='edit-dialog-content'>
        <AlertDialog
          show={showUndoDialog}
          title={'Remove Edits?'}
          message={'All of your changes will be removed.'}
        >
          <Button
            aria-label={CONTINUE_EDITING_TEXT}
            rounded
            borderless
            neutral
            small
            type='button'
            onClick={cancelUndoEdit}
          >
            {CONTINUE_EDITING_TEXT}
          </Button>
          <Button
            aria-label={REMOVE_TEXT}
            rounded
            borderless
            accent
            small
            type='button'
            onClick={clearEdits}
          >
            {REMOVE_TEXT}
          </Button>
        </AlertDialog>
        <Edit
          isLoading={isLoading}
          content={editableContent.article}
          handleContentChange={handleContentChange}
        />
      </DialogContent>
    </Dialog>
  )
}
