import { CartItem } from './Cart'
export interface Preview {
  previewId: string
  previewTitle: string
  previewLanguageKey: string
  previewType: string
  show: boolean
  previewActions: PreviewUserActions[]
  cartItem: CartItem
  editMode: boolean
  contentArtifact?: string
  content?: string
}

export const EMPTY_PREVIEW = {
  previewId: '',
  previewTitle: '',
  previewLanguageKey: '',
  previewType: '',
  show: false,
  previewActions: [],
  cartItem: {} as CartItem,
  editMode: false,
}

// use these to dictate what actions are available in Preview
export enum PreviewUserActions {
  CART = 'cart',
  FAVORITES = 'favorites',
  EDIT = 'edit',
  //PRINT = 'print'
}

export interface EditableContent {
  article: string
  contentWrapper: string
}

export const EMPTY_EDITABLE_CONTENT = { article: '', contentWrapper: '' }
