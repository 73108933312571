import { sendEvent } from 'services/apis/events.api'
import { EventPayloadContextState } from 'services/context/EventPayloadCtx'
import { LanguageState } from 'services/context/SearchLanguageContext'
import {
  calculatePatientAge,
  deriveElapsedMillisecondsString,
  getUtcDatestampString,
} from 'services/utility/common'
import { IsEnglishLocalization } from 'services/utility/languages.util'
import { uuid } from 'services/utility/uuid.util'
import { PersistMap, AssignmentType, CreateAssignmentResponse } from 'types/Assignment'
import { CartItem } from 'types/Cart'
import { ContentFormat } from 'types/ContentFormat'
import {
  AssignmentCreatedEvent,
  BackEndEventCore,
  BackEndEventType,
  ClinicalLaunchEvent,
  ContentAssignedEvent,
  ContentPersist,
  HistoryViewedEvent,
  ManualSearchEvent,
} from 'types/EventTypes'
import { ContentTypes } from 'types/HwContentTypes'
import { LaunchContext } from 'types/LaunchContextTypes'
import { PatientHistory } from 'types/Patient'

const MAX_POSTAL_CODE_LENGTH = 3
const ALWAYS_MASK_CODES = [
  '036',
  '692',
  '878',
  '059',
  '790',
  '879',
  '063',
  '821',
  '884',
  '102',
  '823',
  '890',
  '203',
  '830',
  '893',
  '556',
  '831',
]
export const DEFAULT_MASK = '000'

export function createCoreEvent(
  EventType: BackEndEventType,
  eventPayloadContext: EventPayloadContextState
): BackEndEventCore {
  return {
    EventType,
    SessionId: eventPayloadContext?.sessionId,
    LaunchCodes: eventPayloadContext?.launchCodes,
    OrganizationId: eventPayloadContext.organizationId,
    OrganizationName: eventPayloadContext.organizationName,
    SubscriptionId: eventPayloadContext.subscriptionId,
    PractitionerFhirId: eventPayloadContext?.practitioner?.ehrUserId,
    PractitionerName: eventPayloadContext?.practitioner?.name,
    PatientPreferredLanguage: fallbackForEmptyEventField(
      eventPayloadContext?.patient?.preferredLanguage,
      'Not Provided'
    ),
    AuthToken: eventPayloadContext?.accessToken,
    PatientId: eventPayloadContext?.patient?.id,
    PractitionerLocation: eventPayloadContext?.location?.name,
    EncounterId: eventPayloadContext?.encounter?.id,
    PractitionerRole: eventPayloadContext?.practitionerRole?.codes?.flatMap(p =>
      p.codes.map(c => c.display)
    ) as string[],
  }
}

export function fallbackForEmptyEventField(eventField: string | undefined, fallback: string) {
  let fixedEventField = eventField ?? fallback

  if (fixedEventField === '') {
    return fallback
  }

  return fixedEventField
}

export function maskPostalCode(input?: string): string {
  const firstThree = input?.slice(0, MAX_POSTAL_CODE_LENGTH)
  if (ALWAYS_MASK_CODES.find(f => f === firstThree)) {
    return DEFAULT_MASK
  }
  return firstThree ?? ''
}

export function sendClinicalLaunchEvent(
  eventPayloadState: EventPayloadContextState,
  initialTimeStamp: number,
  searchLanguage: LanguageState
) {
  const baseEvent = createCoreEvent(BackEndEventType.ClinicalLaunch, eventPayloadState)
  const extendedEvent: ClinicalLaunchEvent = {
    ...baseEvent,
    PatientPostalCode: maskPostalCode(eventPayloadState?.patient?.zipCode),
    PatientAge: deidentifyPatientAge(calculatePatientAge(eventPayloadState?.patient?.dateOfBirth)),
    PatientSex: eventPayloadState?.patient?.gender,
    PatientRace: eventPayloadState?.patient?.race,
    PatientEthnicity: eventPayloadState?.patient?.ethnicity,
    SearchLanguage: searchLanguage?.InitialLanguage?.key,
    DateOfLaunch: getUtcDatestampString(),
    Platform: 'Epic', // TODO: this needs to be dynamic before we have other platforms
    IntegrationType: 'FHIR', // TODO: this needs to be dynamic before we have other integrations
    TimeToFinishLaunch: `${deriveElapsedMillisecondsString(initialTimeStamp)}ms`,
    PractitionerSpecialty: eventPayloadState?.practitionerRole?.specialties?.flatMap(s =>
      s.codes.map(c => c.display)
    ) as string[],
    FacilitySpecialty:
      (eventPayloadState?.location?.medicalSpecialties?.flatMap(m =>
        m.codes.map(c => c.display)
      ) as string[]) || [],
    DepartmentSpecialty:
      (eventPayloadState?.department?.medicalSpecialties?.flatMap(m =>
        m.codes.map(c => c.display)
      ) as string[]) || [],
    EncounterClass: eventPayloadState?.encounter?.class?.display,
    EncounterType: eventPayloadState?.encounter?.type?.flatMap(t =>
      t.codes.map(c => c.display)
    ) as string[],
    Readmission:
      eventPayloadState.encounter?.hospitalizationReadmission &&
      eventPayloadState.encounter?.hospitalizationReadmission !== null
        ? true
        : null,
  }
  sendEvent(extendedEvent)
}

export function sendManualSearchEvent(
  eventPayloadState: EventPayloadContextState,
  currentRefinement: string,
  searchLanguage: LanguageState
) {
  const baseEvent = createCoreEvent(BackEndEventType.ManualSearch, eventPayloadState)
  const extendedEvent: ManualSearchEvent = {
    ...baseEvent,
    PractitionerRole: eventPayloadState?.practitionerRole?.codes?.flatMap(p =>
      p.codes.map(c => c.display)
    ) as string[],
    PractitionerSpecialty: eventPayloadState?.practitionerRole?.specialties?.flatMap(s =>
      s.codes.map(c => c.display)
    ) as string[],
    SearchString: currentRefinement,
    SearchLanguage: searchLanguage.SelectedLanguage.key,
    DateOfSearch: getUtcDatestampString(),
  }
  sendEvent(extendedEvent)
}

export function sendHistoryViewedEvent(eventPayloadState: EventPayloadContextState) {
  const baseEvent = createCoreEvent(BackEndEventType.HistoryViewed, eventPayloadState)
  const extendedEvent: HistoryViewedEvent = {
    ...baseEvent,
    PractitionerSpecialty: eventPayloadState?.practitionerRole?.specialties?.flatMap(s =>
      s.codes.map(c => c.display)
    ) as string[],
    PractitionerRole: eventPayloadState?.practitionerRole?.codes?.flatMap(p =>
      p.codes.map(c => c.display)
    ) as string[],
    DateHistoryViewed: getUtcDatestampString(),
  }
  sendEvent(extendedEvent)
}

export function sendAssignmentCreatedEvent(
  eventPayloadState: EventPayloadContextState,
  selectedItems: CartItem[],
  assignmentBody: PatientHistory,
  assignmentType: AssignmentType,
  pdfs: ContentPersist[],
  htmls: ContentPersist[]
) {
  const baseEvent = createCoreEvent(BackEndEventType.AssignmentCreated, eventPayloadState)
  const extendedEvent: AssignmentCreatedEvent = {
    ...baseEvent,
    PatientAge: deidentifyPatientAge(calculatePatientAge(eventPayloadState?.patient?.dateOfBirth)),
    PatientSex: eventPayloadState?.patient?.gender,
    PatientRace: eventPayloadState?.patient?.race,
    PatientEthnicity: eventPayloadState?.patient?.ethnicity,
    PatientPostalCode: maskPostalCode(eventPayloadState?.patient?.zipCode),
    AssignmentId: assignmentBody.id,
    NumberOfTitlesInAssignment: selectedItems.length,
    AssignmentMethod: { Value: assignmentType },
    NoteIncluded: assignmentBody.notes && assignmentBody.notes.length > 0 ? true : false,
    VideosIncluded: selectedItems?.filter(f => f.type === ContentTypes.VIDEO).length > 0,
    NonEnglishContentIncluded:
      selectedItems?.filter(c => !IsEnglishLocalization(c.localization)).length > 0,
    PractitionerRole: eventPayloadState?.practitionerRole?.codes?.flatMap(p =>
      p.codes.map(c => c.display)
    ) as string[],
    PractitionerSpecialty: eventPayloadState?.practitionerRole?.specialties?.flatMap(s =>
      s.codes.map(c => c.display)
    ) as string[],
    AssignmentCreateDate: getUtcDatestampString(),
    PdfLinks: pdfs,
    HtmlLinks: htmls,
  }
  sendEvent(extendedEvent)
}

export function sendContentAssignedEvent(
  eventPayloadState: EventPayloadContextState,
  assignmentBody: CreateAssignmentResponse,
  item: CartItem
) {
  const baseEvent = createCoreEvent(BackEndEventType.ContentAssigned, eventPayloadState)
  const format: ContentFormat = item.type === ContentTypes.VIDEO ? 'Video' : 'Text'
  const extendedEvent: ContentAssignedEvent = {
    ...baseEvent,
    AssignmentId: assignmentBody.assignment.id,
    ContentIdentifier: item.hwid,
    Title: item.title,
    Version: getContentVersionFromList(assignmentBody.persist, item),
    SearchResultType: item.searchResultType,
    SearchString: item.searchString,
    SearchResultRank: item.searchResultRank,
    Format: { Value: format },
    Edited: item.edited,
    Language: item.localization.key,
    ContentAssignedDate: getUtcDatestampString(),
    PractitionerSpecialty: eventPayloadState?.practitionerRole?.specialties?.map(s => s.text),
    PractitionerRole: eventPayloadState?.practitionerRole?.codes?.flatMap(p =>
      p.codes.map(c => c.display)
    ) as string[],
    Source: item.source,
  }
  sendEvent(extendedEvent)
}

export function deidentifyPatientAge(patientAge: string | undefined): string {
  const PatientAgeToStartDeidentify = 90

  if (!patientAge) {
    return ''
  }

  let numericPatientAge = parseFloat(patientAge)

  if (isNaN(numericPatientAge)) {
    return ''
  }

  if (numericPatientAge >= PatientAgeToStartDeidentify) {
    return `${PatientAgeToStartDeidentify}+`
  }

  return patientAge.toString()
}

export function createEventPayloadContextFromLaunch(
  launchContext: LaunchContext
): EventPayloadContextState {
  const {
    contextSessionId,
    patient,
    organization,
    practitioner,
    location,
    department,
    encounter,
    practitionerRole,
    hwOrganization,
    hwSubscription,
    hwAccessToken,
  } = launchContext
  const launchCodes = Array.prototype.concat(encounter?.diagnoses, encounter?.reasons)
  return {
    sessionId: contextSessionId,
    organization,
    location,
    department,
    practitioner,
    patient,
    launchCodes,
    practitionerRole,
    encounter,
    subscriptionId: hwSubscription?.id ?? uuid(), // TODO: remove this once we are always authing
    organizationId: hwOrganization?.id ?? uuid(), // TODO: remove this once we are always authing
    organizationName: hwOrganization?.name ?? 'No Organization Name Available', // TODO: remove this once we are always authing
    accessToken: hwAccessToken,
  }
}

function getContentVersionFromList(persist: PersistMap, item: CartItem): string {
  return persist.pdf?.filter(f => f.hwid === item.hwid).pop()?.contentVersion ?? ''
}
