import { Chip } from '@material-ui/core'
import { FC } from 'react'
import { Purpose } from 'types/AlgoliaHit'

import './PurposeMetadataChips.scss'

type Props = {
  purposes: Purpose[]
}

export const PurposeMetadataChips: FC<Props> = ({ purposes }) => {
  const renderPurposes = purposes.map((purpose, key) => (
    <Chip key={key} label={purpose.label} size='small' />
  ))

  return (
    <div className='chip' data-testid='purpose-test'>
      {renderPurposes}
    </div>
  )
}
