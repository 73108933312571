import React from 'react'
import { createCtx } from './ContextHelper'
import { Patient, PreviouslyAssignedContent } from 'types/Patient'
import { DEFAULT_LOCALIZATION } from 'services/utility/languages.util'

export const EMPTY_PATIENT: Patient = {
  id: '',
  firstName: '',
  lastName: '',
  age: '',
  gender: '',
  preferredLanguage: '',
  preferredLocalization: DEFAULT_LOCALIZATION,
  history: [],
  dateOfBirth: '',
  medicalRecordNumber: '',
  medicalRecordNumberIdentifierType: '',
  race: '',
  ethnicity: '',
  previouslyAssigned: [],
  zipCode: '',
}

export enum PatientActionEnum {
  SET_PATIENT,
  CLEAR_PATIENT,
  SET_HISTORY,
}

type PatientAction =
  | { type: PatientActionEnum.SET_PATIENT; data: Patient }
  | { type: PatientActionEnum.CLEAR_PATIENT }
  | { type: PatientActionEnum.SET_HISTORY; data: PreviouslyAssignedContent[] }
type PatientContextType = {
  state: Patient
  dispatch: React.Dispatch<PatientAction>
}

const [usePatient, CtxProvider] = createCtx<PatientContextType>('Patient Context')

const PatientContextProvider: React.FC = ({ children }) => {
  function reducer(ctxState: Patient, action: PatientAction): Patient {
    switch (action.type) {
      case PatientActionEnum.SET_PATIENT:
        return { ...ctxState, ...action.data }
      case PatientActionEnum.SET_HISTORY:
        return {
          ...ctxState,
          previouslyAssigned: [...action.data],
        }
      case PatientActionEnum.CLEAR_PATIENT:
        return EMPTY_PATIENT
      default:
        console.warn('Action type not handled in Patient reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, EMPTY_PATIENT)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { PatientContextProvider, usePatient }
