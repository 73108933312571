import { LaunchContext } from 'types/LaunchContextTypes'

export const setConfigurationsFromLaunch = (launch: LaunchContext) => {
  const urlParams = new URLSearchParams(window.location.search)
  const customLogoUriVal = urlParams.get('customLogoUri')

  const clientConfigurations = {
    customLogoUri: customLogoUriVal,
    hwAccessToken: launch.hwAccessToken,
    collapseAccordions: launch.clientConfig.collapseAccordions,
    inHouseContent: launch.clientConfig.inHouseContent,
    allowEdit: launch.clientConfig.allowEdit ?? true,
    useDeliverySettingFilters: launch.clientConfig.useDeliverySettingFilters,
    skipWriteback: launch.clientConfig.skipWriteback ?? false,
    sendMultiplePDFs: launch.clientConfig.sendMultiplePDFs ?? false,
    unassign: launch.clientConfig.unassign ?? false,
  }
  return clientConfigurations
}
