import React, { ComponentType, LazyExoticComponent } from 'react'

// The PreloadableComponent type adds a `preload` method to the LazyExoticComponent type via union
type PreloadableComponent<T extends ComponentType<any>> = LazyExoticComponent<T> & {
  preload: () => Promise<void>
}

// ComponentType type takes a factory method which returns a Promise with a Component (the import)
// it returns a PeeloadableComponent
function lazyWithPreload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> {
  const Component: Partial<PreloadableComponent<T>> = React.lazy(factory)

  // set the preload method of the PreloadableComponent to the resolved promise from the factory method of the ComponentType (the import)
  Component.preload = async () => {
    await factory()
  }

  return Component as PreloadableComponent<T>
}

export default lazyWithPreload
