import { DiscoFacet } from 'types/DiscoTypes'

export const minUserAge = 0
export const maxUserAge = 110

export type FilterPropertiesType = {
  aisFilterString: string
  aisFilterList: string[]
  aisOptionalList: string[]
  isInDefaultState: boolean
  activeFilterCount: number
}

export type FilterHandlerType = {
  initialize: (facets: DiscoFacet[]) => void
  merge: (
    filters?: (string | number | undefined)[] | string,
    mergeAttributes?: string[] | undefined,
    replaceAttributes?: string[] | undefined
  ) => void
  add: (facet: DiscoFacet) => void
  remove: (facet: DiscoFacet) => void
  toggle: (facet: DiscoFacet) => void
  reset: (attributeLabel?: string | undefined) => void
  isActive: (attribute: string | undefined, labelsCandidate: string | number | undefined) => boolean
  setDebug: (debugOn: boolean) => void
}

export const attributeLabels: { [key: string]: string } = {
  rightAge: 'content.minAge',
  leftAge: 'content.maxAge',
  sex: 'content.gender',
  concepts: 'concept.label',
  childConcepts: 'concept.taxonomy.children.label',
  parentConcepts: 'concept.taxonomy.parents.label',
  contentType: 'content.type',
  specialty: 'concept.specialties',
  delivery: 'content.delivery',
  keywords1: 'concept.keywords.r1',
  keywords2: 'concept.keywords.r2',
  audience: 'content.audience',
  availableLocalizations: 'content.availableLocalizations',
  product: 'content.product',
  id: 'content.hwid',
  displayLanguage: 'content.localization',
  languagePackage: 'content.languagePackage',
  selectedLanguage: 'content.selectedLanguage',
}

export const filterManagerIds: { [key: string]: string } = {
  global: 'GLOBAL_FILTERS',
  optional: 'OPTIONAL_FILTERS',
  launch: 'LAUNCH_FILTERS',
  favorites: 'FAVORITES_FILTERS',
}
