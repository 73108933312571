import { FC } from 'react'
import CKContentEditor from 'components/ContentEditor/CKContentEditor'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'

import './Edit.scss'

type Props = {
  isLoading: boolean
  content: string
  handleContentChange: (content: string) => void
}
export const Edit: FC<Props> = ({ isLoading, content, handleContentChange }) => {
  return (
    <>
      {isLoading ? (
        <LoadingSpinner msg='Loading Content...' />
      ) : (
        <CKContentEditor setEditedContent={handleContentChange} content={content} />
      )}
    </>
  )
}
