import { Snackbar, SnackbarContent } from '@material-ui/core'
import { FC } from 'react'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'

type Props = {
  id: string
  show: boolean
  duration: number
  backgroundColor: SnackbarBackgroundColor
  textColor: SnackbarTextColor
  message: string
  onClose: () => any
}

export const SnackbarAlert: FC<Props> = ({
  id,
  show,
  duration,
  backgroundColor,
  textColor,
  message,
  onClose,
}) => {
  return (
    <Snackbar
      key={id}
      open={show}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className='snack'
    >
      <SnackbarContent
        style={{ backgroundColor: backgroundColor, color: textColor }}
        message={message}
      />
    </Snackbar>
  )
}
