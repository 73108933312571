import env from '@beam-australia/react-env'
import { LaunchContext } from '../../types/LaunchContextTypes'
import { getMockLaunchContext } from '../mock-data/LaunchContext'
import { uuid } from 'services/utility/uuid.util'
import { appInsights } from 'services/AppInsights'
import { FhirResourceQuery } from 'types/Fhir'

const NULL_LAUNCH_URL = `${env('HW_DEMO_LAUNCH_URL')}`
const GET_FHIR_URL = `${env('HW_API_LAUNCH_URL')}/fhir`
const GET_PLATFORM_URL = `${env('HW_API_LAUNCH_URL')}/platform`

export async function fetchNullLaunchStatusUrl(
  testContext: string,
  sessionId: string
): Promise<string> {
  let statusUrl = ''
  try {
    appInsights?.startTrackEvent(`Launch::Fetch NullLaunch StatusUrl`)
    var selectedContext = getMockLaunchContext(testContext)
    const launchContext = selectedContext?.output?.Value as LaunchContext
    const res = await fetch(NULL_LAUNCH_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        LaunchContext: launchContext,
      }),
    })
    if (res.ok) {
      const resUrl = await res.text()
      const resParsedUrl = decodeURI(new URL(resUrl).searchParams?.get('statusUrl') ?? '')

      appInsights?.stopTrackEvent(`Launch::Fetch NullLaunch StatusUrl`, {
        sessionId: sessionId,
      })
      return resParsedUrl
    } else {
      return statusUrl
    }
  } catch {
    return statusUrl
  }
}

export async function fetchLaunchContextData(
  url: string,
  sessionId: string
): Promise<LaunchContext> {
  try {
    appInsights?.startTrackEvent(`Launch::Fetch Context`)
    const res = await fetch(`${url}`)
    if (res.ok) {
      const data: LaunchContext = await res.json()
      data.contextSessionId = sessionId
      appInsights?.stopTrackEvent(`Launch::Fetch Context`, {
        sessionId: data.contextSessionId,
        locationId: data.location?.id as string,
      })
      window.sessionId = data.contextSessionId
      return data
    }

    return {
      clientConfig: {} as any,
      patient: {} as any,
      location: {} as any,
      organization: {} as any,
      practitioner: {} as any,
      practitionerRole: {} as any,
      encounter: {} as any,
      ssoPatientIdentifier: {} as any,
      writebackDestinations: {} as any,
      hwAccessToken: '',
      hwSubscription: {} as any,
      hwOrganization: {} as any,
      contextSessionId: uuid(),
      isRedoxTestEnvironment: true,
      error: true,
      entitlement: {} as any,
    }
  } catch {
    return {
      clientConfig: {} as any,
      patient: {} as any,
      location: {} as any,
      organization: {} as any,
      practitioner: {} as any,
      practitionerRole: {} as any,
      encounter: {} as any,
      ssoPatientIdentifier: {} as any,
      writebackDestinations: {} as any,
      hwAccessToken: '',
      hwSubscription: {} as any,
      hwOrganization: {} as any,
      contextSessionId: uuid(),
      isRedoxTestEnvironment: true,
      error: true,
      entitlement: {} as any,
    }
  }
}

export async function fetchFhir(
  cacheId: string,
  resource?: FhirResourceQuery
): Promise<LaunchContext> {
  let postBody: any = {
    cacheId: cacheId,
  }

  if (resource) {
    postBody['resources'] = resource
  }

  const response = await fetch(GET_FHIR_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody),
  })

  if (response.ok) {
    return await response.json()
  }
  return {
    clientConfig: {} as any,
    patient: {} as any,
    location: {} as any,
    department: {} as any,
    organization: {} as any,
    practitioner: {} as any,
    practitionerRole: {} as any,
    encounter: {} as any,
    ssoPatientIdentifier: {} as any,
    writebackDestinations: {} as any,
    hwAccessToken: '',
    hwSubscription: {} as any,
    hwOrganization: {} as any,
    contextSessionId: uuid(),
    isRedoxTestEnvironment: true,
    error: true,
    entitlement: {} as any,
  }
}

export async function fetchPlatform(cacheId: string): Promise<LaunchContext> {
  const response = await fetch(GET_PLATFORM_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ cacheId: cacheId }),
  })

  if (response.ok) {
    return response.json()
  }
  return {
    clientConfig: {} as any,
    patient: {} as any,
    location: {} as any,
    organization: {} as any,
    practitioner: {} as any,
    practitionerRole: {} as any,
    encounter: {} as any,
    ssoPatientIdentifier: {} as any,
    writebackDestinations: {} as any,
    hwAccessToken: '',
    hwSubscription: {} as any,
    hwOrganization: {} as any,
    contextSessionId: uuid(),
    isRedoxTestEnvironment: true,
    error: true,
    entitlement: {} as any,
  }
}
