import classNames from 'classnames'
import React, { useRef } from 'react'
import { useFeatures } from 'services/context/FeatureContext'
import './HealthwiseContent.scss'

export const ContentErrorMessage = 'Error Loading Content'
export const ContentErrorMessageTestId = 'content-error-message'

type Props = {
  html: string
  id?: string | undefined
  hwid?: string | undefined
  tabIndex?: -1 | 0 | undefined
  type: string
  'aria-hidden'?: boolean | undefined
  'aria-describedby'?: string | undefined
}

const HealthwiseContent: React.FC<Props> = ({ html, id, tabIndex, type, hwid, ...props }) => {
  const { state: features } = useFeatures()
  const hwContentRef = useRef(document.createElement('div'))
  const ariaDescribedby = props['aria-describedby']
  const ariaHidden = props['aria-hidden']
  type = type || 'unknown'

  function Debug() {
    return features['enableDebugMode'] ? (
      <>
        <p>Hwid: {hwid}</p>
      </>
    ) : (
      <></>
    )
  }

  if (html) {
    return (
      <>
        <Debug />
        <div
          className={classNames(
            'rendered-hw-content',
            `rendered-content-${type.toLowerCase().replace(/\s/g, '-')}`
          )}
          dangerouslySetInnerHTML={{ __html: html.replace(' role="complementary"', '') }}
          ref={hwContentRef}
          id={id}
          tabIndex={tabIndex}
          aria-describedby={ariaDescribedby}
          aria-hidden={ariaHidden}
        />
      </>
    )
  }
  return <p data-testid={ContentErrorMessageTestId}>{ContentErrorMessage}</p>
}

export default HealthwiseContent
