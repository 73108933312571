import { FC } from 'react'
import env from '@beam-australia/react-env'

import './footer.scss'
import { openUrlNewWindow } from 'services/utility/common'
import Link from '@material-ui/core/Link'
import moment from 'moment'

const HW_TERMS_URL = env('HW_TERMS_URL')
const HW_PRIVACY_URL = env('HW_PRIVACY_URL')

export const Footer: FC = () => {
  const openLink = (url: string) => {
    openUrlNewWindow(url)
  }

  return (
    <div className='footer'>
      <div className='footer-links'>
        <p className='footer-copyright-text' data-testid='footer-copyright'>
          © {moment().format('YYYY')} Healthwise, Incorporated
        </p>
        |
        <Link
          className='footer-link'
          component='button'
          variant='body2'
          onClick={() => openLink(HW_TERMS_URL)}
        >
          Terms
        </Link>
        |
        <Link
          className='footer-link'
          component='button'
          variant='body2'
          onClick={() => openLink(HW_PRIVACY_URL)}
        >
          Privacy
        </Link>
      </div>
      <div className='footer-disclaimer'>
        <p>
          Your organization has provided consent to process your business contact information
          consistent with Healthwise’s privacy notice.
        </p>
      </div>
    </div>
  )
}
