export enum ContentTypes {
  PI_AFTERVISIT = 'PI Aftervisit',
  ANATOMY = 'Anatomy Sketch',
  CDC = 'CDC Document',
  FDB = 'Fdb',
  MULTUM = 'Multum Document',
  PI_POST_OP = 'PI Post-Op',
  PI_PRE_OP = 'PI Pre-Op',
  QUICKLIST = 'Quick List',
  VIDEO = 'Video',
  IN_HOUSE = 'In-House PDF',
}
