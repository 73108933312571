import { FC, useLayoutEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Divider, IconButton, Popover } from '@material-ui/core'
import { FolderSharedOutlined, ShoppingBasketOutlined, StarBorderRounded } from '@material-ui/icons'
import { CustomAutocomplete } from 'components/CustomAlgoliaComponents/CustomAutocomplete/CustomAutocomplete'
import { PatientSettings } from 'components/PatientSettings/PatientSettings'
import { usePatient } from 'services/context/PatientContext'
import { Cart } from '../cart/Cart'
import { CartActionEnum, useCart } from 'services/context/CartContext'
import {
  FilterPropertiesType,
  FilterHandlerType,
  attributeLabels,
} from 'services/filters/filter.config'
import { Algolia } from 'types/Algolia'
import { useWindowWidth } from 'services/hooks/useWindowDimensions'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import { SearchLanguageSelector } from 'components/SearchLanguageSelector/SearchLanguageSelector'
import { Localizations } from 'services/utility/languages.util'
import './Header.scss'
import { useFeatures } from 'services/context/FeatureContext'
import { LanguageActionTypes, useSearchLanguage } from 'services/context/SearchLanguageContext'
import { DiscoFacet, DiscoJoiner } from 'types/DiscoTypes'

interface Props {
  algolia: Algolia
  filterProperties: FilterPropertiesType
  filterHandler: FilterHandlerType
  launchFilterHandler: FilterHandlerType
  optionalFilterHandler: FilterHandlerType
  assignmentDisabled: boolean
  updateHeaderHeight: (height: number) => void
}
const MANAGE_FAVORITES_TEXT = 'Manage Favorites'
const HIDE_BASKET_TEXT = 'Hide Basket'
const SHOW_BASKET_TEXT = 'Show Basket'
const HIDE_PATIENT_FILTERS_HISTORY_TEXT = 'Hide Patient Filters & History'
const SHOW_PATIENT_FILTERS_HISTORY_TEXT = 'Show Patient Filters & History'

export const Header: FC<Props> = ({
  algolia,
  filterProperties,
  filterHandler,
  launchFilterHandler,
  optionalFilterHandler,
  assignmentDisabled,
  updateHeaderHeight,
}) => {
  const { state: patient } = usePatient()
  const { state: cart, dispatch: cartDispatch } = useCart()

  const headerRef = useRef<HTMLDivElement>(null)
  const [cartVisible, setCartVisible] = useState<boolean>(
    cart.userOverrodeVisibility ? cart.visible : cart.items.length > 0
  )
  const { width } = useWindowWidth()

  const [patientSettingsShowing, setPatientSettingsShowing] = useState(false)
  const [patientSettingsAnchor, setPatientSettingsAnchor] = useState<HTMLButtonElement | null>(null)

  const handlePatientIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPatientSettingsShowing(!patientSettingsShowing)
    setPatientSettingsAnchor(patientSettingsAnchor ? null : event.currentTarget)
  }

  const closePatientSettings = () => {
    setPatientSettingsShowing(false)
    setPatientSettingsAnchor(null)
  }

  useLayoutEffect(() => {
    const setVisible = cart.userOverrodeVisibility ? cart.visible : cart.items.length > 0
    setCartVisible(setVisible)
  }, [cart.visible, cart.userOverrodeVisibility, cart.items.length])

  useLayoutEffect(() => {
    if (headerRef.current != null) {
      updateHeaderHeight(headerRef.current.clientHeight)
    }
  }, [headerRef, width, updateHeaderHeight, cartVisible])

  const toggleCart = () => {
    cartDispatch({ type: CartActionEnum.TOGGLE_VISIBILITY_OVERRIDE, data: true })
    cartDispatch({ type: CartActionEnum.TOGGLE_CART_VISIBILITY, data: !cartVisible })
  }

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    cartDispatch({ type: CartActionEnum.TOGGLE_CART_VISIBILITY, data: cart.items.length > 0 })
    cartDispatch({ type: CartActionEnum.TOGGLE_VISIBILITY_OVERRIDE, data: true })
  }

  const toggleCartText = cartVisible ? HIDE_BASKET_TEXT : SHOW_BASKET_TEXT
  const toggleCartAriaLabel =
    toggleCartText + (cart.items.length > 0 ? `${cart.items.length} total items in basket` : ``)
  const patientSettingsText = patientSettingsShowing
    ? HIDE_PATIENT_FILTERS_HISTORY_TEXT
    : SHOW_PATIENT_FILTERS_HISTORY_TEXT

  const { state: features } = useFeatures()
  const debugModeEnabled = features['enableDebugMode']
  const { state: searchLanguage, dispatch: searchLanguageDispatch } = useSearchLanguage()

  function handleChange(langKeyValue: string) {
    const localization = Localizations.find(l => l.key === langKeyValue)
    if (localization) {
      const languageFacet: DiscoFacet = {
        attributes: [attributeLabels.selectedLanguage],
        values: [localization.key],
        joiner: DiscoJoiner.OR,
      }
      filterHandler.reset(attributeLabels.selectedLanguage)
      launchFilterHandler.reset(attributeLabels.selectedLanguage)
      filterHandler.add(languageFacet)
      launchFilterHandler.add(languageFacet)
      searchLanguageDispatch({ type: LanguageActionTypes.LANGUAGE_SELECTED, data: localization })
    }
  }

  return (
    <div className='header-outer' data-testid='header' ref={headerRef}>
      <header className='header-container'>
        <div className='header-nav'>
          <div className='nav-logo'>
            <NavLink className='main-logo' to='/'>
              <img
                src='healthwise_Advise_R.svg'
                alt='Healthwise Advise® Logo'
                onClick={handleLogoClick}
              />
            </NavLink>
          </div>
          <div className='nav-search'>
            {debugModeEnabled ? (
              JSON.stringify(searchLanguage.AvailableLanguages.map(f => f.key))
            ) : (
              <></>
            )}
            <SearchLanguageSelector
              availableLanguages={searchLanguage.AvailableLanguages}
              selectedLanguage={searchLanguage.SelectedLanguage}
              handleChange={handleChange}
            />

            <CustomAutocomplete
              algolia={algolia}
              wrapperId='search-bar-skinny'
              className='nav-search-bar'
              filterProperties={filterProperties}
              filterHandler={filterHandler}
            />
          </div>
          <div className='nav-icons'>
            <div className='nav-icon'>
              <AdviseTooltip text={patientSettingsText} placement='bottom'>
                <IconButton
                  aria-label={patientSettingsText}
                  className='patient-settings-button'
                  size='medium'
                  onClick={handlePatientIconClick}
                >
                  <FolderSharedOutlined />
                </IconButton>
              </AdviseTooltip>

              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={patientSettingsShowing}
                anchorEl={patientSettingsAnchor}
                onClose={closePatientSettings}
              >
                <PatientSettings
                  patient={patient}
                  close={closePatientSettings}
                  hideCartOnLoad={() => {
                    cartDispatch({ type: CartActionEnum.TOGGLE_CART_VISIBILITY, data: false })
                    cartDispatch({ type: CartActionEnum.TOGGLE_VISIBILITY_OVERRIDE, data: true })
                  }}
                  filterHandler={filterHandler}
                  launchFilterHandler={launchFilterHandler}
                  optionalFilterHandler={optionalFilterHandler}
                />
              </Popover>
            </div>
            <div className='nav-icon'>
              <AdviseTooltip text={MANAGE_FAVORITES_TEXT} placement={'bottom'}>
                <IconButton
                  className='show-favorites-button'
                  size='medium'
                  href='#/favorites'
                  aria-label={MANAGE_FAVORITES_TEXT}
                >
                  <StarBorderRounded />
                </IconButton>
              </AdviseTooltip>
            </div>
            <div className='nav-icon'>
              <AdviseTooltip text={toggleCartText} placement={'left'}>
                <IconButton
                  onClick={toggleCart}
                  aria-label={toggleCartAriaLabel}
                  aria-live='polite'
                >
                  <Badge overlap='rectangular' badgeContent={cart.items.length} color='primary'>
                    <ShoppingBasketOutlined color='primary' />
                  </Badge>
                </IconButton>
              </AdviseTooltip>
            </div>
          </div>
        </div>
        <span aria-live='polite' className='offscreen-text'>
          {cartVisible ? 'basket opened' : 'basket closed'}
        </span>

        {debugModeEnabled ? searchLanguage.SelectedLanguage.key : <></>}

        {cartVisible ? (
          <div className='header-cart'>
            <Cart disablePrintAndAVS={assignmentDisabled} />
          </div>
        ) : null}

        <Divider className='divider' />
      </header>
    </div>
  )
}
