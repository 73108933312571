import React, { ReactNode } from 'react'
import { createCtx } from './ContextHelper'
import { Feature } from 'types/Features'

export enum FeatureActionEnum {
  SET_FEATURES,
  UPDATE_FEATURE,
}

type FeatureAction =
  | { type: FeatureActionEnum.SET_FEATURES; data: Feature }
  | { type: FeatureActionEnum.UPDATE_FEATURE; data: { name: string; enabled: boolean } }

type FeatureContextType = {
  state: Feature
  dispatch: React.Dispatch<FeatureAction>
}

const [useFeatures, CtxProvider] = createCtx<FeatureContextType>('Favorites Context')
type Props = {
  initialState?: Feature
  children: ReactNode
}
const FeatureContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: Feature, action: FeatureAction): Feature {
    switch (action.type) {
      case FeatureActionEnum.SET_FEATURES:
        const updatedFeatures = Object.assign(ctxState, action.data)
        return updatedFeatures
      case FeatureActionEnum.UPDATE_FEATURE:
        const newState = { ...ctxState }
        newState[action.data.name] = action.data.enabled
        return newState
      default:
        console.warn('Action type not handled in Cart reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? {})
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { FeatureContextProvider, useFeatures }
