import { SvgIcon } from '@material-ui/core'
import { LocationCity } from '@material-ui/icons'
import classNames from 'classnames'
import { FC } from 'react'

interface EditProps {
  color?: string
}

export const EditNote: FC<EditProps> = ({ color }) => {
  const classes = classNames('adv-icon', 'MuiSvgIcon-root')
  const fill = color === 'light' ? '#F0F0F0' : '#006272'
  return (
    <svg
      height='40'
      viewBox='0 0 20 20'
      className={classes}
      focusable='false'
      aria-hidden='true'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={fill}
        d='M3,5h9v1.5H3V5z M3,11.25h6v1.5H3V11.25z M3,8.12h9v1.5H3V8.12z M16.78,11.99l0.65-0.65c0.29-0.29,0.29-0.77,0-1.06 l-0.71-0.71c-0.29-0.29-0.77-0.29-1.06,0l-0.65,0.65L16.78,11.99z M16.19,12.58L11.77,17H10v-1.77l4.42-4.42L16.19,12.58z'
      />
    </svg>
  )
}

export const AllIcon: FC = () => {
  return (
    <SvgIcon>
      <path
        d='M259,385.65h2.69l-5.61-14.84h-2.31l-5.61,14.84h2.68l1.2-3.46h5.75ZM255,374l2.15,6.16h-4.3Z'
        transform='translate(-248.2 -365)'
      />
      <path d='M265.81,370h-2.48v15.65h2.48Z' transform='translate(-248.2 -365)' />
      <path d='M271.13,370h-2.47v15.65h2.47Z' transform='translate(-248.2 -365)' />
    </SvgIcon>
  )
}

interface Props {
  color?: string
}

export const InHouseContent: FC<Props> = ({ color }) => {
  let fill = '#006272'
  switch (color) {
    case 'dark':
      fill = '#676767'
      break
    case 'black':
      fill = '#000000de'
      break
    default:
      fill = '#006272'
      break
  }
  return <LocationCity htmlColor={fill} data-testid='in-house-icon' />
}
