import React, { ReactNode } from 'react'
import { createCtx } from 'services/context/ContextHelper'
import { CartItem } from 'types/Cart'

export enum EditActionEnum {
  SET_EDIT,
  CLEAR_EDIT,
}

type EditAction =
  | { type: EditActionEnum.SET_EDIT; data: CartItem }
  | { type: EditActionEnum.CLEAR_EDIT }

export type EditContextType = {
  state: CartItem
  dispatch: React.Dispatch<EditAction>
}

const [useEdit, CtxProvider] = createCtx<EditContextType>('Edit Context')

type Props = {
  initialState?: CartItem
  children: ReactNode
}

const EditContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: CartItem, action: EditAction): CartItem {
    switch (action.type) {
      case EditActionEnum.SET_EDIT:
        return action.data
      case EditActionEnum.CLEAR_EDIT:
        return {} as CartItem
      default:
        console.warn('Action type not handled in Edit reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? ({} as CartItem))
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { EditContextProvider, useEdit }
