import {
  AssignmentContent,
  ContentAssignmentDto,
  CreateAssignmentContentDTO,
} from 'types/Assignment'
import { AssignmentStatus } from 'types/AssignmentStatus'
import { ContentTypes } from 'types/HwContentTypes'
import { PersistedContent } from 'types/PdfResult'

export function mapContentAssignments(
  html: PersistedContent[],
  pdfs: PersistedContent[],
  content: AssignmentContent[]
): ContentAssignmentDto[] {
  const contentAssignments = content.map(assignment => {
    const { hwid, language } = assignment

    let assignedContent = html.find(h => h.id.includes(hwid) && h.id.includes(language))
    if (!assignedContent) {
      assignedContent = pdfs.find(pdf => pdf.id.includes(hwid) && pdf.id.includes(language))
    }

    const contentArtifact = assignedContent?.id ?? ''
    const contentVersion = assignedContent?.contentVersion ?? ''

    return {
      hwid,
      contentVersion,
      language,
      contentArtifact,
    }
  })

  return contentAssignments
}

export function getStatusText(status: AssignmentStatus, contentType: ContentTypes) {
  if (status === AssignmentStatus.SENT) {
    return 'Added to AVS'
  } else {
    let text = ''
    if (status === AssignmentStatus.OPENED) {
      text = 'Opened'
      if (contentType === ContentTypes.VIDEO) {
        text += ' Video'
      } else {
        text += ' Article'
      }
    } else if (status === AssignmentStatus.STARTED) {
      text = 'Started'
      if (contentType === ContentTypes.VIDEO) {
        text += ' Video'
      } else {
        text += ' Article'
      }
    } else if (status === AssignmentStatus.PARTIAL) {
      text = 'Partially'
      if (contentType === ContentTypes.VIDEO) {
        text += ' Watched'
      } else {
        text += ' Read'
      }
    } else if (status === AssignmentStatus.COMPLETED) {
      text = 'Finished'
      if (contentType === ContentTypes.VIDEO) {
        text += ' Watching'
      } else {
        text += ' Reading'
      }
    }
    return text
  }
}

export const encodeEditedContent = (content: CreateAssignmentContentDTO[]) => {
  return content.map(c => {
    if (c.rawHtml) {
      c.rawHtml = encodeURIComponent(c.rawHtml)
    }
    return c
  })
}
