import { FC, useEffect, useState } from 'react'
import { Typography, IconButton, Divider } from '@material-ui/core'
import { LibraryAdd, Print, AddComment } from '@material-ui/icons'
import { CollapsibleListItem } from 'components/ListItem/CollapsibleListItem/CollapsibleListItem'
import { CartActionEnum, useCart } from 'services/context/CartContext'
import { GetLocalizationFromKey } from 'services/utility/languages.util'
import {
  GetLocalizationsOfDuplicateCartItem,
  renderCartLanguageDropdown,
} from 'services/utility/cart.util'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { CartItem } from 'types/Cart'
import { NotesField } from 'components/NotesField/NotesField'
import classNames from 'classnames'
import { ListType } from 'types/ListTypes'
import { useFavorites } from 'services/context/FavoritesContext'
import { InsightEventNames, sendSearchEvent } from 'services/utility/algoliainsight.util'
import { AlertDialog } from 'components/Dialog/AlertDialog'
import Button from 'components/Button'
import { usePractitioner } from 'services/context/PractitionerContext'
import { useSnackBar, SnackBarActionEnum } from 'services/context/SnackbarContext'
import { uuid } from 'services/utility/uuid.util'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import { useFeatures } from 'services/context/FeatureContext'
import { useLaunchContext } from 'services/context/LaunchContext'
import './Cart.scss'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { PatientActionEnum, usePatient } from 'services/context/PatientContext'
import { createAssignmentV3, fetchPreviouslyAssigned } from 'services/apis/assignment.api'
import { printPDF, sendAssignedEvents } from 'services/utility/cart.util.attach'
import { appInsights } from 'services/AppInsights'
import {
  convertPersistedContentToContentPersist,
  createAssignmentDto,
} from 'services/utility/cart.assignment.util'
import { sendAssignmentCreatedEvent } from 'services/utility/events/bee/event.util'
import { useEventPayloadContext } from 'services/context/EventPayloadCtx'
import { TRY_AGAIN } from 'services/utility/strings'
import {
  ADD_TO_AVS_TEXT,
  FAILED_TO_WRITE_CONTENT,
  KEEP_EDITED_CONTENT_TEXT,
  NO_ENCOUNTER_TEXT,
  PLEASE_SELECT_CONTENT,
  PRINT_SELECTED_CONTENT_TEXT,
  REMOVE_FROM_BASKET_TEXT,
  REMOVE_TEXT,
  UNABLE_TO_PRINT,
} from 'services/utility/strings/cart'

type Props = {
  disablePrintAndAVS?: boolean
}

export const Cart: FC<Props> = ({ disablePrintAndAVS }) => {
  const { state: cart, dispatch: cartDispatch } = useCart()
  const { state: launch } = useLaunchContext()
  const { state: patient, dispatch: patientDispatch } = usePatient()
  const { state: favorites } = useFavorites()
  const { state: practitioner } = usePractitioner()
  const { dispatch: snackDispatch } = useSnackBar()
  const { state: clientConfigurations } = useClientConfigurations()
  const { state: eventPayloadState } = useEventPayloadContext()
  const { state: features } = useFeatures()
  const [showNote, setShowNote] = useState<boolean>(false)
  const [showRemoveEditedContentModal, setRemoveEditedContentModal] = useState<boolean>(false)
  const [editedItemToRemove, setEditedItemToRemove] = useState<CartItem>()
  const [isLoading, setIsLoading] = useState<{ message: string; loading: boolean }>({
    message: '',
    loading: false,
  })
  const [updatePreviouslyAssignedFlag, setUpdatePreviouslyAssignedFlag] = useState<boolean>(false)

  const noteText = showNote ? 'Hide Note' : 'Add Note'

  useEffect(() => {
    const updatePreviouslyAssigned = async () => {
      const response = await fetchPreviouslyAssigned(
        patient.id,
        clientConfigurations['hwAccessToken']
      )
      if (response) {
        patientDispatch({ type: PatientActionEnum.SET_HISTORY, data: [...response] })
        setUpdatePreviouslyAssignedFlag(false)
      }
    }

    if (updatePreviouslyAssignedFlag) {
      updatePreviouslyAssigned()
    }
  })

  const handleCheck = (newChecked: boolean, hwid: string, languageKey: string) => {
    const item = cart.items.find(
      item => item.hwid === hwid && item.localization.key === languageKey
    )

    if (item) {
      if (item.edited) {
        setEditedItemToRemove(item)
        setRemoveEditedContentModal(true)
      } else {
        removeCartItem(item)
      }
    }
  }

  function handleLanguageChange(localizationKey: string, id?: string) {
    const getResult = cart.items.filter(item => item.cartItemId === id)
    if (getResult.length > 0) {
      const item = getResult[0]
      const newLocalization = GetLocalizationFromKey(localizationKey)
      if (newLocalization) {
        cartDispatch({
          type: CartActionEnum.LANGUAGE_CHANGE,
          data: { cartItemId: item.cartItemId, localization: newLocalization },
        })
      }
    }
  }

  const setSnackBar = (message: string, show: boolean, error: boolean) => {
    const snack = {
      show: show,
      message: message,
      backgroundColor: error ? SnackbarBackgroundColor.ERROR : SnackbarBackgroundColor.SUCCESS,
      textColor: SnackbarTextColor.WHITE,
      duration: 5000,
      key: uuid(),
    }
    snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
  }

  const invalidCart = () => cart.items?.length < 1

  const handlePrintClick = async () => {
    if (invalidCart()) {
      setSnackBar(`${PLEASE_SELECT_CONTENT} ${PRINT_SELECTED_CONTENT_TEXT}.`, true, true)
      return
    }

    setIsLoading({ loading: true, message: 'Content is printing...' })
    const skipWriteback = clientConfigurations['skipWriteback'] ?? false //If property is not set - then don't skip
    const createAssignmentV3Params = createAssignmentDto(
      'print',
      practitioner,
      launch,
      patient,
      cart.items,
      clientConfigurations['customLogoUri'],
      clientConfigurations['sendMultiplePDFs'],
      cart.notes,
      skipWriteback
    )
    appInsights?.startTrackEvent(`Assignment::Create Assignment`)
    const createAssignment = await createAssignmentV3(
      createAssignmentV3Params,
      clientConfigurations['hwAccessToken']
    )

    // Indicates a successful assignment on our end
    if (createAssignment.fileUrl) {
      printPDF(createAssignment.fileUrl)
      setUpdatePreviouslyAssignedFlag(true)
      sendAssignedEvents(cart.items, eventPayloadState, createAssignment)
      sendAssignmentCreatedEvent(
        eventPayloadState,
        cart.items,
        createAssignment.assignment,
        'print',
        convertPersistedContentToContentPersist(createAssignment.persist.pdf),
        convertPersistedContentToContentPersist(createAssignment.persist.html)
      )
      setSnackBar('Content successfully printed.', true, false)
    }

    // Indicates a succesful writeback
    if (createAssignment.isWritebackSuccess) {
      setIsLoading({ loading: false, message: '' })
      successfulCartFulfillment()
    } else {
      setIsLoading({ loading: false, message: '' })
      setSnackBar(`${FAILED_TO_WRITE_CONTENT} ${TRY_AGAIN}`, true, true)
    }
    appInsights?.stopTrackEvent(`Assignment::Create Assignment`, {
      sessionId: launch.contextSessionId,
      locationId: launch.location?.id as string,
    })
  }

  const handleAvsClick = async () => {
    if (invalidCart()) {
      setSnackBar(`${PLEASE_SELECT_CONTENT} ${ADD_TO_AVS_TEXT}.`, true, true)
      return
    }
    setIsLoading({ loading: true, message: 'Adding Content To AVS...' })

    const skipWriteback = clientConfigurations['skipWriteback']
    const createAssignmentV3Params = createAssignmentDto(
      'avs',
      practitioner,
      launch,
      patient,
      cart.items,
      clientConfigurations['customLogoUri'],
      clientConfigurations['sendMultiplePDFs'],
      cart.notes,
      skipWriteback
    )
    appInsights?.startTrackEvent(`Assignment::Create Assignment`)

    const createAssignment = await createAssignmentV3(
      createAssignmentV3Params,
      clientConfigurations['hwAccessToken']
    )

    // Indicates a successful assignment on our end
    if (createAssignment.assignmentId) {
      setUpdatePreviouslyAssignedFlag(true)
      sendAssignedEvents(cart.items, eventPayloadState, createAssignment)
      sendAssignmentCreatedEvent(
        eventPayloadState,
        cart.items,
        createAssignment.assignment,
        'avs',
        convertPersistedContentToContentPersist(createAssignment.persist.pdf),
        convertPersistedContentToContentPersist(createAssignment.persist.html)
      )
    }

    // Indicates a successful writeback
    if (createAssignment.isWritebackSuccess) {
      const message = cart.items.length > 1 ? 'items added to AVS.' : 'item added to AVS.'
      setIsLoading({ loading: false, message: '' })
      setSnackBar(`${cart.items.length} ${message}`, true, false)
      successfulCartFulfillment()
    } else {
      setIsLoading({ loading: false, message: '' })
      setSnackBar(`${FAILED_TO_WRITE_CONTENT} ${TRY_AGAIN}`, true, true)
    }
    appInsights?.stopTrackEvent(`Assignment::Create Assignment`, {
      sessionId: launch.contextSessionId,
      locationId: launch.location?.id as string,
    })
  }

  const toggleRemoveEditsVisibility = () => {
    setRemoveEditedContentModal(!showRemoveEditedContentModal)
  }

  const toggleNoteVisibility = () => {
    setShowNote(!showNote)
    cartDispatch({ type: CartActionEnum.TOGGLE_NOTES_VISIBILITY, data: showNote })
  }

  function successfulCartFulfillment() {
    cartDispatch({ type: CartActionEnum.EMPTY_CART })
  }

  const updateNote = (note: string) => {
    cartDispatch({ type: CartActionEnum.UPDATE_NOTES, data: note })
  }

  const removeCartItem = (cartItem: CartItem) => {
    if (cartItem) {
      cartDispatch({ type: CartActionEnum.REMOVE_ITEM, data: cartItem })
      sendSearchEvent(InsightEventNames.basketRemove, cartItem.algoliaInsightsEventData)
      const snack = {
        show: true,
        message: `"${cartItem.title}" ${REMOVE_FROM_BASKET_TEXT}`,
        backgroundColor: SnackbarBackgroundColor.SUCCESS,
        textColor: SnackbarTextColor.WHITE,
        duration: 5000,
        key: uuid(),
      }
      snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })

      if (cartItem.edited) {
        toggleRemoveEditsVisibility()
      }
    }
  }

  const handleDelete = () => {
    if (editedItemToRemove) {
      removeCartItem(editedItemToRemove)
    }
  }

  const rerenderLanguageSelector = () => {
    renderCartLanguageDropdown()
  }

  const renderCartItems = cart.items
    .map((item, i) => {
      const disabledLocalizations = GetLocalizationsOfDuplicateCartItem(item.hwid, cart.items)
      const isFavorite = favorites.uniqueFavoritesList.indexOf(item.hwid) !== -1

      const listItem = (
        <CollapsibleListItem
          isEdited={item.edited}
          editedContent={item.editedContent}
          key={i}
          listType={ListType.CART}
          type={item.type}
          docType={item.docType}
          title={item.title}
          hwid={item.hwid}
          description={item.description}
          availableLocalizations={item.availableLocalizations}
          disabledLocalizations={disabledLocalizations}
          selectedLanguage={item.localization}
          showLanguageSelect={true}
          existsInCart={true}
          isFavorite={isFavorite}
          handleCheck={handleCheck}
          handleLanguageChange={handleLanguageChange}
          algoliaInsightEventData={item.algoliaInsightsEventData}
          searchResultRank={item.searchResultRank}
          searchTerm={item.searchString}
          purpose={item.purpose}
          inHouse={item.inHouse}
          source={item.source}
        />
      )
      return listItem
    })
    .reverse()

  return (
    <section data-testid='cart-container' className='cart' data-section-type='SkinnyResultSection'>
      <div className='cart-header'>
        <Typography className='cart-header__title' component='h2'>
          {cart.items.length > 0 ? <div className='cart-count'>{cart.items.length}</div> : null}
          Basket
        </Typography>
        <AdviseTooltip text={noteText} placement='bottom'>
          <IconButton
            data-testid='note-button'
            aria-label={noteText}
            onClick={toggleNoteVisibility}
          >
            <AddComment color='primary' />
          </IconButton>
        </AdviseTooltip>
        <AdviseTooltip
          text={
            disablePrintAndAVS
              ? `${UNABLE_TO_PRINT}${NO_ENCOUNTER_TEXT}`
              : PRINT_SELECTED_CONTENT_TEXT
          }
          placement='bottom'
        >
          <IconButton
            data-testid='print-selected-button'
            aria-label={
              disablePrintAndAVS
                ? `${UNABLE_TO_PRINT}${NO_ENCOUNTER_TEXT}`
                : PRINT_SELECTED_CONTENT_TEXT
            }
            onClick={handlePrintClick}
            disabled={disablePrintAndAVS}
            className={disablePrintAndAVS ? 'cart-button-disabled' : 'cart-button'}
          >
            <Print color='primary' />
          </IconButton>
        </AdviseTooltip>
        <AdviseTooltip
          text={disablePrintAndAVS ? `${UNABLE_TO_PRINT}${NO_ENCOUNTER_TEXT}` : ADD_TO_AVS_TEXT}
          placement='bottom'
        >
          <IconButton
            data-testid='avs-selected-button'
            aria-label={
              disablePrintAndAVS ? `${UNABLE_TO_PRINT}${NO_ENCOUNTER_TEXT}` : ADD_TO_AVS_TEXT
            }
            onClick={handleAvsClick}
            disabled={disablePrintAndAVS}
            className={disablePrintAndAVS ? 'cart-button-disabled' : 'cart-button'}
          >
            <LibraryAdd color='primary' />
          </IconButton>
        </AdviseTooltip>
      </div>

      <Divider />
      <AlertDialog
        data-testid='alert-dialog'
        show={showRemoveEditedContentModal}
        title={'Remove Edited Content?'}
        message={'All of your changes will be lost.'}
      >
        <Button
          className='keep-edited-content-button'
          aria-label={KEEP_EDITED_CONTENT_TEXT}
          rounded
          borderless
          neutral
          small
          type='button'
          onClick={toggleRemoveEditsVisibility}
        >
          {KEEP_EDITED_CONTENT_TEXT}
        </Button>
        <Button
          aria-label={REMOVE_TEXT}
          rounded
          borderless
          accent
          small
          type='button'
          onClick={handleDelete}
        >
          {REMOVE_TEXT}
        </Button>
      </AlertDialog>
      <div className={classNames('cart-body')} onScroll={rerenderLanguageSelector}>
        {features['enableDebugMode'] ? (
          <>
            <p>Cart Items: {cart?.items?.length}</p>
            <p>Cart Visible: {cart?.visible === true ? 'True' : 'False'}</p>
          </>
        ) : (
          <></>
        )}
        {isLoading.loading ? (
          <LoadingSpinner msg={isLoading.message} />
        ) : (
          <div>
            {showNote ? (
              <NotesField note={cart.notes} updateNote={updateNote} variant={'outlined'} />
            ) : null}

            {renderCartItems}
          </div>
        )}
      </div>
    </section>
  )
}
