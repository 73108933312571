import React, { ReactNode } from 'react'
import { LaunchContext } from 'types/LaunchContextTypes'
import { createCtx } from './ContextHelper'

export enum LaunchActionEnum {
  SET_LAUNCH,
}

type LaunchAction = { type: LaunchActionEnum.SET_LAUNCH; data: LaunchContext }

type LaunchContextType = {
  state: LaunchContext
  dispatch: React.Dispatch<LaunchAction>
}

const [useLaunchContext, CtxProvider] = createCtx<LaunchContextType>('Launch Context')

type Props = {
  initialState?: LaunchContext
  children: ReactNode
}

const LaunchContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(ctxState: LaunchContext, action: LaunchAction): LaunchContext {
    switch (action.type) {
      case LaunchActionEnum.SET_LAUNCH:
        return action.data
      default:
        console.warn('Action type not handled in Launch reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? ({} as LaunchContext))
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { LaunchContextProvider, useLaunchContext }
