import { FC } from 'react'
import { LanguageState, SearchLanguageCtxProvider } from './SearchLanguageContext'
import { SearchTermCtxProvider } from './SearchTermContext'
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from '../AppInsights'
import { PreviewContextProvider } from '../context/PreviewContentCtx'
import { PatientContextProvider } from './PatientContext'
import { CartContextProvider } from './CartContext'
import { PractitionerContextProvider } from './PractitionerContext'
import { EventPayloadContextProvider, EventPayloadContextState } from './EventPayloadCtx'
import { ConfigurationsContextProvider } from './ClientConfigurationContext'
import { EditContextProvider } from './EditContext'
import { PopoverContextProvider } from './PopoverContext'
import { Cart, CartItem } from 'types/Cart'
import { Preview } from 'types/Preview'
import { SnackBarContextProvider } from './SnackbarContext'
import { LaunchContext, Practitioner } from 'types/LaunchContextTypes'
import { FeatureContextProvider } from './FeatureContext'
import { FavoritesContextProvider } from './FavoritesContext'
import { Feature } from 'types/Features'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ResultsContextProvider } from './ResultsContext'
import { ResultSection } from 'types/ResultSections'
import { LaunchContextProvider } from './LaunchContext'
import { ClientConfiguration } from 'types/Config'
import { FavoritesContextState } from 'types/Favorites'

export type InitialState = {
  CartState?: Cart
  PreviewState?: Preview
  EditState?: CartItem
  SearchLanguageState?: LanguageState
  EventPayloadState?: EventPayloadContextState
  PractitionerState?: Practitioner
  FavoritesState?: FavoritesContextState
  FeatureState?: Feature
  ResultsState?: ResultSection[]
  LaunchState?: LaunchContext
  ClientConfigurationState?: ClientConfiguration
}

type Props = {
  initialState?: InitialState
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const AppContextWrapper: FC<Props> = ({ initialState, children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => <h1>I believe something went wrong</h1>}
          appInsights={reactPlugin}
        >
          <LaunchContextProvider initialState={initialState?.LaunchState}>
            <ResultsContextProvider initialState={initialState?.ResultsState}>
              <PractitionerContextProvider initialState={initialState?.PractitionerState}>
                <ConfigurationsContextProvider
                  initialState={initialState?.ClientConfigurationState}
                >
                  <PopoverContextProvider>
                    <PatientContextProvider>
                      <FavoritesContextProvider initialState={initialState?.FavoritesState}>
                        <PreviewContextProvider initialState={initialState?.PreviewState}>
                          <EditContextProvider initialState={initialState?.EditState}>
                            <SearchTermCtxProvider>
                              <SearchLanguageCtxProvider
                                initialState={initialState?.SearchLanguageState}
                              >
                                <EventPayloadContextProvider
                                  initialState={initialState?.EventPayloadState}
                                >
                                  <SnackBarContextProvider>
                                    <FeatureContextProvider
                                      initialState={initialState?.FeatureState}
                                    >
                                      <CartContextProvider initialState={initialState?.CartState}>
                                        {children}
                                      </CartContextProvider>
                                    </FeatureContextProvider>
                                  </SnackBarContextProvider>
                                </EventPayloadContextProvider>
                              </SearchLanguageCtxProvider>
                            </SearchTermCtxProvider>
                          </EditContextProvider>
                        </PreviewContextProvider>
                      </FavoritesContextProvider>
                    </PatientContextProvider>
                  </PopoverContextProvider>
                </ConfigurationsContextProvider>
              </PractitionerContextProvider>
            </ResultsContextProvider>
          </LaunchContextProvider>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </QueryClientProvider>
  )
}
