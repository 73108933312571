import React, { FC, useState, useEffect, useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { actionKeys, keyCodes } from 'services/utility/common'
import { uuid } from 'services/utility/uuid.util'
import classNames from 'classnames'
import { ArrowDropDown } from '@material-ui/icons'
import { testElementIsOutsideListBox, handleGlobalEscapeKey } from 'services/utility/common'
import './ListBox.scss'
import { useCart } from 'services/context/CartContext'
import { renderCartLanguageDropdown } from 'services/utility/cart.util'

export interface ListBoxItemType {
  label: string
  shortLabel?: string
  value: string
  indexValue?: string
  disabled: boolean
}

interface Props {
  title?: string
  data: ListBoxItemType[]
  id?: string
  className?: string
  onChange?: (dataItem: ListBoxItemType) => void
  selectedElementIndex?: number
  disabled?: boolean
}

const directions: { [key: string]: number } = {
  up: -1,
  down: 1,
}

export const ListBox: FC<Props> = ({
  data,
  title,
  id,
  className,
  onChange,
  selectedElementIndex,
  disabled,
}) => {
  const baseId = uuid()
  const buttonId = baseId + '-button'
  const expandElementId = baseId + '-expand-element'
  const expandListId = baseId + '-expand-list'
  const usableId = id || baseId + '-list-box'

  const minSelectedIndex = 0
  const maxSelectedIndex = data.length - 1

  const [alertMessage, setAlertMessage] = useState('')
  const [listExpanded, setListExpanded] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(selectedElementIndex || 0)
  const [highlightIndex, setHighlightIndex] = useState(selectedElementIndex || 0)
  const [timer, setTimer] = useState<any>(null)

  const { state: cart } = useCart()

  const alertMessageTimeoutMs = 1500

  const dropDownRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      handleGlobalEscapeKey(e, usableId, setListExpanded, false)
    }

    const handleOutsideClick = (e: any) => {
      if (testElementIsOutsideListBox(e.target, usableId)) {
        setListExpanded(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('click', handleOutsideClick, true)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('click', handleOutsideClick, true)
    }
  }, [usableId])

  useEffect(() => {
    const resizeHandler = () => {
      clearTimeout(timer)
      setTimer(setTimeout(renderCartLanguageDropdown, 100))
    }

    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [timer])

  useEffect(renderCartLanguageDropdown, [cart])

  const closeList = () => {
    setListExpanded(false)
    setAlertMessage('list closed')
    focusOnButton()
  }

  const focusOnButton = () => {
    const $button = document.getElementById(buttonId)
    if ($button) {
      $button.focus()
    }
  }

  const moveHighlight = (direction: number) => {
    let index = highlightIndex + direction
    moveHighlightTo(index)
    announceChange(index, index === selectedElementIndex ? 'selected' : 'highlighted')
  }

  const moveHighlightTo = (index: number) => {
    if (index < minSelectedIndex) {
      index = maxSelectedIndex
    } else if (index > maxSelectedIndex) {
      index = minSelectedIndex
    }
    setHighlightIndex(index)
    announceChange(index)
  }

  const changeSelectedLanguage = (index: number) => {
    const dataItem: ListBoxItemType = data[index]
    if (dataItem) {
      if (index === selectedElementIndex) {
        announceChange(index, 'already selected')
        closeList()
      } else if (dataItem.disabled) {
        announceChange(index, 'disabled')
      } else {
        setSelectedIndex(index)
        setHighlightIndex(index)
        announceChange(index, 'selected')
        closeList()
        onChange && onChange(dataItem)
      }
    }
  }

  const announceChange = (index: number, extraMessage?: string) => {
    const dataItem: ListBoxItemType = data[index]
    if (dataItem?.label) {
      const countMessage = index >= 0 ? ` ${index + 1} of ${maxSelectedIndex + 1}` : ''
      const disabledMessage = dataItem.disabled ? ` disabled` : ''
      setAlertMessage(
        dataItem.label + disabledMessage + countMessage + (extraMessage ? ` ${extraMessage}` : '')
      )
      setTimeout(() => {
        setAlertMessage('')
      }, alertMessageTimeoutMs)
    }
  }

  const handleKeyDown = (e: any) => {
    var key: number = e.which || e.keyCode
    const keyCode = keyCodes[key]
    switch (keyCode) {
      case actionKeys.Esc:
        closeList()
        break
      case actionKeys.ArrowUp:
      case actionKeys.ArrowLeft:
        e.preventDefault()
        e.stopPropagation()
        moveHighlight(directions.up)
        break
      case actionKeys.ArrowDown:
      case actionKeys.ArrowRight:
        e.preventDefault()
        e.stopPropagation()
        moveHighlight(directions.down)
        break
      case actionKeys.Home:
        e.preventDefault()
        e.stopPropagation()
        moveHighlightTo(minSelectedIndex)
        break
      case actionKeys.End:
        e.preventDefault()
        e.stopPropagation()
        moveHighlightTo(maxSelectedIndex)
        break
      case actionKeys.Tab:
        if (listExpanded) {
          closeList()
        }
        break
      case actionKeys.Enter:
        if (listExpanded) {
          e.preventDefault()
          e.stopPropagation()
          changeSelectedLanguage(highlightIndex)
        }
        break
    }
  }

  const handleListBoxClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAlertMessage(listExpanded ? 'list closed' : 'list open')
    setListExpanded(!listExpanded)
    setTimeout(() => {
      renderCartLanguageDropdown()
    }, 0)
    e.stopPropagation()
  }

  const handleListBoxItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation()
    changeSelectedLanguage(index)
  }
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
      role='application'
    >
      {/* Added this to prevent the Hyperspace app from moving cursor to the status window */}
      <FocusLock disabled={!listExpanded}>
        <div
          id={usableId}
          className={classNames(className, 'listbox-component', { 'list-expanded': listExpanded })}
          onKeyDown={handleKeyDown}
        >
          <div role='alert' className='offscreen-text' aria-relevant='text additions'>
            <span>{alertMessage}</span>
          </div>
          <label className='listbox-title' htmlFor={buttonId} id={expandElementId}>
            {title ? title : null}
            {data?.[selectedIndex]?.label && (
              <span className='offscreen-text'>{' ' + data[selectedIndex].label}</span>
            )}
          </label>
          <button
            disabled={disabled}
            className='listbox-button'
            data-testid='listbox-button'
            aria-haspopup='listbox'
            id={buttonId}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleListBoxClick(e)}
            title={data[selectedIndex].label}
            aria-label={data[selectedIndex].label}
          >
            <span className='listbox-button-inner'>
              <span className='listbox-button-label' aria-hidden>
                {data[selectedIndex].shortLabel || data[selectedIndex].label}
              </span>
              <span className='listbox-button-icon-wrapper' aria-hidden>
                <ArrowDropDown />
              </span>
            </span>
          </button>
          <ul
            id={expandListId}
            tabIndex={-1}
            role='listbox'
            aria-labelledby={expandElementId}
            className='listbox-list'
            aria-activedescendant={baseId + selectedIndex}
            ref={dropDownRef}
          >
            {data.map((dataItem, dx) => {
              const isSelectedIndex = dx === selectedIndex
              const isHighlightIndex = dx === highlightIndex
              return (
                <li
                  key={baseId + dx}
                  id={baseId + dx}
                  tabIndex={isSelectedIndex ? 0 : -1}
                  aria-selected={isSelectedIndex}
                  className={classNames(
                    'listbox-item',
                    {
                      'listbox-item-selected': isSelectedIndex,
                    },
                    {
                      'listbox-item-highlight': isHighlightIndex,
                    },
                    {
                      'listbox-item-disabled': dataItem.disabled,
                    }
                  )}
                  role='option'
                  aria-labelledby={expandElementId}
                  aria-disabled={dataItem.disabled}
                  data-value={dataItem.value}
                  data-short-label={dataItem.shortLabel}
                  onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                    handleListBoxItemClick(e, dx)
                  }}
                >
                  {dataItem.label}
                </li>
              )
            })}
          </ul>
        </div>
      </FocusLock>
    </form>
  )
}
