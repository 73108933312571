import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { Popover } from 'components/Popover/Popover'
import { FC, useState, Suspense, useEffect } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { usePatient } from 'services/context/PatientContext'
import { usePopover } from 'services/context/PopoverContext'
import { usePractitioner } from 'services/context/PractitionerContext'
import { FilterHandlerType, FilterPropertiesType } from 'services/filters/filter.config'
import { analyticsTags } from 'services/utility/algoliainsight.util'
import { PopoverType } from 'types/Popover'
import { EditDialog } from 'views/edit/EditDialog'
import { PreviewDialog } from 'views/preview/PreviewDialog'
import { AppKeyboardShortcuts } from './components/AppKeyboardShortcuts'
import { PageWrapper } from './PageWrapper'
import { Header } from 'views/Header/Header'
import { Error } from 'components/Error/Error'
import { Footer } from 'views/footer/footer'
import { Algolia } from 'types/Algolia'
import { Feature } from 'types/Features'
import { useClientConfigurations } from 'services/context/ClientConfigurationContext'
import { useLaunchContext } from 'services/context/LaunchContext'
import { isEmpty } from 'lodash'
import { SnackBarActionEnum, useSnackBar } from 'services/context/SnackbarContext'
import { SnackbarAlert } from 'components/SnackbarAlert/SnackbarAlert'
import { Timeout } from 'components/Timeout/Timeout'
import lazyWithPreload from 'services/utility/preloadLazy'
import env from '@beam-australia/react-env'

const inactivityTimeoutWarningSeconds = parseInt(env('INACTIVITY_TIMEOUT_WARNING_MINS')) * 60
const inactivityTimeoutSeconds = parseInt(env('INACTIVITY_TIMEOUT_MINS')) * 60
const appTimeoutWarningSeconds = parseInt(env('SESSION_TIMEOUT_WARNING_MINS')) * 60
const appTimeoutSeconds = parseInt(env('SESSION_TIMEOUT_MINS')) * 60

const FeaturesPreload = lazyWithPreload(() => import('views/Features'))

const MainPreload = lazyWithPreload(() => import('views/main/Main'))
const PatientHistoryWrapperPreload = lazyWithPreload(
  () => import('views/history/PatientHistoryWrapper')
)
const FavoritesPreload = lazyWithPreload(() => import('views/favorites/Favorites'))

type Props = {
  launchLoading: boolean
  launchError: string
  algolia: Algolia
  optionalProperties: FilterPropertiesType
  filterProperties: FilterPropertiesType
  filterHandler: FilterHandlerType
  launchFilterProperties: FilterPropertiesType
  launchFilterHandler: FilterHandlerType
  optionalFilterHandler: FilterHandlerType
  features: Feature
}

// UI Language for content coming from Algolia
export const TEXT_SEARCH_SECTION = 'TEXT_SEARCH'

export const Layout: FC<Props> = ({
  launchLoading,
  launchError,
  algolia,
  optionalProperties,
  filterProperties,
  filterHandler,
  launchFilterProperties,
  launchFilterHandler,
  optionalFilterHandler,
  features,
}) => {
  const { state: patient } = usePatient()
  const { state: popover } = usePopover()
  const { state: clientConfigurations } = useClientConfigurations()
  const { state: practitioner } = usePractitioner()
  const { state: snack, dispatch: snackDispatch } = useSnackBar()

  const [headerHeight, setHeaderHeight] = useState(0)
  const [focusedAccordion, setFocusedAccordion] = useState<number | null>(null)
  const { state: launch } = useLaunchContext()
  const showPreview = popover.type === PopoverType.PREVIEW
  const showEdit = popover.type === PopoverType.EDIT && clientConfigurations['allowEdit']

  useEffect(() => {
    if (!launchLoading) {
      MainPreload.preload()
      FeaturesPreload.preload()
      PatientHistoryWrapperPreload.preload()
      FavoritesPreload.preload()
    }
  }, [launchLoading])

  const encounterNotFound =
    launch.encounter === null || isEmpty(launch.encounter) || !launch.encounter.id

  if (features['enableDebugMode'] && window.console && console.info) {
    console.info(filterProperties.aisFilterString, optionalProperties.aisOptionalList)
  }

  const setHeader = (height: number) => {
    setHeaderHeight(height)
  }

  const algoliaReady = algolia?.client?.appId !== '' && algolia?.state.index !== ''

  if (launchLoading) {
    return <LoadingSpinner msg='Getting Search Results...' />
  }

  if (launchError) {
    return <Error message={launchError} />
  }

  const closeSnackBar = () => {
    snackDispatch({ type: SnackBarActionEnum.CLEAR_SNACKBAR })
  }

  return (
    <Router>
      {algoliaReady ? (
        <InstantSearch searchClient={algolia.client} indexName={algolia.state.index}>
          <Configure
            hitsPerPage={3}
            filters={filterProperties.aisFilterString}
            distinct={true}
            facetingAfterDistinct={true}
            analytics={true}
            clickAnalytics={true}
            enablePersonalization={true}
            userToken={practitioner.id}
            analyticsTags={analyticsTags()}
          />
          <AppKeyboardShortcuts />

          <Header
            algolia={algolia}
            filterHandler={filterHandler}
            optionalFilterHandler={optionalFilterHandler}
            filterProperties={filterProperties}
            launchFilterHandler={launchFilterHandler}
            updateHeaderHeight={setHeader}
            assignmentDisabled={encounterNotFound}
          />

          <SnackbarAlert
            id={snack.key}
            show={snack.show}
            duration={snack.duration}
            backgroundColor={snack.backgroundColor}
            textColor={snack.textColor}
            message={snack.message}
            onClose={closeSnackBar}
          />
          <Timeout
            inactivityTimeoutWarningSeconds={inactivityTimeoutWarningSeconds}
            inactivityTimeoutSeconds={inactivityTimeoutSeconds}
            appTimeoutWarningSeconds={appTimeoutWarningSeconds}
            appTimeoutSeconds={appTimeoutSeconds}
          ></Timeout>

          {showPreview ? <PreviewDialog show={popover.type === PopoverType.PREVIEW} /> : null}

          {showEdit ? <EditDialog show={popover.type === PopoverType.EDIT} /> : null}

          <Popover show={popover.type === PopoverType.REMOVEEDIT}> </Popover>

          <PageWrapper headerHeight={headerHeight}>
            <Switch>
              <Suspense fallback={<LoadingSpinner msg='' />}>
                <Route
                  path='/features/:featureList?'
                  render={props => <FeaturesPreload {...props} />}
                />
                <Route
                  path='/ab/:featureList?'
                  render={props => (
                    <MainPreload
                      assignmentDisabled={encounterNotFound}
                      algolia={algolia}
                      filterProperties={filterProperties}
                      filterHandler={filterHandler}
                      optionalProperties={optionalProperties}
                      launchFilterProperties={launchFilterProperties}
                      launchFilterHandler={launchFilterHandler}
                      practitionerId={practitioner.id}
                      focusedAccordion={focusedAccordion}
                      setFocusedAccordion={setFocusedAccordion}
                      {...props}
                    />
                  )}
                />{' '}
                <Route
                  path='/educationHistory'
                  render={props => (
                    <PatientHistoryWrapperPreload patientId={patient.id} {...props} />
                  )}
                />
                <Route path='/favorites' render={() => <FavoritesPreload algolia={algolia} />} />
                <Route
                  path='/'
                  exact
                  render={props => (
                    <MainPreload
                      assignmentDisabled={encounterNotFound}
                      algolia={algolia}
                      filterProperties={filterProperties}
                      filterHandler={filterHandler}
                      optionalProperties={optionalProperties}
                      launchFilterProperties={launchFilterProperties}
                      launchFilterHandler={launchFilterHandler}
                      practitionerId={practitioner.id}
                      focusedAccordion={focusedAccordion}
                      setFocusedAccordion={setFocusedAccordion}
                      {...props}
                    />
                  )}
                />
              </Suspense>
            </Switch>
          </PageWrapper>
          <Footer />
        </InstantSearch>
      ) : null}
    </Router>
  )
}
