import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const AppKeyboardShortcuts: FC = () => {
  const history = useHistory() //This needs to be nested within the <Router> component

  useEffect(() => {
    const handleShortcuts = (e: KeyboardEvent) => {
      if (e.altKey && e.ctrlKey && e.key === 's') {
        history.push('/')
      } else if (e.altKey && e.ctrlKey && e.key === 'm') {
        history.push('/')
      } else if (e.altKey && e.ctrlKey && e.key === 'f') {
        history.push('/features')
      }
    }
    window.addEventListener('keydown', handleShortcuts)
    return () => {
      window.removeEventListener('keydown', handleShortcuts)
    }
  }, [history])

  return <></>
}
