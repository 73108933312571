import React from 'react'
import { createCtx } from './ContextHelper'
import { FavoritesContextState, FavoritesFolder } from '../../types/Favorites'

const EMPTY_FAVORITES_STATE: FavoritesContextState = {
  favoritesFolders: [],
  uniqueFavoritesList: [],
  manageFavoritesFolders: [],
}

export enum FavoritesActionEnum {
  SET_FAVORITES,
  SET_SELECTED_FOLDER,
  SET_UNIQUE_FAVORITES,
  SET_MANAGE_FAVORITES_FOLDERS,
  SET_SELECT_ALL_FAVORITES,
}

type FavoritesAction =
  | { type: FavoritesActionEnum.SET_FAVORITES; data: FavoritesFolder[] }
  | { type: FavoritesActionEnum.SET_UNIQUE_FAVORITES; data: string[] }
  | { type: FavoritesActionEnum.SET_MANAGE_FAVORITES_FOLDERS; data: string[] }

type FavoritesContextType = {
  state: FavoritesContextState
  dispatch: React.Dispatch<FavoritesAction>
}
type Props = {
  initialState?: FavoritesContextState
}

const [useFavorites, CtxProvider] = createCtx<FavoritesContextType>('Favorites Context')

const FavoritesContextProvider: React.FC<Props> = ({ initialState, children }) => {
  function reducer(
    ctxState: FavoritesContextState,
    action: FavoritesAction
  ): FavoritesContextState {
    switch (action.type) {
      case FavoritesActionEnum.SET_FAVORITES:
        return { ...ctxState, favoritesFolders: action.data }
      case FavoritesActionEnum.SET_UNIQUE_FAVORITES:
        return { ...ctxState, uniqueFavoritesList: action.data }
      case FavoritesActionEnum.SET_MANAGE_FAVORITES_FOLDERS:
        return { ...ctxState, manageFavoritesFolders: action.data }
      default:
        console.warn('Action type not handled in Cart reducer', action)
        return ctxState
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState ?? EMPTY_FAVORITES_STATE)
  return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>
}

export { FavoritesContextProvider, useFavorites }
