import { FC, useEffect, useRef } from 'react'
import { Localization } from 'types/Localization'
import { LanguageSelect } from 'components/LanguageSelect/LanguageSelect'
import { Checkbox, ListItem, ListItemAvatar, Typography } from '@material-ui/core'
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import { CreateCartItemId } from 'services/utility/cart.util'
import { ContentTypes } from 'types/HwContentTypes'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import { InHouseContent } from 'components/Icons/Icons'
import './CustomListItem.scss'

interface CustomListItemProps {
  hwid: string
  title: string
  isSelected: boolean
  showLanguageSelect: boolean
  availableLocalizations: Localization[]
  disabledLocalizations?: Localization[]
  type?: string
  docType?: string
  inHouse?: boolean
  selectedLanguage: Localization
  existsInCart: boolean
  handleCheck: (newChecked: boolean, hwid: string, languageKey: string) => void
  handleListItemClick?: (hwid: any, checked?: any) => any
  handleLanguageChange?: (language: string, id?: string) => void
  handleKey: (e: any) => void
}

export const CustomListItem: FC<CustomListItemProps> = ({
  hwid,
  title,
  isSelected,
  showLanguageSelect,
  availableLocalizations,
  disabledLocalizations,
  selectedLanguage,
  existsInCart,
  handleCheck,
  handleLanguageChange,
  handleListItemClick,
  type,
  docType,
  inHouse,
  handleKey,
}) => {
  const focusRef = useRef<any>(null)

  useEffect(() => {
    if (isSelected) {
      focusRef?.current?.focus({ preventScroll: true })
    }
  }, [isSelected])

  const renderLanguageSelect =
    availableLocalizations && showLanguageSelect && handleLanguageChange ? (
      <LanguageSelect
        id={CreateCartItemId(hwid, selectedLanguage.key)}
        selectedLanguage={selectedLanguage}
        availableLanguages={availableLocalizations}
        disabledLanguages={disabledLocalizations}
        handleLanguageChange={handleLanguageChange}
        languageSelectElementId={'ContentLanguageSelector'}
        className={'content-language-selector'}
      />
    ) : null

  const checkboxId = `list-item-${hwid}`
  const deselectStopper = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    handleListItemClick && handleListItemClick(hwid, existsInCart)
  }

  const iconLabel = type === ContentTypes.VIDEO ? 'Video' : 'Article'
  const inHouseLabel = 'In-house'
  const checkboxTooltip = existsInCart ? 'Remove Item from Basket' : 'Add Item to Basket'
  return (
    <ListItem
      button
      component='li'
      tabIndex={isSelected ? 0 : -1}
      selected={isSelected}
      className={`customListItem ${isSelected ? 'selectedListItem' : ''}`}
      ref={focusRef}
      onKeyDown={(e: any) => handleKey(e)}
      onClick={() => (handleListItemClick ? handleListItemClick(hwid, existsInCart) : null)}
    >
      <div className='listItem-left' tabIndex={-1}>
        <AdviseTooltip text={checkboxTooltip} placement='top'>
          <Checkbox
            tabIndex={-1}
            id={checkboxId}
            color='primary'
            checked={existsInCart}
            onClick={e => e.stopPropagation()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              handleCheck(e.target.checked, hwid, selectedLanguage.key)
            }}
            className='checkbox'
            aria-describedby={title}
            aria-checked={existsInCart}
            aria-label={checkboxTooltip}
          />
        </AdviseTooltip>
      </div>
      <label className='listItem-center' htmlFor={checkboxId} onClick={deselectStopper}>
        <span className='offscreen-text'>{type}, </span>
        <span className='listItemContent'>
          {renderLanguageSelect}
          <Typography className='itemTitle' variant='subtitle1' component='h2'>
            {title}
          </Typography>
        </span>
      </label>
      <div className='listItem-right'>
        <span aria-label={inHouseLabel} title={inHouseLabel} role='img' onClick={deselectStopper}>
          {inHouse ? <InHouseContent color={'black'} /> : <></>}
        </span>
      </div>
      <div className='listItem-right'>
        <ListItemAvatar className='iconWrapper'>
          <span aria-label={iconLabel} title={iconLabel} role='img' onClick={deselectStopper}>
            {type === ContentTypes.VIDEO ? <OndemandVideoIcon /> : <DescriptionOutlined />}
          </span>
        </ListItemAvatar>
      </div>
    </ListItem>
  )
}
