import { useState, ReactNode, FC } from 'react'
import { Typography, ListItemIcon, Checkbox, IconButton, Popover } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CreateCartItemId, ItemExistsInCart } from 'services/utility/cart.util'
import { CartActionEnum, useCart } from 'services/context/CartContext'
import { PreviewActionEnum, usePreview } from 'services/context/PreviewContentCtx'
import { PreviewUserActions } from 'types/Preview'
import { InsightEventNames, sendSearchEvent } from 'services/utility/algoliainsight.util'
import { FavoritesActionEnum, useFavorites } from 'services/context/FavoritesContext'
import { CreateRounded, StarOutlineRounded, StarRounded } from '@material-ui/icons'
import { ManageFavorites } from 'views/favorites/ManageFavorites'
import { IsDefaultLanguage } from 'services/utility/languages.util'
import { PopoverActionEnum, usePopover } from 'services/context/PopoverContext'
import { PopoverType } from 'types/Popover'
import { EditActionEnum, useEdit } from 'services/context/EditContext'
import { isEditableContentType } from 'services/utility/common'
import { AdviseTooltip } from 'components/Tooltip/Tooltip'
import './PreviewHeader.scss'
import { appInsights } from 'services/AppInsights'
import { SnackBarActionEnum, useSnackBar } from 'services/context/SnackbarContext'
import { uuid } from 'services/utility/uuid.util'
import { SnackbarBackgroundColor, SnackbarTextColor } from 'types/Snackbar'
import { ADD_TO_BASKET_TEXT, REMOVE_FROM_BASKET_TEXT } from 'services/utility/strings/cart'

const CLOSE_PREVIEW_TEXT = 'Close Preview'
type Props = {
  allowEdit: boolean
}

export const PreviewHeader: FC<Props> = ({ allowEdit }) => {
  const { state: cart, dispatch: cartDispatch } = useCart()

  const { state: preview, dispatch: previewDispatch } = usePreview()
  const { dispatch: popoverDispatch } = usePopover()
  const { dispatch: editDispatch } = useEdit()
  const { dispatch: snackDispatch } = useSnackBar()

  const { state: favorites, dispatch: favoritesDispatch } = useFavorites()
  const [favoritesAnchorElement, setFavoritesAnchorElement] = useState<null | HTMLElement>(null)

  const cartItemId = preview.cartItem
    ? preview.cartItem.cartItemId
    : CreateCartItemId(preview.previewId, preview.previewLanguageKey)
  const existsInCart = ItemExistsInCart(cartItemId, cart.items)
  const isFavorite = favorites.uniqueFavoritesList.indexOf(preview.cartItem.hwid) !== -1
  const isDefaultLanguage = IsDefaultLanguage(preview.cartItem.localization)
  const checkboxTooltip = existsInCart ? 'Remove Item from Basket' : 'Add Item to Basket'
  const favoriteTooltip = isFavorite ? 'Favorited' : 'Add to Favorites'
  const showManageFavorites = Boolean(favoritesAnchorElement)

  const cartAvailable = preview.previewActions.indexOf(PreviewUserActions.CART) > -1
  const favoriteAvailable = preview.previewActions.indexOf(PreviewUserActions.FAVORITES) > -1
  const editAvailable = preview.previewActions.indexOf(PreviewUserActions.EDIT) > -1

  function closeFavoritesModal(): void {
    setFavoritesAnchorElement(null)
  }

  const handleClose = () => {
    previewDispatch({ type: PreviewActionEnum.CLEAR_PREVIEW })
    popoverDispatch({ type: PopoverActionEnum.CLEAR_POPOVER })
  }

  const handleCheck = (checked: boolean) => {
    if (cartAvailable && preview.cartItem) {
      const cartItem = { ...preview.cartItem }

      if (checked) {
        sendSearchEvent(InsightEventNames.basketAdd, cartItem.algoliaInsightsEventData)
        cartDispatch({
          type: CartActionEnum.ADD_ITEM,
          data: { ...cartItem, localization: preview.cartItem.localization },
        })
      } else {
        sendSearchEvent(InsightEventNames.basketRemove, cartItem.algoliaInsightsEventData)
        cartDispatch({
          type: CartActionEnum.REMOVE_ITEM,
          data: { ...cartItem, localization: preview.cartItem.localization },
        })
      }

      const message = `'${cartItem.title}' ${
        checked ? ADD_TO_BASKET_TEXT : REMOVE_FROM_BASKET_TEXT
      }`
      const snack = {
        show: true,
        message,
        backgroundColor: SnackbarBackgroundColor.SUCCESS,
        textColor: SnackbarTextColor.WHITE,
        duration: 5000,
        key: uuid(),
      }
      snackDispatch({ type: SnackBarActionEnum.SET_SNACKBAR, data: snack })
    }
  }

  function renderCartSelection(): ReactNode {
    return cartAvailable ? (
      <ListItemIcon className='preview-checkbox-wrapper'>
        <AdviseTooltip text={checkboxTooltip} placement={'right'}>
          <Checkbox
            color='primary'
            checked={existsInCart}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e.target.checked)}
            inputProps={{ 'aria-label': `${checkboxTooltip} ${preview.previewTitle}` }}
            aria-checked={existsInCart}
          />
        </AdviseTooltip>
      </ListItemIcon>
    ) : (
      <></>
    )
  }

  const toggleFavoritesModal = (event: React.MouseEvent<HTMLElement>) => {
    appInsights.startTrackEvent('Favorites::Dialog Open')

    const favoritesFolders = favorites.favoritesFolders
      .filter(folder => folder.Favorites.find(fav => fav.hwid === preview.cartItem.hwid))
      .map(folder => folder.FolderId)
    favoritesDispatch({
      type: FavoritesActionEnum.SET_MANAGE_FAVORITES_FOLDERS,
      data: favoritesFolders,
    })
    setFavoritesAnchorElement(favoritesAnchorElement ? null : event.currentTarget)
  }

  function renderFavoriteSelection(): ReactNode {
    return favoriteAvailable ? (
      <ListItemIcon>
        <AdviseTooltip text={favoriteTooltip} placement={'bottom'}>
          <IconButton
            className='show-favorites-button'
            size='medium'
            onClick={(event: React.MouseEvent<HTMLElement>) => toggleFavoritesModal(event)}
          >
            {isFavorite ? <StarRounded /> : <StarOutlineRounded />}
          </IconButton>
        </AdviseTooltip>
        <Popover
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          open={showManageFavorites}
          anchorEl={favoritesAnchorElement}
          onClose={closeFavoritesModal}
        >
          <ManageFavorites
            algoliaInsightEventData={preview.cartItem.algoliaInsightsEventData}
            hwid={preview.cartItem.hwid}
            isFavorite={isFavorite}
            close={closeFavoritesModal}
          />
        </Popover>
      </ListItemIcon>
    ) : (
      <></>
    )
  }

  function renderEditSelection(): ReactNode {
    return existsInCart &&
      isDefaultLanguage &&
      editAvailable &&
      allowEdit &&
      isEditableContentType(preview.cartItem.type) ? (
      <ListItemIcon>
        <AdviseTooltip text={'Edit Article'} placement={'bottom'}>
          <IconButton
            className='edit-button'
            size='medium'
            onClick={handleEditContent}
            data-testid='preview-edit-button'
          >
            <CreateRounded />
          </IconButton>
        </AdviseTooltip>
      </ListItemIcon>
    ) : (
      <></>
    )
  }

  function handleEditContent() {
    const item = { ...preview.cartItem }
    editDispatch({ type: EditActionEnum.SET_EDIT, data: item })
    popoverDispatch({ type: PopoverActionEnum.SET_POPOVER, data: { type: PopoverType.EDIT } })
  }

  function renderAvailableCartActions(): ReactNode {
    return (
      <>
        {renderCartSelection()}
        {renderFavoriteSelection()}
        {renderEditSelection()}
      </>
    )
  }

  return (
    <div className='preview-header'>
      <div className='preview-header-title'>
        <div className='preview-header-text'>
          <Typography id='modal-title' variant='h6' component='h2'>
            {preview.previewTitle}
          </Typography>
        </div>
        <AdviseTooltip text={CLOSE_PREVIEW_TEXT} placement={'left'}>
          <IconButton
            onClick={handleClose}
            className='preview-header-close'
            aria-label={CLOSE_PREVIEW_TEXT}
            aria-controls={'modal'}
          >
            <CloseIcon />
          </IconButton>
        </AdviseTooltip>
      </div>
      <div className='preview-header-controls'>{renderAvailableCartActions()}</div>
    </div>
  )
}
