import moment from 'moment'
import { cloneDeep } from 'lodash'
import { uuid } from 'services/utility/uuid.util'

export function getMockLaunchContext(contextId: string): any {
  if (contextId in MockLaunchContext) {
    let mock = cloneDeep(MockLaunchContext[contextId])

    let age = Number.parseInt(mock.output.Value.patient.age ?? 1)
    mock.output.Value.patient.dateOfBirth = moment()
      .subtract(age, 'years')
      .subtract(1, 'days')
      .format()
    return mock
  }

  return null
}

const MockLaunchContext: any = {
  '1': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          collapseAccordions: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'e2.pOWNwzfXYlWpAYx43FMg3',
          visitNumber: '01829762',
          diagnoses: [
            {
              text: 'GERD (gastroesophageal reflux disease)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '235595009',
                  display: 'Gastroesophageal reflux disease (disorder)',
                },
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'K21.9',
                  display: 'GERD (gastroesophageal reflux disease)',
                },
              ],
            },
          ],
          careSetting: 'inpatient',
          reasons: [
            {
              text: 'Epigastric pain',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '119416008',
                  display: 'Epigastric Discomfort (Finding)',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Inpatient',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.10110',
                  code: '101',
                  display: 'Inpatient',
                },
              ],
            },
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '6',
            display: 'Discharge',
          },
          date: '2010-12-28T13:24:42Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eMCBUPSPulvD09.x6XGKl6Q3',
          gender: 'male',
          age: '50',
          preferredLanguage: 'fr',
          name: 'Harvey Wallbanger',
          firstName: 'Harvey',
          lastName: 'Wallbanger',
          email: 'hwallbanger@fakedomain.com',
          phoneNumber: '(222)-555-1212',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'eMCBUPSPulvD09.x6XGKl6Q3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eweKBcq8ruM-1MzZAX-A-0uj2rDm4SMniPONiaM5VGQk3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          codes: [
            {
              codes: [
                {
                  code: '3',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.836982.1040',
                  display: 'Registered Nurse',
                  source: 'practitionerRole/code',
                },
              ],
              text: 'Registered Nurse',
            },
          ],
          specialties: [],
        },
      },
    },
  },
  '2': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          collapseAccordions: true,
          allowEdit: false,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
          writebackId: 'bar',
          writebackIdType: 'PROVID',
        },
        encounter: {
          visitNumber: '01829722',
          id: 'eBfO2N4Ux73pQIhLYS6t1ZA3',
          medications: [
            {
              id: 'e52MAJSdNhwg6s0Rab0h.Cw3',
              codes: [
                {
                  source: 'medication/code',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.253',
                  code: '66416',
                },
              ],
              text: 'INSULIN ASPART 100 UNIT/ML SC SOLN',
            },
            {
              codes: [
                {
                  source: 'medication/code',
                  codeSystem: 'http://www.nlm.nih.gov/research/umls/rxnorm',
                  code: '2123111',
                },
              ],
              text: 'albuterol (PROVENTIL HFA;VENTOLIN HFA) inhaler',
            },
          ],
          careSetting: 'inpatient',
          immunizations: [
            {
              id: 'eY6KzzE7s5qBWwMbQEnaOCfer8yZikamQVw97ikNG7FI3',
              codes: [
                {
                  source: 'immunization/vaccinecode',
                  codeSystem: 'http://hl7.org/fhir/sid/cvx',
                  code: '8987',
                },
              ],
              text: 'Measles',
            },
          ],

          diagnoses: [
            {
              text: 'Atrial Fibrillation (unspecified)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'I48.91',
                  display: 'Gastroesophageal reflux disease (disorder)',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '2',
            display: 'Inpatient',
          },
          hospitalizationReadmission: {
            text: 'Readmission',
            codes: [
              {
                source: 'encounter/hospitalization/readmission',
                code: 'R',
                codeSystem: 'readmissionSystem',
                display: 'Readmission',
              },
            ],
          },
          date: '2019-08-31T02:00:00Z',
        },
        patient: {
          id: 'eXFljJT8WxVd2PjwvPAGR1A3',
          zipCode: '53706',
          gender: 'female',
          race: 'Other Race',
          ethnicity: 'Hispanic or Latino',
          age: '81',
          preferredLanguage: null,
          firstName: 'Gernetta',
          lastName: 'Blanston',
          email: 'gblanston@fakedomain.com',
          phoneNumber: '(222)-555-1213',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'eXFljJT8WxVd2PjwvPAGR1A3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eweKBcq8ruM-1MzZAX-A-0uj2rDm4SMniPONiaM5VGQk3',
          managingOrganization: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'EMH Emergency',
          medicalSpecialties: [
            {
              codes: [
                {
                  code: '45',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.686980.110',
                  source: 'Location/Specialty',
                  display: 'Emergency Medicine',
                },
              ],
            },
          ],
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Family Medicine',
          medicalSpecialties: [
            {
              codes: [
                {
                  code: '9',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.686980.110',
                  source: 'Department/Specialty',
                  display: 'Family Medicine',
                },
              ],
            },
          ],
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '3': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          visitNumber: '21829762',
          diagnoses: [],
          careSetting: 'outpatient',
          reasons: [
            {
              text: 'Geriatric annual health check',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '281038008',
                  display: 'Geriatric annual health check',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '6',
            display: 'Discharge',
          },
          hospitalizationReadmission: {
            text: 'Readmission',
            codes: [
              {
                source: 'encounter/hospitalization/not/readmission',
                code: 'X',
                codeSystem: 'someOtherCodeSystem',
                display: 'display',
              },
            ],
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'ehq2i0YHiqAexdraJZgml1Q3',
          zipCode: '53593',
          gender: 'male',
          age: '72',
          preferredLanguage: null,
          firstName: 'Fred',
          lastName: 'Sanford',
          email: 'fsanford@fakedomain.com',
          phoneNumber: '(222)-555-1214',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'ehq2i0YHiqAexdraJZgml1Q3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
          medicalSpecialties: [
            {
              codes: [
                {
                  code: '45',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.686980.110',
                  source: 'Location/Specialty',
                  display: 'Emergency Medicine',
                },
              ],
            },
          ],
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '4': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          visitNumber: '31231829762',
          diagnoses: [
            {
              text: 'Encounter for full-term uncomplicated delivery',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'O80',
                  display: 'Encounter for full-term uncomplicated delivery',
                },
              ],
            },
            {
              text: 'Encounter for maternal postpartum care and examination',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z39',
                  display: 'Encounter for maternal postpartum care and examination',
                },
              ],
            },

            {
              text: 'Breastfeeding (mother)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '413712001',
                  display: 'Breastfeeding (mother)',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Inpatient',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.10110',
                  code: '101',
                  display: 'Inpatient',
                },
              ],
            },
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '3',
            display: 'Discharge',
          },
          date: '2014-05-18T15:45:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eb0DHmCOlbSRqSVzriEOXQw3',
          zipCode: '53593',
          gender: 'female',
          age: '20',
          preferredLanguage: null,
          firstName: 'Samantha',
          lastName: 'Smith',
          email: 'ssmith@fakedomain.com',
          phoneNumber: '(222)-555-1215',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'eb0DHmCOlbSRqSVzriEOXQw3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Family Medicine',
          medicalSpecialties: [
            {
              codes: [
                {
                  code: '9',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.686980.110',
                  source: 'Department/Specialty',
                  display: 'Family Medicine',
                },
              ],
            },
          ],
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '5': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          visitNumber: '0182931262',
          diagnoses: [],
          reasons: [
            {
              text: 'Pain in right hip joint',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: ' 316921000119102',
                  display: 'Pain in right hip joint',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Inpatient',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.10110',
                  code: '101',
                  display: 'Inpatient',
                },
              ],
            },
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '3',
            display: 'Discharge',
          },
          date: '2014-05-18T15:45:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'ebl8K3BhOCJiHnP-D16pYjQ3',
          zipCode: '53593',
          gender: 'female',
          age: '67',
          preferredLanguage: 'en-US',
          firstName: 'Betty',
          lastName: 'White',
          email: 'bwhite@fakedomain.com',
          phoneNumber: '(222)-555-1216',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'ebl8K3BhOCJiHnP-D16pYjQ3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '6': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          visitNumber: '03123829762',
          diagnoses: [
            {
              text: 'Urinary tract infection, site not specified',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'N39.0',
                  display: 'Urinary tract infection, site not specified',
                },
              ],
            },
            {
              text: 'Supervision of normal first pregnancy',
              id: 'eX1sATURhCIxeHrjkKYQQLQ3',
              date: '2012-09-21',
              codes: [
                {
                  source: 'patient/problem-list-item',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-10-cm',
                  code: 'Z34.00',
                  display: null,
                  status: null,
                },
                {
                  sourc: 'patient/problem-list-item',
                  codeSystem: 'http://snomed.info/sct',
                  code: '457811000124103',
                  display: null,
                  status: null,
                },
                {
                  source: 'patient/problem-list-item',
                  codeSystem: 'http://snomed.info/sct',
                  code: '72892002',
                  display: null,
                  status: null,
                },
                {
                  source: 'patient/problem-list-item',
                  codeSystem: 'http://snomed.info/sct',
                  code: '127364007',
                  display: null,
                  status: null,
                },
                {
                  source: 'patient/problem-list-item',
                  codeSystem: 'http://snomed.info/sct',
                  code: '129825007',
                  display: null,
                  status: null,
                },
                {
                  source: 'patient/problem-list-item',
                  codeSystem: 'http://snomed.info/sct',
                  code: '305058001',
                  display: null,
                  status: null,
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Urgent Care',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '',
                  display: 'Urgent Care',
                },
              ],
            },
          ],
          class: {
            code: 'AMB',
            codeSystem: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
            display: null,
            source: 'encounter/class',
          },
          date: '2014-05-18T15:45:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'ea9y8-.XQClrh-P2qGJos.A3',
          zipCode: '53593',
          gender: 'female',
          age: '34',
          preferredLanguage: 'en',
          firstName: 'Pearl',
          lastName: 'Barley',
          email: 'pbarley@fakedomain.com',
          phoneNumber: '(222)-555-1217',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'ea9y8-.XQClrh-P2qGJos.A3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '7': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: '1331829762',
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          diagnoses: [
            {
              text: 'Encounter for routine child health examination without abnormal findings',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z00.129',
                  display:
                    'Encounter for routine child health examination without abnormal findings',
                },
              ],
            },
            {
              text: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z68.53',
                  display: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '5',
            display: 'Appointment',
          },
          date: '2014-05-18T15:45:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'egX.8N.qXVpArKXzoym8hVA3',
          zipCode: '53593',
          gender: 'male',
          age: '8',
          preferredLanguage: 'es',
          firstName: 'Fred',
          lastName: 'Savage',
          email: 'fsavage@fakedomain.com',
          phoneNumber: '(222)-555-1218',
          phoneNumberType: 'work',
        },
        ssoPatientIdentifier: {
          ID: 'egX.8N.qXVpArKXzoym8hVA3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '8': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eMg--HSEe9eGuh9X2Th54bQ3',
          visitNumber: '123456789',
          diagnoses: [
            {
              text: 'Lump or mass in breast',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '89164003',
                  display: 'Breast lump (finding)',
                },
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '611.72',
                  display: 'Lump or mass in breast',
                },
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'N63',
                  display: 'Lump or mass in breast',
                },
              ],
            },
          ],
          reasons: [
            {
              text: 'Breast Mass',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.728286',
                  code: '160078',
                  display: 'Breast Mass',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Outpatient',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.10110',
                  code: '102',
                  display: 'Outpatient',
                },
              ],
            },
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '4',
            display: 'HOV',
          },
          date: '2013-07-29T17:00:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eD9Lyzs-ab83UnHh.C9ZKDg3',
          zipCode: '53719',
          gender: 'female',
          age: '40',
          preferredLanguage: 'en-US',
          firstName: 'Henryetta',
          lastName: 'Hyacinth',
          email: 'hhyacinth@fakedomain.com',
          phoneNumber: '(222)-555-1219',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'eD9Lyzs-ab83UnHh.C9ZKDg3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eIFJdH1hm1mbcKtakI4NHq27tpuUFeCGavbGSaa6v1os3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          codes: [
            {
              codes: [
                {
                  code: '3',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.836982.1040',
                  display: 'Registered Nurse',
                  source: 'practitionerRole/code',
                },
              ],
              text: 'Registered Nurse',
            },
          ],
          specialties: [],
        },
      },
    },
  },
  '9': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          id: 'eMg--HSEe9eGuh9X2Th54bQ3',
          visitNumber: '  0312231829762',
          diagnoses: [
            {
              text: 'Essential hypertension',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '59621000',
                  display: 'Essential hypertension',
                },
              ],
            },
            {
              text: 'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://snomed.info/sct',
                  code: '161891005',
                  display: 'Backache (finding)',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '724.5',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'M54.9',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
              ],
              date: null,
            },
          ],
          reasons: [
            {
              text: 'Pain in right hip joint',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: ' 316921000119102',
                  display: 'Pain in right hip joint',
                },
              ],
            },
            {
              text: 'Stress Fracture of the Foot',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.728286',
                  code: '263253007',
                  display: 'Stress Fracture of the Foot',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Outpatient',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.10110',
                  code: '102',
                  display: 'Outpatient',
                },
              ],
            },
            {
              text: 'Hospital Encounter',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '3',
                  display: 'Hospital Encounter',
                },
              ],
            },
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '4',
            display: 'HOV',
          },
          date: '2013-07-29T17:00:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eKAGlRUehT.T1KL9CvHWxGg3',
          zipCode: '53719',
          gender: 'male',
          age: '26',
          preferredLanguage: 'en-US',
          firstName: 'Bart',
          lastName: 'Bartholomew',
          email: 'bbartholomew@fakedomain.com',
          phoneNumber: '(222)-555-1220',
          phoneNumberType: 'mobile',
        },
        ssoPatientIdentifier: {
          ID: 'eKAGlRUehT.T1KL9CvHWxGg3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eIFJdH1hm1mbcKtakI4NHq27tpuUFeCGavbGSaa6v1os3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'General Health',
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          codes: [
            {
              codes: [
                {
                  code: '3',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.836982.1040',
                  display: 'Registered Nurse',
                  source: 'practitionerRole/code',
                },
              ],
              text: 'Registered Nurse',
            },
          ],
          specialties: [],
        },
      },
    },
  },
  '10': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: 'e01829762',
          id: 'eMg--HSEe9eGuh9X2Th54bQ3',
          diagnoses: [],
          reasons: [
            {
              text: 'Well-Child Visit',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '408503006',
                  display: 'Well-Child Visit',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '4',
            display: 'HOV',
          },
          date: '2013-07-29T17:00:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eKAGlRUehT.T1KL9CvHWxGg4',
          zipCode: '02134',
          gender: 'Female',
          age: '1',
          preferredLanguage: 'en-US',
          firstName: 'Lorna',
          lastName: 'Howard',
          email: 'lhoward@fakedomain.com',
          phoneNumber: '(222)-555-1221',
          phoneNumberType: 'home',
        },
        ssoPatientIdentifier: {
          ID: 'eKAGlRUehT.T1KL9CvHWxGg4',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eIFJdH1hm1mbcKtakI4NHq27tpuUFeCGavbGSaa6v1os3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'aaaaaaaaaaaaaaaaaaaaa',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'General Health',
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          codes: [
            {
              codes: [
                {
                  code: '3',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.836982.1040',
                  display: 'Registered Nurse',
                  source: 'practitionerRole/code',
                },
              ],
              text: 'Registered Nurse',
            },
          ],
          specialties: [],
        },
      },
    },
  },
  '11': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'es4W7J6vSf9iDKkP1Yb2DqOA45lZxTFx3J0rStga5vRk3',
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: '01829762134',
          id: 'eMg--HSEe9eGuh9X2Th54bQ3',
          diagnoses: [],
          reasons: [
            {
              text: 'Sensory and Motor Development, Infant',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '710356009',
                  display: 'Sensory and Motor Development, Infant',
                },
              ],
            },
            {
              text: 'Growth and Development, Infant',
              codes: [
                {
                  source: 'encounter/reason',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '42668004',
                  display: 'Growth and Development, Infant',
                },
              ],
            },
          ],
          type: [
            {
              text: 'Consultation',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.2.808267',
                  code: '1001',
                  display: 'Consultation',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            status: null,
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '4',
            display: 'HOV',
          },
          date: '2013-07-29T17:00:00Z',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'eKAGlRUehT.T1KL9CvHWxGg9',
          zipCode: '55106',
          gender: 'Male',
          age: '0.1',
          preferredLanguage: 'en-US',
          firstName: 'Myles',
          lastName: 'Blue',
          email: 'mblue@fakedomain.com',
          phoneNumber: '(222)-555-1222',
          phoneNumberType: 'home',
        },
        ssoPatientIdentifier: {
          ID: 'eKAGlRUehT.T1KL9CvHWxGg9',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eIFJdH1hm1mbcKtakI4NHq27tpuUFeCGavbGSaa6v1os3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
          name: 'General Health',
        },
        department: {
          id: 'e4W4rmGe9QzuGm2Dy4NBqVc0KDe6yGld6HW95UuN-Qd03',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          codes: [
            {
              codes: [
                {
                  code: '3',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.836982.1040',
                  display: 'Registered Nurse',
                  source: 'practitionerRole/code',
                },
              ],
              text: 'Registered Nurse',
            },
          ],
          specialties: [],
        },
      },
    },
  },
  'redox-with-org': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'e6o492XkIm9Z6AbUpyzF.gF1',
          name: 'Julio Ballota',
        },
        encounter: {
          visitNumber: '12301829762',
          id: 'DgJU10fc8iCg3-evJADpiTkYN',
          diagnoses: [
            {
              id: 'boaubsoudb9aioubd099087d',
              text: 'Other depression',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://snomed.info/sct',
                  code: '35489007',
                  display: 'Depressive disorder (disorder)',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '311',
                  display: 'Other depression',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'F32.89',
                  display: 'Other depression',
                },
              ],
              date: null,
            },
            {
              id: 'doaubsoudb9aioubd099087d',
              text: 'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://snomed.info/sct',
                  code: '161891005',
                  display: 'Backache (finding)',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '724.5',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'M54.9',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
              ],
              date: null,
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.400.3.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '13',
            display: 'Support OP Encounter',
          },
          date: '2022-03-21',
          hospitalizationReadmission: null,
        },
        patient: {
          id: 'fff1928amuabfinfllik',
          zipCode: '20041',
          firstName: 'Arzo',
          lastName: 'Ginurac',
          gender: 'male',
          age: '32',
          preferredLanguage: 'es-us',
          phoneNumber: '555-555-5555',
          phoneNumberType: 'home',
          email: 'foo@test.com',
        },
        ssoPatientIdentifier: {
          ID: 'fff1928amuabfinfllik',
          IDType: 'FHIR ID',
        },
        writebackDestinations: null,
        location: {
          id: 'nmd8a.aobuaiyvrr',
          managingOrganization: null,
          name: 'Emergency Department',
        },
        organization: {
          id: 'aafaz42-12f3-1244-aav6-9b0d036ef4e8', // reflects the use of Redox SSO Meta Source instead of FHIR Id
        },
        department: {
          id: 'nmd8a.aobuaiyvrr',
        },
        practitionerRole: null,
      },
    },
  },
  '12': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: 'e6o492XkIm9Z6AbUpyzF.gF1',
          name: 'Dan Mann',
        },
        encounter: {
          id: 'DgJU10fc8iCg3-evJADpiTkYN',
          visitNumber: '3301829762',
          hospitalizationReadmission: null,
          diagnoses: [
            {
              id: 'boaubsoudb9aioubd099087d',
              text: 'Other depression',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://snomed.info/sct',
                  code: '35489007',
                  display: 'Depressive disorder (disorder)',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '311',
                  display: 'Other depression',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'F32.89',
                  display: 'Other depression',
                },
              ],
              date: null,
            },
            {
              id: 'doaubsoudb9aioubd099087d',
              text: 'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://snomed.info/sct',
                  code: '161891005',
                  display: 'Backache (finding)',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'http://hl7.org/fhir/sid/icd-9-cm/diagnosis',
                  code: '724.5',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
                {
                  source: 'encounter/diagnosis',
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'M54.9',
                  display:
                    'Back pain, unspecified back location, unspecified back pain laterality, unspecified chronicity',
                },
              ],
              date: null,
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.400.3.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            source: 'encounter/class',
            codeSystem: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '13',
            display: 'Support OP Encounter',
          },
          date: '2022-03-21',
        },
        patient: {
          id: 'fff1928amuabfinfllik',
          zipCode: '20041',
          firstName: 'Arzo',
          lastName: 'Ginurac',
          gender: 'male',
          age: '32',
          preferredLanguage: null,
          phoneNumber: '555-555-5555',
          phoneNumberType: 'home',
          email: '',
        },
        ssoPatientIdentifier: {
          ID: 'fff1928amuabfinfllik',
          IDType: 'FHIR ID',
        },
        writebackDestinations: null,
        location: {
          id: 'nmd8a.aobuaiyvrr',
          managingOrganization: null,
          name: 'Emergency Department',
        },
        organization: {
          id: 'aafaz42-12f3-1244-aav6-4577036ef4e8', // reflects the use of Redox SSO Meta Source instead of FHIR Id
        },
        department: {
          id: 'nmd8a.aobuaiyvrr',
        },
        practitionerRole: null,
      },
    },
  },
  '13': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: uuid(),
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: '1331829762',
          id: '',
          hospitalizationReadmission: null,
          diagnoses: [
            {
              text: 'Encounter for routine child health examination without abnormal findings',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z00.129',
                  display:
                    'Encounter for routine child health examination without abnormal findings',
                },
              ],
            },
            {
              text: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z68.53',
                  display: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '5',
            display: 'Appointment',
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'egX.8N.qXVpArKXzoym8hVA3',
          zipCode: '53593',
          gender: 'unknown',
          age: '8',
          preferredLanguage: 'es',
          firstName: 'Fred',
          lastName: 'Savage',
          email: 'fsavage@fakedomain.com',
          phoneNumber: '(222)-555-1218',
          phoneNumberType: 'work',
        },
        ssoPatientIdentifier: {
          ID: 'egX.8N.qXVpArKXzoym8hVA3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  '14': {
    output: {
      Value: {
        clientConfig: {
          sendMultiplePDFs: false,
          allowEdit: true,
        },
        practitioner: {
          id: uuid(),
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: null,
          id: '',
          hospitalizationReadmission: null,
          diagnoses: [
            {
              text: 'Encounter for routine child health examination without abnormal findings',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z00.129',
                  display:
                    'Encounter for routine child health examination without abnormal findings',
                },
              ],
            },
            {
              text: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z68.53',
                  display: 'BMI pediatric, 85th percentile to less than 95th percentile for age',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '5',
            display: 'Appointment',
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'egX.8N.qXVpArKXzoym8hVA3',
          zipCode: '53593',
          gender: 'male',
          age: '8',
          preferredLanguage: 'es',
          firstName: 'Fred',
          lastName: 'Savage',
          email: 'fsavage@fakedomain.com',
          phoneNumber: '(222)-555-1218',
          phoneNumberType: 'work',
        },
        ssoPatientIdentifier: {
          ID: 'egX.8N.qXVpArKXzoym8hVA3',
          IDType: 'FHIR ID',
        },
        writebackDestinations: [
          {
            ID: '22a8f92c-6d26-4dfe-a664-d45bc5e63eca',
            Name: 'apporchard.epic.com',
          },
        ],
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'ePqMmiCU9E1xOUefB9e7Plw3',
          name: 'General Health',
          managingOrganization: 'eMsCX.wQw.oWpUmFIC3xKLQ3',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  uhsSamantha: {
    output: {
      Value: {
        testingPartitionId: 1,
        practitioner: {
          id: '59f5531f29017b8bb8f0c5c04bfc8466',
          name: 'Jane Doe',
        },
        encounter: {
          visitNumber: null,
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          hospitalizationReadmission: {
            text: 'Readmission',
            codes: [
              {
                source: 'encounter/hospitalization/readmission',
                code: 'R',
                codeSystem: 'readmissionSystem',
                display: 'Readmission',
              },
            ],
          },
          diagnoses: [
            {
              text: 'Encounter for full-term uncomplicated delivery',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: '080',
                  display: 'Encounter for full-term uncomplicated delivery',
                },
              ],
            },
            {
              text: 'Encounter for maternal postpartum care and examination',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z39.0',
                  display: 'Encounter for maternal postpartum care and examination',
                },
              ],
            },
            {
              text: 'Breastfeeding (mother)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '413712001',
                  display: 'Breastfeeding (mother)',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '6',
            display: 'Discharge',
            source: 'encounter/class',
            status: '',
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'ff632cf7-98f5-4141-ac28-67aa3a9a9361',
          zipCode: '53593',
          gender: 'female',
          age: '20',
          preferredLanguage: 'en-us',
          firstName: 'Samantha',
          lastName: 'Smith',
          email: 'hw.consumer.ui.app0@mailinator.com',
          phoneNumber: '12626837022',
          phoneNumberType: 'work',
        },
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: '0d272c56-1168-45dc-aafe-1583f989236b',
          name: 'Universal Health System',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  zionSamantha: {
    output: {
      Value: {
        testingPartitionId: 2,
        practitioner: {
          id: '59f5531f29017b8bb8f0c5c04bfc8466',
          name: 'Dillon Malone',
        },
        encounter: {
          visitNumber: null,
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          hospitalizationReadmission: {
            text: 'Readmission',
            codes: [
              {
                source: 'encounter/hospitalization/readmission',
                code: 'R',
                codeSystem: 'readmissionSystem',
                display: 'Readmission',
              },
            ],
          },
          diagnoses: [
            {
              text: 'Encounter for full-term uncomplicated delivery',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: '080',
                  display: 'Encounter for full-term uncomplicated delivery',
                },
              ],
            },
            {
              text: 'Encounter for maternal postpartum care and examination',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z39.0',
                  display: 'Encounter for maternal postpartum care and examination',
                },
              ],
            },
            {
              text: 'Breastfeeding (mother)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '413712001',
                  display: 'Breastfeeding (mother)',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '6',
            display: 'Discharge',
            source: 'encounter/class',
            status: '',
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'ff632cf7-98f5-4141-ac28-67aa3a9a9361',
          zipCode: '53593',
          gender: 'female',
          age: '20',
          preferredLanguage: 'en-us',
          firstName: 'Samantha',
          lastName: 'Smith',
          email: 'hw.consumer.ui.app0@mailinator.com',
          phoneNumber: '12626837022',
          phoneNumberType: 'work',
        },
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'a9084a3d-bd9a-466e-8a9c-9dd49ef1ad73',
          name: 'Zion CMX Hospital',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
  zionSamanthaPract2: {
    output: {
      Value: {
        testingPartitionId: 2,
        practitioner: {
          id: '7a9b5254-f3bf-4497-a8bf-0089fa044d61',
          name: 'Will Williamson',
        },
        encounter: {
          visitNumber: null,
          id: 'eOokDxOJrjzBwx6nmgED5yQ3',
          hospitalizationReadmission: {
            text: 'Readmission',
            codes: [
              {
                source: 'encounter/hospitalization/readmission',
                code: 'R',
                codeSystem: 'readmissionSystem',
                display: 'Readmission',
              },
            ],
          },
          diagnoses: [
            {
              text: 'Encounter for full-term uncomplicated delivery',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: '080',
                  display: 'Encounter for full-term uncomplicated delivery',
                },
              ],
            },
            {
              text: 'Encounter for maternal postpartum care and examination',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'urn:oid:2.16.840.1.113883.6.90',
                  code: 'Z39.0',
                  display: 'Encounter for maternal postpartum care and examination',
                },
              ],
            },
            {
              text: 'Breastfeeding (mother)',
              codes: [
                {
                  source: 'encounter/diagnosis',
                  status: null,
                  codeSystem: 'http://snomed.info/sct',
                  code: '413712001',
                  display: 'Breastfeeding (mother)',
                },
              ],
            },
          ],
          reasons: [],
          type: [
            {
              text: 'Office Visit',
              codes: [
                {
                  source: 'encounter/type',
                  status: null,
                  codeSystem: 'urn:oid:1.2.840.114350.1.13.0.1.7.10.698084.30',
                  code: '101',
                  display: 'Office Visit',
                },
              ],
            },
          ],
          class: {
            system: 'urn:oid:1.2.840.114350.1.72.1.7.7.10.696784.13260',
            code: '6',
            display: 'Discharge',
            source: 'encounter/class',
            status: '',
          },
          date: '2014-05-18T15:45:00Z',
        },
        patient: {
          id: 'ff632cf7-98f5-4141-ac28-67aa3a9a9361',
          zipCode: '53593',
          gender: 'female',
          age: '20',
          preferredLanguage: 'en-us',
          firstName: 'Samantha',
          lastName: 'Smith',
          email: 'hw.consumer.ui.app0@mailinator.com',
          phoneNumber: '12626837022',
          phoneNumberType: 'work',
        },
        location: {
          id: 'eO-3e71xq6A5Xp7leBMrw5X-8sAl6UHIF91p7maQKFdU3',
          name: 'Emergency Department',
        },
        organization: {
          id: 'a9084a3d-bd9a-466e-8a9c-9dd49ef1ad73',
          name: 'Zion CMX Hospital',
        },
        practitionerRole: {
          id: 'eS9pn2YvOO0FnBOttGx807O3-Yyj1T4I058dR1GqF7sL6v9EE.E-1uW9HaFbL.fzW3',
          specialties: [],
          codes: [],
        },
      },
    },
  },
}
